import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { mapArrayToObject } from '../utils/array';

export const useGetCities = (
  voivodeship: string,
  schoolType: number | Array<number>
) => {
  const qs = require('qs');
  const query = qs.stringify(
    {
      filters: {
        voivodeship: {
          id: {
            $eq: voivodeship,
          },
        },
        schoolType: {
          id: {
            $eq: schoolType,
          },
        },
        works: {
          id: {
            $null: false,
          },
        },
      },
      populate: {
        city: true,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return useQuery({
    queryKey: ['cities', voivodeship, schoolType],
    queryFn: () =>
      voivodeship.length > 0
        ? axios.get(`/schools?${query}`).then((res) => {
            return mapArrayToObject(
              res.data?.data,
              (obj: any) => obj.city.id,
              (obj: any) => obj.city.name
            );
          })
        : {},
  });
};
