import s from './SocialMedia.module.scss';
import card from '../../components/Card/FlexCard.module.scss';
import Facebook from './facebook.webp';
import Media from './youtube.webp';
import { useMediaQuery } from 'usehooks-ts';
import { RevealCard, revealVariants } from '../Animations/CardTransitions';
import { motion } from 'framer-motion';
import { h1Variants } from '../Animations/TextTransitions';

const SocialMedia = () => {
  const mobile = useMediaQuery('(max-width: 768px)');
  return (
    <div className={s.socialMedia}>
      <div className={card.allCards}>
        <RevealCard
          style={{
            width: mobile ? '100%' : '60%',
          }}
          className={s.youtube}
        >
          <motion.div
            onClick={() =>
              window.open(
                'https://www.youtube.com/@pantablet4748/videos',
                '_blank'
              )
            }
            variants={revealVariants}
            style={{
              padding: '3em 3em',
            }}
            className={`${card.singleCard}`}
          >
            <motion.div className={`${s.cardWrapper}`}>
              <motion.h1
                initial='hidden'
                whileInView='visible'
                viewport={{ once: true, amount: 0.3 }}
                variants={h1Variants}
                custom={1}
                className='headline-1a'
              >
                Subskrybuj nas
              </motion.h1>
              <img style={{}} src={Media} alt='Pan Tablet na Youtube'></img>
            </motion.div>
          </motion.div>
        </RevealCard>
        <RevealCard
          style={{
            width: mobile ? '100%' : '40%',
          }}
          className={s.facebook}
        >
          <motion.div
            onClick={() =>
              window.open('https://pl-pl.facebook.com/PanTablet/', '_blank')
            }
            variants={revealVariants}
            style={{
              padding: '3em 3em',
            }}
            className={`${card.singleCard}`}
          >
            <motion.div className={`${s.cardWrapper}`}>
              <motion.h1
                initial='hidden'
                whileInView='visible'
                viewport={{ once: true, amount: 0.3 }}
                variants={h1Variants}
                custom={1.5}
                className='headline-1a'
              >
                Polub nas
              </motion.h1>
              <img src={Facebook} alt='Pan Tablet na Facebooku'></img>
            </motion.div>
          </motion.div>
        </RevealCard>
      </div>
    </div>
  );
};

export default SocialMedia;
