import { DateTime, Interval } from 'luxon';

export const formatDate = (date: string | number | Date) => {
  const d = new Date(date);

  return `${String(d.getDate()).padStart(2, '0')}.${String(
    d.getMonth() + 1
  ).padStart(2, '0')}.${d.getFullYear()}`;
};

export const yearBook = (date: string) => {
  const month = new Date(date).getMonth() + 1;
  let startingYear: number = 0;

  switch (true) {
    case month >= 9:
      startingYear = new Date(date).getFullYear();
      break;
    case month >= 1 && month <= 8:
      startingYear = new Date(date).getFullYear() - 1;
      break;
  }

  const yearBook =
    startingYear.toString() + '/' + (startingYear + 1).toString();

  return yearBook;
};

// returns date of closest Monday going backwards and date of closest Friday
// ex. 2023-08-05 -> [2023-07-31, 2023-08-04]
export const getDateWorkWeek = (d: Date): [Date, Date] => {
  let date = new Date(d);
  const day = date.getDay();

  let weekStartDelta = 1 - day;
  if (weekStartDelta === 1) {
    weekStartDelta = -6;
  }

  const weekEndDelta = ((7 - day) % 7) - 2;

  let weekStart = new Date(date);
  let weekEnd = new Date(date);

  weekStart.setDate(weekStart.getDate() + weekStartDelta);
  weekEnd.setDate(weekEnd.getDate() + weekEndDelta);

  return [weekStart, weekEnd];
};

// returns range of a date's month, excluding weekends
// ex. 2023-07-20 -> [2023-07-03, 2023-07-31]
export const getDateWorkMonth = (date: Date): [Date, Date] => {
  const getDaysUntilWorkWeek = (d: Date): number => {
    switch (d.getDay()) {
      case 0:
        return 1;

      case 6:
        return 2;

      default:
        return 0;
    }
  };

  let startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  startDate.setDate(startDate.getDate() + getDaysUntilWorkWeek(startDate));
  endDate.setDate(endDate.getDate() - getDaysUntilWorkWeek(endDate));

  return [startDate, endDate];
};

// returns an extended range of a work month with previous and next month if needed
// ex. [2023-08-01, 2023-08-31] -> [2023-07-31, 2023-09-01]
export const getMonthFullWorkRange = (range: [Date, Date]): [Date, Date] => {
  const getAdditionalDays = (d: Date): number => {
    return d.getDay() - 1;
  };

  let startDate = new Date(range[0]);
  let endDate = new Date(range[1]);

  startDate.setDate(startDate.getDate() - getAdditionalDays(startDate));
  endDate.setDate(endDate.getDate() + (4 - getAdditionalDays(endDate)));

  return [startDate, endDate];
};

export const getWorkDatesBetween = (range: [Date, Date]): Array<Date> => {
  const startRange = DateTime.fromJSDate(new Date(range[0])).startOf('day');
  const endRange = DateTime.fromJSDate(new Date(range[1]))
    .startOf('day')
    .plus({ day: 1 });

  const interval = Interval.fromDateTimes(startRange, endRange);
  let dates: Array<Date> = [];

  interval.splitBy({ day: 1 }).forEach((d) => {
    if (d.start && d.start.weekday !== 6 && d.start.weekday !== 7) {
      dates.push(d.start.toJSDate());
    }
  });

  return dates;
};

export const getWeekdayLocaleString = (date: Date) => {
  const result = date.toLocaleDateString('pl-PL', {
    weekday: 'long',
  });

  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const getMonthLocaleString = (date: Date) => {
  const result = date.toLocaleDateString('pl-PL', {
    month: 'long',
  });

  return result.charAt(0).toUpperCase() + result.slice(1);
};

// returns localized subranges (day, month, year) of a date range
// ex. [2023-10-01, 2023-11-05] -> [[1, 5], [Październik, Listopad], [2023]]
export const getDateRangeSubranges = (
  range: [Date, Date]
): Array<Array<number | string>> => {
  const getSubrange = (x: any, y: any) => {
    if (x === y) {
      return [x];
    } else {
      return [x, y];
    }
  };

  let subranges = [];

  subranges.push(getSubrange(range[0].getDate(), range[1].getDate()));
  subranges.push(
    getSubrange(getMonthLocaleString(range[0]), getMonthLocaleString(range[1]))
  );
  subranges.push(getSubrange(range[0].getFullYear(), range[1].getFullYear()));

  return subranges;
};

// converts time from a string format `HH:MM` to a number of minutes
export const timeToMinutes = (time: string): number => {
  const [hour, minutes] = time.split(':');

  return +hour * 60 + +minutes;
};

// returns minutes between two string format `HH:MM` times
export const minutesSince = (startTime: string, time: string): number => {
  return timeToMinutes(time) - timeToMinutes(startTime);
};
