import Contests from './components/Contests/Contests';
import globalColors from '../../styles/_colors.scss';
import SubHeader from '../../components/SubHeader/SubHeader';
import Header from './components/Header/Header';
import SocialMedia from '../../components/SocialMedia/SocialMedia';
import Image1 from './components/Header/tablet-images/primary-school/image-1.webp';
import Image2 from './components/Header/tablet-images/primary-school/image-2.webp';
import Image3 from './components/Header/tablet-images/primary-school/image-3.webp';
import Image4 from './components/Header/tablet-images/primary-school/image-4.webp';
import Image5 from './components/Header/tablet-images/primary-school/image-5.webp';
import Image6 from './components/Header/tablet-images/primary-school/image-6.webp';
import { motion } from 'framer-motion';

const ContestsPage = () => {
  return (
    <>
      <motion.div
        initial='hidden'
        animate='visible'
        exit={{ opacity: 0, transition: { duration: 1 } }}
      >
        <SubHeader color={globalColors.regular1Kinder} />
        <Header images={[Image1, Image2, Image3, Image4, Image5, Image6]} />
        <Contests />
        <SocialMedia />
      </motion.div>
    </>
  );
};

export default ContestsPage;
