import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useValidableState = <T,>(
  initialState: T,
  validationFunction: (state: T) => boolean = (obj: any) => obj.length > 0,
  dependencyArray: Array<any> = []
): [T, Dispatch<SetStateAction<T>>, boolean] => {
  const [state, setState] = useState(initialState);
  const [valid, setValid] = useState(false);
  useEffect(() => {
    setValid(validationFunction(state));
    // eslint-disable-next-line
  }, [...dependencyArray, state, setValid, validationFunction]);
  return [state, setState, valid];
};

export const validateEmail = (email: string) => /\S+@\S+\.\S+/.test(email);

/*
Password rules
  - 1 upper case letter
  - 1 lower case letter
  - 1 number
  - 8-16 characters long
*/

export const validatePassword = (password: string) =>
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,16}$/.test(password);

export const phoneNumberRegex = /^[+\d](?:.*\d)?$/;
