import Congratulations from './congratulations.webp';
import s from './RegisterSuccess.module.scss';
import button from '../../../../components/Button/Button.module.scss';
import { CaretCircleRightIcon } from '../../../../components/Icons/icons';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import { useResendEmailConfirmation } from '../../../../api/auth';
import { useEffect, useState } from 'react';
import ResendConfirmationPopup from '../../../../components/Popups/ResendConfirmationPopup/ResendConfirmationPopup';
import { motion } from 'framer-motion';
import {
  h1Variants,
  h4Variants,
} from '../../../../components/Animations/TextTransitions';

interface Props {
  email: string;
}

const RegisterSuccess = ({ email }: Props) => {
  const navigate = useNavigate();
  const [emailRecipient, setEmailRecipient] = useState('');
  const [popupVisible, setPopupVisible] = useState(false);

  useEffect(() => {
    setEmailRecipient(email);
  }, [email]);

  const resendEmailConfirmation = useResendEmailConfirmation();

  const send = () => {
    resendEmailConfirmation.mutate(
      {
        email: emailRecipient,
      },
      {
        onSuccess: async () => {
          setPopupVisible(true);
        },
        onError: async (e: any) => {
          console.log(e);
        },
      }
    );
  };

  return (
    <>
      <ResendConfirmationPopup
        visible={popupVisible}
        setVisible={setPopupVisible}
      />
      <motion.div
        initial='hidden'
        animate='visible'
        exit={{ opacity: 0, transition: { duration: 1 } }}
        className={s.container}
      >
        <div className={s.congratsInfo}>
          <motion.h1 variants={h1Variants}>Gratulujemy!</motion.h1>
          <motion.h4 variants={h4Variants}>
            Jesteś już naszym użytkownikiem!
            <br />
            Przed Tobą ostatni krok by móc się zalogować.
            <br />
            Potwierdź prośbę o rejestrację poprzez e-mail, który do Ciebie
            wysłaliśmy.
          </motion.h4>
          <h3 className='headline-3b'>Miłego korzystania!</h3>

          <Button
            style={{ width: '139px', height: '40px' }}
            onClick={() => navigate('/login')}
            className={`${button.button} ${button.login} ${s.button}`}
            icon={<CaretCircleRightIcon />}
          >
            <p className='button-4'>Zaloguj się</p>
          </Button>
          <Button>
            <span
              className={`${s.resendEmail} add-underline`}
              onClick={() => send()}
            >
              E-mail nie dotarł? Kliknij TUTAJ aby wysłać ponownie.
            </span>
          </Button>
        </div>
        <img className={s.image} src={Congratulations} alt='congratulations' />
      </motion.div>
    </>
  );
};

export default RegisterSuccess;
