import { useEffect, useState } from 'react';
import { useGetComments } from '../../../../api/comment';
import Button from '../../../../components/Button/Button';
import s from './ViewComment.module.scss';
import { useSchoolParams } from '../../../../utils/api/school';

const ViewComment = () => {
  const params = useSchoolParams();
  const comments = useGetComments(params.workId);
  const [resComments, setResComments] = useState<any>(null);
  useEffect(() => {
    const { data } = comments;
    if (data?.length >= 0) {
      setResComments(data);
    }
  }, [comments]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextComment = () => {
    if (currentIndex !== resComments?.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const previousComment = () => {
    if (currentIndex !== 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <>
      {resComments?.length > 0 && (
        <>
          <h4 className={s.commentsViewHeader}>Komentarze</h4>
          <div className={s.commentsView}>
            <p className='body-2 add-bold'>
              {resComments?.[currentIndex]?.author?.firstname +
                ' ' +
                resComments?.[currentIndex]?.author?.lastname}
            </p>
            <p className='field-1'>{resComments?.[currentIndex]?.text}</p>
            <div className={s.navigation}>
              <p className='button-5'>
                {currentIndex + 1}/{resComments?.length}
              </p>
              <div className={s.navigationButtons}>
                <Button>
                  <p onClick={() => previousComment()} className='button-2'>
                    Poprzedni
                  </p>
                </Button>
                <Button>
                  <p onClick={() => nextComment()} className='button-2'>
                    Następny
                  </p>
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ViewComment;
