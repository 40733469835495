import Button from '../../../../components/Button/Button';
import {
  CaretCircleRightIcon,
  RevealCardIcon,
  RevealCardKindergartenIcon,
  RevealCardPrimarySchoolIcon,
  RevealReverseCardIcon,
} from '../../../../components/Icons/icons';
import s from './SchoolTypes.module.scss';
import PrimarySchool from './podstawowa.webp';
import Kindergarten from './przedszkole.webp';
import globalColors from '../../../../styles/_colors.scss';
import button from '../../../../components/Button/Button.module.scss';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { h1Variants } from '../../../../components/Animations/TextTransitions';

interface Props {
  callback: () => void;
}

export const SchoolContent = ({ callback }: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        onClick={() => navigate('/elementary')}
        className={`${s.cardWrapper}`}
      >
        <div className={s.cardUpper}>
          <img
            src={PrimarySchool}
            alt='Zajęcia Pana Tableta w szkole podstawowej'
          />
        </div>
        <div className={s.cardBottom}>
          <motion.h1
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true, amount: 0.3 }}
            custom={1}
            variants={h1Variants}
            className='headline-1b'
          >
            Szkoła
          </motion.h1>
          <Button
            className={`${button.button} ${button.chooseSchool}`}
            icon={<CaretCircleRightIcon fill={globalColors.blue4} />}
            onClick={() => navigate('/elementary')}
          >
            <p className='button-2'>Wybierz szkołę</p>
          </Button>
        </div>
        <div className={s.openCard}>
          <RevealCardIcon
            onClick={(e: any) => {
              e.stopPropagation();
              callback();
            }}
          />
        </div>
      </div>
    </>
  );
};

export const SchoolContentReverse = ({ callback }: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        onClick={() => navigate('/elementary')}
        className={`${s.cardWrapper} ${s.cardWrapperFlipped}`}
      >
        <div className={`${s.cardUpper} ${s.cardUpperFlipped}`}>
          <RevealCardPrimarySchoolIcon />
          <p className='body-2'>
            Zajęcia dla edukacji wczesnoszkolnej łatwo wprowadzają na lekcje
            Twojego dziecka nowe technologie, które wykorzystujemy w sposób
            bezpieczny i funkcjonalny, tak, by wzmacniać proces dydaktyczny.
          </p>
        </div>
        <div className={`${s.cardBottom} ${s.cardBottomFlipped}`}>
          <Button
            className={`${button.button} ${button.chooseSchoolAlt}`}
            icon={<CaretCircleRightIcon />}
            onClick={() => navigate('/elementary')}
          >
            <p className='button-2'>Wybierz szkołę</p>
          </Button>
        </div>
      </div>

      <div className={s.openCard}>
        <RevealReverseCardIcon
          onClick={(e: any) => {
            e.stopPropagation();
            callback();
          }}
        />
      </div>
    </>
  );
};

export const KindergartenContent = ({ callback }: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        onClick={() => navigate('/kindergarten')}
        className={`${s.cardWrapper}`}
      >
        <div className={s.cardUpper}>
          <img src={Kindergarten} alt='point-2' />
        </div>
        <div className={s.cardBottom}>
          <motion.h1
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true, amount: 0.3 }}
            custom={1.5}
            variants={h1Variants}
            className='headline-1b'
          >
            Przedszkole
          </motion.h1>
          <Button
            className={`${button.button} ${button.chooseSchool}`}
            icon={<CaretCircleRightIcon fill={globalColors.blue4} />}
            onClick={() => navigate('/kindergarten')}
          >
            <p className='button-2'>Wybierz przedszkole</p>
          </Button>
        </div>
        <div className={s.openCard}>
          <RevealCardIcon
            onClick={(e: any) => {
              e.stopPropagation();
              callback();
            }}
          />
        </div>
      </div>
    </>
  );
};

export const KindergartenContentReverse = ({ callback }: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        onClick={() => navigate('/kindergarten')}
        className={`${s.cardWrapper} ${s.cardWrapperFlipped} `}
      >
        <div className={`${s.cardUpper} ${s.cardUpperFlipped}`}>
          <RevealCardKindergartenIcon />
          <p className='body-2'>
            Zajęcia dla edukacji przedszkolnej przygotowują dzieci do
            prawidłowego i bezpiecznego korzystania z nowych technologii, a
            także atrakcyjnie rozwijają ich zainteresowania w oparciu o podstawę
            programową.
          </p>
        </div>
        <div className={`${s.cardBottom} ${s.cardBottomFlipped}`}>
          <Button
            className={`${button.button} ${button.chooseSchoolAlt}`}
            icon={<CaretCircleRightIcon />}
            onClick={() => navigate('/kindergarten')}
          >
            <p className='button-2'>Wybierz przedszkole</p>
          </Button>
        </div>
        <div className={s.openCard}>
          <RevealReverseCardIcon
            onClick={(e: any) => {
              e.stopPropagation();
              callback();
            }}
          />
        </div>
      </div>
    </>
  );
};
