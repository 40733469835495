import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import qs from 'qs';

interface Props {
  topicId: number;
}

export const useGetTopic = ({ topicId }: Props) => {
  const query = qs.stringify(
    {
      filters: {
        id: topicId,
      },
      populate: {
        category: true,
        workingMaterials: true,
        homework: true,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return useQuery({
    queryKey: ['topics', topicId],
    queryFn: () =>
      axios.get(`/topics?${query}`).then((res) => res.data?.data[0]),
  });
};
