import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { mapArrayToObject } from '../utils/array';

export const useGetVoivodeships = (schoolType: number | Array<number>) => {
  const qs = require('qs');
  const query = qs.stringify(
    {
      filters: {
        schoolType: {
          id: {
            $eq: schoolType,
          },
        },
        works: {
          id: {
            $null: false,
          },
        },
      },
      populate: {
        voivodeship: true,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return useQuery({
    queryKey: ['voivodeships', schoolType],
    queryFn: async () => {
      return axios.get(`/schools?${query}`).then((res) =>
        mapArrayToObject(
          res.data?.data,
          (obj: any) => obj.voivodeship.id,
          (obj: any) => obj.voivodeship.name
        )
      );
    },
  });
};
