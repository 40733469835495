import { IBasePopupProps, withPopup } from '../withPopup';
import SuccessImage from './success.webp';
import Button from '../../Button/Button';
import button from '../../Button/Button.module.scss';
import s from './ResendConfirmationPopup.module.scss';

const ResendConfirmationPopup = ({ close }: IBasePopupProps) => {
  return (
    <div className={s.container}>
      <img width='430px' src={SuccessImage} alt='success' />

      <div className={s.content}>
        <h3 className='headline-3b'>Wysłaliśmy link aktywacyjny.</h3>

        <p className='body-3'>
          Sprawdź skrzynkę pocztową. Jeśli nie widzisz wiadomości od nas,
          zerknij do SPAM-u.
        </p>

        <Button
          onClick={() => {
            if (close) {
              close();
            }
          }}
          className={`${button.button} ${button.login} ${s.button}`}
        >
          <p className='button-5'>OK, dziękuję</p>
        </Button>
      </div>
    </div>
  );
};

export default withPopup(ResendConfirmationPopup);
