import axios from 'axios';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { SchoolType } from './contest';

export interface ITimeframe {
  startDate: string;
  endDate: string;
}

interface Props {
  school?: string | Array<number> | undefined;
  grade?: string | undefined;
  workId?: string | undefined;
  timeframe?: ITimeframe | null;
  schoolType?: SchoolType;
}

export const useGetRandomWork = () => {
  const qs = require('qs');
  const query = qs.stringify(
    {
      sort: ['createdAt:desc'],
      pagination: {
        start: 0,
        limit: 1,
      },
      populate: {
        topic: {
          populate: ['category'],
        },
        media: true,
        school: { populate: ['schoolType'] },
        grade: true,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return useQuery({
    queryKey: ['worksRandom'],
    queryFn: () => {
      return axios.get(`/works?${query}`).then((res) => {
        return res.data?.data;
      });
    },
    placeholderData: [],
  });
};

export const useGetTop50Works = () => {
  const qs = require('qs');
  const query = qs.stringify(
    {
      sort: ['createdAt:desc'],
      pagination: {
        start: 0,
        limit: 50,
      },
      populate: {
        media: true,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return useQuery({
    queryKey: ['worksTop50'],
    queryFn: () => {
      return axios.get(`/works?${query}`).then((res) => {
        return res.data?.data;
      });
    },
    placeholderData: [],
  });
};

export const useGetTop5Works = (contestId: number) => {
  const qsWorks = require('qs');
  const queryWorks = qsWorks.stringify(
    {
      sort: ['createdAt:desc'],
      pagination: {
        start: 0,
        limit: 5,
      },
      populate: {
        topic: {
          populate: ['category'],
        },
        media: true,
        school: { populate: ['schoolType'] },
        grade: true,
        votes: true,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return useQuery({
    queryKey: ['worksTop5', contestId],
    queryFn: async () => {
      return axios
        .get(`/work/get-top-works/${contestId}?${queryWorks}`)
        .then((res) => {
          return res.data;
        });
    },
    placeholderData: [],
  });
};

export const useGetWorksForFavorites = ({ school }: Props) => {
  const qs = require('qs');
  const query = qs.stringify(
    {
      filters: {
        school: school
          ? {
              id: {
                $eq: school,
              },
            }
          : {},
      },
      populate: {
        school: { populate: ['schoolType'] },
        grade: true,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return useQuery({
    queryKey: ['worksForFavorites', school],
    queryFn: () => axios.get(`/works?${query}`).then((res) => res.data?.data),
    placeholderData: [],
  });
};
export const useGetWorks = ({ school, grade, workId, timeframe }: Props) => {
  const qs = require('qs');
  const query = qs.stringify(
    {
      sort: ['createdAt:desc'],
      filters: {
        school: school
          ? {
              id: {
                $eq: school,
              },
            }
          : {},
        grade: {
          id: grade
            ? {
                $eq: grade,
              }
            : {},
        },
        id: workId
          ? {
              $eq: workId,
            }
          : {},
        createdAt: timeframe
          ? {
              $gte: timeframe.startDate,
              $lte: timeframe.endDate,
            }
          : {},
      },
      populate: {
        topic: {
          populate: ['category'],
        },
        media: true,
        school: { populate: ['schoolType', 'voivodeship', 'city'] },
        grade: true,
        animator: true,
        votes: true,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return useQuery({
    queryKey: [
      'works',
      school,
      grade,
      workId,
      `${timeframe?.startDate}-${timeframe?.endDate}`,
    ],
    queryFn: () => axios.get(`/works?${query}`).then((res) => res.data?.data),
    placeholderData: [],
    enabled: timeframe !== null,
    keepPreviousData: true,
  });
};

export const useGetInfiniteWorks = ({
  school,
  grade,
  workId,
  timeframe,
}: Props) => {
  const qs = require('qs');
  const query = (page: number) =>
    qs.stringify(
      {
        sort: ['createdAt:desc'],
        filters: {
          school: school
            ? {
                id: {
                  $eq: school,
                },
              }
            : {},
          grade: {
            id: grade
              ? {
                  $eq: grade,
                }
              : {},
          },
          id: workId
            ? {
                $eq: workId,
              }
            : {},
          createdAt: timeframe
            ? {
                $gte: timeframe.startDate,
                $lte: timeframe.endDate,
              }
            : {},
        },
        pagination: {
          page,
        },
        populate: {
          topic: {
            populate: ['category'],
          },
          media: true,
          school: { populate: ['schoolType', 'voivodeship', 'city'] },
          grade: true,
          animator: true,
          votes: true,
        },
      },
      {
        encodeValuesOnly: true,
      }
    );

  return useInfiniteQuery({
    queryKey: [
      'worksInfinite',
      school,
      grade,
      workId,
      `${timeframe?.startDate}-${timeframe?.endDate}`,
    ],
    queryFn: ({ pageParam = 1 }) =>
      axios.get(`/works?${query(pageParam)}`).then((res) => res.data),
    enabled: timeframe !== null,
    getNextPageParam: (lastPage) =>
      lastPage.meta.pagination.page < lastPage.meta.pagination.pageCount
        ? lastPage.meta.pagination.page + 1
        : undefined,
  });
};

export enum SearchType {
  Id,
  Name,
}

export interface ISearchOptions {
  school: SearchType;
  grade: SearchType;
  author: SearchType;
}

export const useGetWorksForSearchbar = (
  searchOptions: ISearchOptions,
  school: string | null,
  grade: string | null,
  author: string | null
) => {
  const qs = require('qs');
  const query = qs.stringify(
    {
      filters: {
        $or: [
          {
            author:
              searchOptions.author === SearchType.Name && author
                ? {
                    $containsi: author,
                  }
                : {},
          },
        ],
        $and: [
          {
            school:
              searchOptions.school === SearchType.Id && school
                ? {
                    id: { $eq: school },
                  }
                : {},
          },
          {
            grade:
              searchOptions.grade === SearchType.Id && grade
                ? {
                    id: { $eq: grade },
                  }
                : {},
          },
          {
            author:
              searchOptions.author === SearchType.Id && author
                ? {
                    $eq: author,
                  }
                : {},
          },
        ],
      },
      populate: {
        school: { populate: ['name', 'schoolType'] },
        grade: true,
        author: true,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return useQuery({
    queryKey: ['worksForSearchbar', school, grade, author],
    queryFn: () =>
      school && school.length > 0 && grade && grade.length > 0
        ? axios.get(`/works?${query}`).then((res) => res.data?.data)
        : [],
    placeholderData: [],
  });
};

export const useGetWorksForContest = ({ timeframe }: Props) => {
  const qs = require('qs');
  const query = qs.stringify(
    {
      sort: ['createdAt:desc'],
      filters: {
        createdAt: timeframe
          ? {
              $gte: timeframe.startDate,
              $lte: timeframe.endDate,
            }
          : {},
      },
      populate: {
        school: { populate: ['schoolType'] },
        grade: true,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return useQuery({
    queryKey: ['worksContest', `${timeframe?.startDate}-${timeframe?.endDate}`],
    queryFn: () => axios.get(`/works?${query}`).then((res) => res.data?.data),
    placeholderData: [],
    enabled: timeframe !== null,
  });
};

export const useGetQualifiedSchools = ({ timeframe, schoolType }: Props) => {
  return useQuery({
    queryKey: [
      'qualifiedSchools',
      `${timeframe?.startDate}-${timeframe?.endDate}-${schoolType}`,
    ],
    queryFn: () =>
      axios
        .post(`/qualified-schools`, {
          startDate: timeframe?.startDate,
          endDate: timeframe?.endDate,
          schoolType: schoolType,
        })
        .then((res) => res.data),
    placeholderData: [],
    enabled: timeframe !== null && schoolType !== undefined,
  });
};
