import Logo from '../PageBar/logo.svg';
import {
  // InstagramIcon,
  YoutubeIcon,
  FacebookIcon,
  LoginIcon,
  RegisterIcon,
  LogoutIcon,
} from '../Icons/icons';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { logOut } from '../../redux/slices/userSlice';
import s from './Header.module.scss';
import pageBar from '../PageBar/PageBar.module.scss';
import FavoriteSchools from './FavoriteSchools/FavoriteSchools';
import WithoutAccountPopup from '../Popups/WithoutAccountPopup/WithoutAccountPopup';
import { useState } from 'react';
import NoFavoritesAddedPopup from '../Popups/NoFavoritesAddedPopup/NoFavoritesAddedPopup';
import { animation } from '../../utils/animation';

const Header = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const isOnLoginPage = pathname === '/login' || pathname === '/pre-login';
  const loggedIn = useAppSelector((state) => state.user.loggedIn);
  const userData = useAppSelector((state) => state.user.userData) as any;
  const [noAccount, setNoAccount] = useState(false);
  const [noFavorites, setNoFavorites] = useState(false);

  return (
    <>
      <WithoutAccountPopup
        otherIncentive='Funkcja szybkiej nawigacji do klasy Twojego dziecka jest zarezerowana dla zarejestrowanych użytkowników. Nie zwlekaj z założeniem konta.'
        visible={noAccount}
        setVisible={setNoAccount}
      />
      <NoFavoritesAddedPopup
        visible={noFavorites}
        setVisible={setNoFavorites}
      />
      <header className={`${pageBar.bar} ${s.header}`}>
        <div className={`${pageBar.left}`}>
          <animation.a href='/'>
            <img src={Logo} alt='logo' />
          </animation.a>
          {loggedIn && userData?.favoriteSchools?.length > 0 ? (
            <FavoriteSchools />
          ) : loggedIn && userData?.favoriteSchools?.length === 0 ? (
            <animation.a
              href='#'
              className={`field-1 add-underline`}
              onClick={() => setNoFavorites(true)}
            >
              Twoje Szkoły
            </animation.a>
          ) : (
            <animation.a
              href='#'
              className={`field-1 add-underline`}
              onClick={() => setNoAccount(true)}
            >
              Twoje Szkoły
            </animation.a>
          )}
          <div className={`${pageBar.links}`}>
            <animation.a className='field-1 add-underline' href='/elementary'>
              Szkoła
            </animation.a>
            <animation.a className='field-1 add-underline' href='/kindergarten'>
              Przedszkole
            </animation.a>
            <animation.a className='field-1 add-underline' href='/contests'>
              Konkursy
            </animation.a>
          </div>
        </div>
        <div className={pageBar.right}>
          <div className={s.socials}>
            {/* <InstagramIcon /> */}
            <YoutubeIcon
              onClick={() =>
                window.open(
                  'https://www.youtube.com/@pantablet4748/videos',
                  '_blank'
                )
              }
            />
            <FacebookIcon
              onClick={() =>
                window.open('https://pl-pl.facebook.com/PanTablet/', '_blank')
              }
            />
          </div>
          {loggedIn ? (
            <div className={`${s.actionButton}`}>
              <animation.span className='field-1'>{`Cześć, ${userData?.firstName}`}</animation.span>
              <LogoutIcon onClick={() => dispatch(logOut())} />
            </div>
          ) : isOnLoginPage ? (
            <a
              href='/pre-register'
              className={`${s.actionHover} ${s.actionButton}`}
            >
              <animation.span className='field-1'>Rejestracja</animation.span>
              <RegisterIcon />
            </a>
          ) : (
            <a
              href='/pre-login'
              className={`${s.actionHover} ${s.actionButton}`}
            >
              <animation.span className='field-1'>Zaloguj się</animation.span>
              <LoginIcon />
            </a>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
