import { combineReducers, configureStore } from '@reduxjs/toolkit';
import user from './slices/userSlice';
import contest from './slices/contestSlice';
import adminUser from './slices/userAdminSlice';
import calendar from './slices/calendarSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const reducers = combineReducers({
  user,
  contest,
  adminUser,
  calendar,
});

const persistConfig = {
  key: 'pan-tablet',
  storage,
  blacklist: ['contest'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
