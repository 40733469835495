import Navigation from './components/Navigation/Navigation';
import s from './CalendarPage.module.scss';
import Login from './components/Login/Login';
import Views from './components/Views/Views';
import { useAppSelector } from '../../redux/hooks';

const CalendarPage = () => {
  const loggedIn = useAppSelector((state) => state.adminUser.loggedIn);

  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        {loggedIn ? (
          <>
            <Navigation />
            <Views />
          </>
        ) : (
          <Login />
        )}
      </div>
    </div>
  );
};

export default CalendarPage;
