import BreadCrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import SocialMedia from '../../../../components/SocialMedia/SocialMedia';
import SubHeader from '../../../../components/SubHeader/SubHeader';
import Banner from '../../components/Banner/Banner';
import IntroMessage from '../../components/IntroMessage/IntroMessage';
import s from '../../SchoolPages.module.scss';
import BannerImage from './components/choose-group.webp';
import Filters from '../../components/Filters/Filters';
import BannerImg from './components/data-banner-groups.webp';
import Cards from '../../components/Cards/Cards';
import cardRegular from './components/groups-book.webp';
import cardWinner from './components/1stPlaceGroups.webp';
import globalColors from '../../../../styles/_colors.scss';
import { useEffect, useState } from 'react';
import { useGetSchoolById } from '../../../../api/school';
import { calculateClassAttendance } from '../../components/utils';
import { yearBook } from '../../../../utils/datetime';
import { useMediaQuery } from 'usehooks-ts';
import { useSchoolParams } from '../../../../utils/api/school';
import globals from '../../../../App.scss';
import DataBannerAlt from '../../components/DataBannerAlt/DataBannerAlt';
import { useGetGrades } from '../../../../api/grade';

const GroupsPage = () => {
  const params = useSchoolParams();
  const gradesApi = useGetGrades(+params.schoolId);
  const school = useGetSchoolById(params.schoolId);
  const [resGrades, setResGrades] = useState<any>(null);
  const [resSchool, setResSchool] = useState<any>(null);
  const [grades, setGrades] = useState<any[]>();
  const mobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    const { data } = school;
    if (data?.length >= 0) {
      setResSchool(data);
    }
  }, [school]);

  useEffect(() => {
    const { data } = gradesApi;
    if (data && Object.keys(data).length >= 0) {
      setResGrades(data);
    }
  }, [gradesApi]);

  const filterGrades = (gradeId: number) => {
    if (gradeId) {
      let newGrades: any[] = [];

      if (resGrades) {
        Object.entries(resGrades).forEach(([gradeId, grade]: [string, any]) => {
          newGrades.push({
            id: gradeId,
            name:
              'Klasa ' +
              grade.name +
              ' rocz.' +
              yearBook(grade?.work?.createdAt),
            address: resSchool?.[0]?.name,
          });
        });
        let uniqueGrades = [
          ...new Map(
            newGrades.map((item: any) => [item['name'], item])
          ).values(),
        ];

        const finalGrades = uniqueGrades?.filter((item: any) => {
          return +gradeId === +item.id;
        });
        setGrades(finalGrades);
      }
    }
  };

  useEffect(() => {
    let newGrades: any[] = [];

    if (resGrades) {
      Object.entries(resGrades).forEach(([gradeId, grade]: [string, any]) => {
        newGrades.push({
          id: gradeId,
          name:
            'Klasa ' + grade.name + ' rocz.' + yearBook(grade?.work?.createdAt),
          address: resSchool?.[0]?.name,
        });
      });
      let uniqueGrades = [
        ...new Map(newGrades.map((item: any) => [item['name'], item])).values(),
      ];

      setGrades(uniqueGrades);
    }
  }, [resGrades, resSchool]);

  return (
    <>
      <SubHeader color={globalColors.blue4} />
      <BreadCrumbs groups={resSchool?.[0]?.name} />
      <Banner img={BannerImage} headline='Wybierz grupę' />
      <div className={s.messageAndSearchbar}>
        <IntroMessage />
        <SearchBar
          style={{
            width: mobile ? globals.mobileWidth : '30%',
            minWidth: '270px',
          }}
        />
      </div>
      <DataBannerAlt
        school={resSchool?.[0]}
        classAttendance={calculateClassAttendance({
          numerator: resSchool?.[0]?.participatingGrades,
          denominator: resSchool?.[0]?.gradesTotal,
        })}
        img={BannerImg}
        className='gradient-kindergarten-school-1'
      />
      {/* schooltype 1 is kindergarten school in backend */}
      <Filters
        school={resSchool}
        callbackAlt={filterGrades}
        schoolType={1}
        schoolFilterTitle='Przedszkola'
        gradeFilterTitle='Grupy'
      />
      <Cards
        cardData={grades}
        cardWinner={cardWinner}
        cardRegular={cardRegular}
        cardWinnerImgStyle={{ width: '370px', height: '238px' }}
        cardAvatarImgStyle={{ width: '156px', height: '156px' }}
        color={globalColors.regular1Kinder}
        actionButtonText={'Wybierz grupę'}
        additionalText='Liczba polubień:'
        noFavorites={true}
        showResultsSummary={true}
        showResultsText='Liczba grup biorących udział w lekcjach Pana Tableta:'
      />
      <SocialMedia />
    </>
  );
};

export default GroupsPage;
