import { useState } from 'react';
import Register from './components/Register/Register';
import RegisterSuccess from './components/RegisterSuccess/RegisterSuccess';

const RegisterPage = () => {
  const [page, setPage] = useState(1);
  const [email, setEmail] = useState('');
  const nextPage = (email: string) => {
    setEmail(email);
    setPage(page + 1);
  };
  switch (page) {
    case 1:
      return <Register nextPage={nextPage} />;
    case 2:
      return <RegisterSuccess email={email} />;
    default:
      return <></>;
  }
};

export default RegisterPage;
