import { useLocation } from '../location';
import { useMemo } from 'react';

export const useSchoolParams = () => {
  const location = useLocation();
  const locationParts = location.pathname.split(/[\s/]+/);
  return useMemo(() => {
    if (location.pathname.includes('contests')) {
      return {
        contestId: locationParts[3],
        schoolId: locationParts[4],
        gradeId: locationParts[5],
        workId: locationParts[6],
      };
    }

    return {
      schoolId: locationParts[2],
      gradeId: locationParts[3],
      workId: locationParts[4],
    };
  }, [location.pathname, locationParts]);
};
