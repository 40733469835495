import { IBasePopupProps, withPopup } from '../withPopup';
import { CaretCircleRightIcon } from '../../Icons/icons';
import Button from '../../Button/Button';
import Image from './favorites.webp';
import button from '../../Button/Button.module.scss';
import s from './AddedToFavouritesPopup.module.scss';

const AddedToFavouritesPopup = ({ close }: IBasePopupProps) => {
  return (
    <div className={s.container}>
      <img width='248px' className={s.center} src={Image} alt='favourites' />

      <h3 className='headline-3b'>
        Wybrana placówka została dodana do ulubionych.
      </h3>

      <p className='body-3'>
        Obserwuj na bieżąco swoją placówkę, śledź konkursy, prace dzieci, bądź
        aktywną częścią społeczności Pana Tableta.
      </p>

      <Button
        onClick={() => {
          if (close) {
            close();
          }
        }}
        className={`${button.button} ${button.login} ${s.center} ${s.button}`}
        icon={<CaretCircleRightIcon />}
      >
        <p className='button-5'>Chcę przeglądać platformę</p>
      </Button>
    </div>
  );
};

export default withPopup(AddedToFavouritesPopup);
