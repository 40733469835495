import s from './ContestRules.module.scss';
import { ReactComponent as ToggleIcon } from './toggle-icon.svg';
import NotebookImage from './notebook.webp';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface IProps {
  image: string;
}

const ContestRules = ({ image }: IProps) => {
  const [opened, setOpened] = useState(false);
  const navigate = useNavigate();

  return (
    <div
      onClick={() => setOpened(!opened)}
      className={`${s.card} ${opened ? s.opened : ''}`}
    >
      {opened ? (
        <div className={s.openedContent}>
          <img src={NotebookImage} alt='notebook' />
          <h2 className='headline-2 add-bold'>Regulamin konkursu</h2>
          <p className='body-2'>
            Zapraszamy do udziału w Konkursie Kreatywnym Pana Tableta, w którym
            mogą brać udział wszystkie szkoły i przedszkola, w których
            prowadzimy zajęcia multimedialne. Każdy zarejestrowany użytkownik
            platformy może oddać 10 głosów w konkursie. Pamiętaj, wygrywa szkoła
            z największą liczbą oddanych głosów! Zapoznaj się ze szczegółowym
            regulaminem konkursu.
          </p>

          <div className={s.points}>
            <div className={s.point}>
              <div className={s.dot} />
              <span
                onClick={(e: any) => {
                  e.stopPropagation();
                  navigate('/register');
                }}
                className='body-1 add-bold add-underline'
              >
                zarejestruj się na platformie i oddawaj głosy na wybrane prace
              </span>
            </div>
            <div className={s.point}>
              <div className={s.dot} />
              <span
                className='body-1 add-bold add-underline'
                onClick={(e: any) => {
                  e.stopPropagation();
                  window.open(
                    'https://pl-pl.facebook.com/PanTablet/',
                    '_blank'
                  );
                }}
              >
                poproś znajomych o zalogowanie i oddanie głosów w konkursie
              </span>
            </div>

            <div className={s.point}>
              <div className={s.dot} />
              <span
                onClick={(e: any) => {
                  e.stopPropagation();
                  window.open(
                    'https://docs.google.com/document/d/1TrzuYyDnWJ1preMZdw_WpfaWUu5QPtDbGOvTw3Oc6H8',
                    '_blank'
                  );
                }}
                className='body-1 add-bold add-underline'
              >
                zobacz szczegółowy regulamin konkursu
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className={s.closedContent}>
          <img src={image} alt='rules' />
          <h1 className='headline-1b'>Regulamin konkursu</h1>
        </div>
      )}

      <ToggleIcon
        onClick={() => setOpened(!opened)}
        style={{ transform: opened ? 'rotate(45deg)' : '', cursor: 'pointer' }}
      />
    </div>
  );
};

export default ContestRules;
