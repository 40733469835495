import s from './Header.module.scss';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import { randomPickOne } from '../../../../utils/array';
import Decoration1 from '../../../ContestsPage/components/Header/image-decorations/decoration-1.webp';
import Decoration2 from '../../../ContestsPage/components/Header/image-decorations/decoration-2.webp';
import Decoration3 from '../../../ContestsPage/components/Header/image-decorations/decoration-3.webp';
import Decoration4 from '../../../ContestsPage/components/Header/image-decorations/decoration-4.webp';
import Decoration5 from '../../../ContestsPage/components/Header/image-decorations/decoration-5.webp';
import Decoration6 from '../../../ContestsPage/components/Header/image-decorations/decoration-6.webp';
import Decoration7 from '../../../ContestsPage/components/Header/image-decorations/decoration-7.webp';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import {
  h1Variants,
  revealAndScaleVariants,
} from '../../../../components/Animations/TextTransitions';
import { useMediaQuery } from 'usehooks-ts';

interface IProps {
  images: Array<string>;
  finished?: boolean;
}

const Header = ({ images, finished = false }: IProps) => {
  const decorations = [
    {
      className: s.decorations1,
      images: [Decoration5, Decoration3, Decoration1, Decoration2],
    },
    {
      className: s.decorations2,
      images: [Decoration4, Decoration7, Decoration6],
    },
  ];

  useEffect(() => {
    setRandomImage(randomPickOne(images));
  }, [images]);

  const [randomImage, setRandomImage] = useState<any>();
  const chosenDecoration = useState(randomPickOne(decorations))[0];

  const mobile = useMediaQuery('(max-width: 768px)');

  const hiddenMask = `repeating-linear-gradient(to top, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 100px, rgba(0,0,0,1) 100px, rgba(0,0,0,1) 100px)`;
  const visibleMask = `repeating-linear-gradient(to top, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,1) 0px, rgba(0,0,0,1) 100px)`;

  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);

  return (
    <div className={s.header}>
      <motion.h1
        initial='hidden'
        whileInView='visible'
        viewport={{ once: true, amount: 0.3 }}
        variants={h1Variants}
        custom={3}
        className='headline-1b'
      >
        {finished ? 'Zakończone konkursy' : 'Każdy głos się liczy'}
      </motion.h1>
      <motion.h4
        initial='hidden'
        whileInView='visible'
        viewport={{ once: true, amount: 0.3 }}
        variants={revealAndScaleVariants}
      >
        Zobacz prace Twojego dziecka i innych uczniów z klasy. Będąc
        zalogowanym, masz pełny dostęp do wszystkich funkcjonalności.
      </motion.h4>

      <SearchBar style={{ width: mobile ? '100%' : '450px' }} />

      <motion.div
        initial={false}
        animate={
          isLoaded && isInView
            ? { WebkitMaskImage: visibleMask, maskImage: visibleMask }
            : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
        }
        transition={{ duration: 1, delay: 1 }}
        viewport={{ once: true }}
        onViewportEnter={() => setIsInView(true)}
        className={s.imageWrapper}
      >
        <div className={s.image}>
          <img
            onLoad={() => setIsLoaded(true)}
            src={randomImage}
            alt='tablet'
          />

          <div className={`${s.decorations} ${chosenDecoration.className}`}>
            {chosenDecoration.images.map((image, index) => (
              <img key={index} src={image} alt={`decoration-${index + 1}`} />
            ))}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Header;
