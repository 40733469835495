import card from '../../../../components/Card/FlexCard.module.scss';
import globalColors from '../../../../styles/_colors.scss';
import button from '../../../../components/Button/Button.module.scss';
import { CaretCircleRightIcon } from '../../../../components/Icons/icons';
import s from './Gallery.module.scss';
import Items from './Items/Items';
import InfiniteHorizontalScroll from '../../../../components/InfiniteHorizontalScroll/InfiniteHorizontalScroll';
import Button from '../../../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  h1Variants,
  h1VariantsH,
} from '../../../../components/Animations/TextTransitions';
import { useMediaQuery } from 'usehooks-ts';

const Gallery = () => {
  const navigate = useNavigate();
  const mobile = useMediaQuery('(max-width: 768px)');
  return (
    <div className={s.wrapper}>
      <div className={card.allCards}>
        <div className={`${card.singleCard} ${s.cardsWrapper}`}>
          <div className={s.galleryWrapper}>
            <InfiniteHorizontalScroll baseVelocity={0.5}>
              {<Items />}
            </InfiniteHorizontalScroll>
          </div>
          <div
            onClick={() => navigate('/contests')}
            className={s.galleryWrapperBottom}
          >
            <motion.h1
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true, amount: 0.3 }}
              variants={mobile ? h1Variants : h1VariantsH}
              custom={1}
            >
              Nasze prace.Zobacz konkursy.
            </motion.h1>
            <Button
              className={`${button.button} ${button.contests}`}
              icon={<CaretCircleRightIcon fill={globalColors.blue4} />}
              onClick={() => navigate('/contests')}
            >
              <strong className='button-6 add-bold'>Konkursy</strong>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
