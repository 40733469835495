import axios from 'axios';
import { useAppSelector } from '../redux/hooks';
import { useEffect, useState } from 'react';

export const axiosDefaults = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
};

export const useAxiosAuthConfig = () => {
  const jwt = useAppSelector((state) => state.user.jwt);
  const [config, setConfig] = useState({
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });

  useEffect(() => {
    setConfig({
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
  }, [jwt]);

  return config;
};
