import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAppSelector } from '../redux/hooks';

export const useGetVoted = () => {
  const user = useAppSelector((state) => state.user);
  const loggedIn = user.loggedIn;

  const qs = require('qs');
  const query = qs.stringify(
    {
      filters: {
        user: {
          id: {
            $eq: user.userData?.id,
          },
        },
      },
      populate: {
        contestId: true,
        workId: true,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return useQuery({
    queryKey: ['votes'],
    queryFn: () => axios.get(`/votes?${query}`).then((res) => res.data.data),
    placeholderData: [],
    enabled: loggedIn,
  });
};
