import { motion } from 'framer-motion';
import Kindergarten1 from './kindergarten1.webp';
import Kindergarten2 from './kindergarten2.webp';
import Kindergarten3 from './kindergarten3.webp';
import Kindergarten4 from './kindergarten4.webp';
import Kindergarten5 from './kindergarten5.webp';
import s from './index.module.scss';

const KindergartenElements = () => {
  return (
    <div className={s.wrapper}>
      <motion.img
        transition={{
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 2,
          delay: 3,
        }}
        animate={{
          y: 10,
          rotateZ: 20,
          scale: 1,
        }}
        src={Kindergarten1}
        alt='kwiatek'
      ></motion.img>
      <motion.img
        transition={{
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 1,
          delay: 5,
        }}
        animate={{
          y: 10,
          rotateY: -30,
          scale: 1,
        }}
        src={Kindergarten2}
        alt='domek'
      ></motion.img>
      <motion.img
        transition={{
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 10,
          delay: 4,
        }}
        animate={{
          y: 10,
          rotateZ: 360,
          scale: 1,
        }}
        src={Kindergarten3}
        alt='zabawa dzieci'
      ></motion.img>
      <motion.img
        transition={{
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 1,
          delay: 2,
        }}
        animate={{
          y: 10,
          rotateX: 20,
          scale: 1,
        }}
        src={Kindergarten4}
        alt='dyplom'
      ></motion.img>
      <motion.img
        transition={{
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 2,
          delay: 2,
        }}
        animate={{
          x: 20,
          y: 10,
          scale: 1,
        }}
        src={Kindergarten5}
        alt='ołówek'
      ></motion.img>
    </div>
  );
};

export default KindergartenElements;
