import {
  useValidableState,
  validatePassword,
} from '../../../../utils/validation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CaretCircleRightIcon } from '../../../../components/Icons/icons';
import ButtonQuery from '../../../../query/ButtonQuery';
import { useResetPassword } from '../../../../api/auth';
import { useEffect } from 'react';
import Input from '../../../../components/Input/Input';
import Padlock from './padlock.webp';
import s from './ResetPassword.module.scss';
import button from '../../../../components/Button/Button.module.scss';
import { motion } from 'framer-motion';

const ResetPassword = () => {
  const [password, setPassword, isPasswordValid] = useValidableState<string>(
    '',
    validatePassword
  );
  const [repeatPassword, setRepeatPassword, isRepeatPasswordValid] =
    useValidableState('', (state) => password === state, [password]);
  const searchParams = useSearchParams()[0];
  const code = searchParams.get('code');
  const resetPassword = useResetPassword();
  const navigate = useNavigate();

  const isFormValid = isPasswordValid && isRepeatPasswordValid;

  useEffect(() => {
    if (!code) {
      navigate('/');
    }
  }, [code, navigate]);

  return code ? (
    <motion.div
      initial='hidden'
      animate='visible'
      exit={{ opacity: 0, transition: { duration: 1 } }}
      className={s.container}
    >
      <div className={s.card}>
        <h1 className='headline-1a'>Wpisz nowe hasło</h1>
        <div className={s.form}>
          <Input
            value={password}
            setValue={setPassword}
            password
            placeholder='Hasło'
            errorMessage={
              isPasswordValid
                ? ''
                : 'Hasło musi zawierać 8-16 znaków, jedną wielką literę, cyfrę.'
            }
          />

          <Input
            value={repeatPassword}
            setValue={setRepeatPassword}
            password
            placeholder='Powtórz hasło'
            errorMessage={
              isRepeatPasswordValid
                ? ''
                : 'Powtórzone hasło musi być identyczne jak hasło.'
            }
          />

          <ButtonQuery
            style={{ width: '212px', height: '36px' }}
            disabled={!isFormValid}
            mutation={resetPassword}
            mutationParam={{ password, code }}
            onMutationError={(error, reset) => {
              console.error(error);
              reset();
            }}
            className={`${button.button} ${button.login}`}
            icon={<CaretCircleRightIcon />}
            onMutationSuccess={() => {
              navigate('/login');
            }}
          >
            <p className='button-5'>Aktywuj hasło</p>
          </ButtonQuery>
        </div>
      </div>
      <div className={s.card}>
        <img src={Padlock} alt='padlock' />
      </div>
    </motion.div>
  ) : (
    <></>
  );
};

export default ResetPassword;
