import { IBasePopupProps, withPopup } from '../withPopup';
import s from './RankingPopup.module.scss';
import { CloseIcon } from '../../Icons/icons';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { IContest } from '../../../api/contest';
import { formatDate } from '../../../utils/datetime';
import { IPrize, useGetPrizes } from '../../../api/prize';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { motion } from 'framer-motion';
import { parseDateWithZone } from '../../../utils/api/timezone';
import Place1Badge from './img/place-1.webp';
import Place2Badge from './img/place-2.webp';
import Place3Badge from './img/place-3.webp';
import {
  isFinalRoundTime,
  isQualificationTime,
} from '../../../utils/api/contests';

export enum PopupTheme {
  Blue,
  Green,
}

interface IProps {
  popupTheme: PopupTheme;
  ranking: Array<any>;
  contest: IContest;
}

const RankingPopup = ({
  close,
  popupTheme,
  ranking,
  contest,
}: IBasePopupProps & IProps) => {
  const getPopupThemeClassName = (popupTheme: PopupTheme) => {
    switch (popupTheme) {
      case PopupTheme.Blue:
        return s.themeBlue;

      case PopupTheme.Green:
        return s.themeGreen;

      default:
        return '';
    }
  };

  const getCountdownCircleTheme = (popupTheme: PopupTheme) => {
    switch (popupTheme) {
      case PopupTheme.Blue:
        return 'url(#primary-school)';

      case PopupTheme.Green:
        return 'url(#kindergarten)';

      default:
        return 'url(#)';
    }
  };

  const getBadgeForPlace = (place: number) => {
    switch (place) {
      case 1:
        return Place1Badge;

      case 2:
        return Place2Badge;

      case 3:
        return Place3Badge;
    }
  };

  let initialTime: number | undefined;

  if (contest?.todaysDate) {
    initialTime = parseDateWithZone(contest.todaysDate);
  } else {
    initialTime = parseDateWithZone(new Date().toISOString());
  }

  const [now, setNow] = useState<number | undefined>(initialTime);
  const [isTimerPlaying, setIsTimerPlaying] = useState<boolean>(true);

  useEffect(() => {
    if (contest?.todaysDate) {
      setNow(parseDateWithZone(contest.todaysDate));
    } else {
      setNow(parseDateWithZone(new Date().toISOString()));
    }
  }, [contest?.todaysDate]);

  const [isQualificationRound, setIsQualificationRound] =
    useState<boolean>(false);

  const [isFinalRound, setIsFinalRound] = useState<boolean | null>(null);

  useEffect(() => {
    setIsQualificationRound(isQualificationTime(contest));
    setIsFinalRound(isFinalRoundTime(contest));
  }, [contest]);

  const startDate = useMemo(() => {
    if (isQualificationRound !== null && isFinalRound !== null) {
      if (isFinalRound) {
        return parseDateWithZone(contest?.mainRoundFrom);
      }
      return parseDateWithZone(contest?.qualificationFrom);
    }
    return undefined;
  }, [contest, isQualificationRound, isFinalRound]);

  const endDate = useMemo(() => {
    if (isQualificationRound !== null && isFinalRound !== null) {
      if (isFinalRound) {
        return parseDateWithZone(contest?.mainRoundTo);
      }
      return parseDateWithZone(contest?.qualificationTo);
    }
    return undefined;
  }, [contest, isQualificationRound, isFinalRound]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (now !== undefined && endDate !== undefined) {
        if (now >= endDate) {
          setIsTimerPlaying(false);
        } else {
          setNow((prevNow) =>
            prevNow !== undefined ? prevNow + 1000 : undefined
          );
        }
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [now, endDate]);

  const { data: prizes } = useGetPrizes();

  const mobile = useMediaQuery('(max-width: 768px)');

  const renderContestMessage = () => {
    if (isQualificationTime(contest)) {
      return <p className='button-2'>Do końca kwalifikacji zostało:</p>;
    } else {
      return <p className='button-2'>Do końca konkursu zostało:</p>;
    }
  };

  return (
    <div className={`${s.container} ${getPopupThemeClassName(popupTheme)}`}>
      <svg>
        <defs>
          <linearGradient
            id='primary-school'
            gradientUnits='userSpaceOnUse'
            x1='-9.05%'
            y1='34.51%'
            x2='109.05%'
            y2='65.49%'
          >
            <stop stopColor='#FFC826' />
            <stop offset='.513' stopColor='#FF903D' />
            <stop offset='1.071' stopColor='#ED6F9B' />
          </linearGradient>

          <linearGradient
            id='kindergarten'
            gradientUnits='userSpaceOnUse'
            x1='-9.23%'
            y1='33.85%'
            x2='109.23%'
            y2='66.15%'
          >
            <stop stopColor='#1570EC' />
            <stop offset='1.002' stopColor='#02B0F5' />
          </linearGradient>
        </defs>
      </svg>
      <div className={s.circle}>
        {renderContestMessage()}
        {startDate &&
          endDate &&
          now &&
          isQualificationRound !== null &&
          isFinalRound !== null && (
            <CountdownCircleTimer
              key={now}
              size={mobile ? 180 : 250}
              isPlaying={isTimerPlaying}
              initialRemainingTime={(endDate - now) / 1000}
              duration={(endDate - startDate) / 1000}
              colors={getCountdownCircleTheme(PopupTheme.Blue)}
            >
              {({ remainingTime }) => {
                let timeToShow;
                if (remainingTime < 3600) {
                  // less than 1 hour
                  // show in minutes and seconds
                  const minutes = Math.floor(remainingTime / 60);
                  const seconds = Math.floor(remainingTime % 60);
                  timeToShow = `${minutes} m ${seconds} s`;
                } else if (remainingTime < 86400) {
                  // less than 1 day
                  // show in hours, minutes and seconds
                  const hours = Math.floor(remainingTime / 3600);
                  const minutes = Math.floor((remainingTime % 3600) / 60);
                  const seconds = Math.floor((remainingTime % 3600) % 60);
                  timeToShow = `${hours} h ${minutes} m ${seconds} s`;
                } else {
                  // show in days, hours, minutes and seconds
                  const days = Math.floor(remainingTime / 86400);
                  const hours = Math.floor((remainingTime % 86400) / 3600);
                  const minutes = Math.floor((remainingTime % 3600) / 60);
                  const seconds = Math.floor((remainingTime % 3600) % 60);
                  timeToShow = (
                    <>
                      {`${days} d`} <br />{' '}
                      {`${hours} h ${minutes} m ${seconds} s`}
                    </>
                  );
                }
                return (
                  <div
                    className={`${s.circleText} ${getPopupThemeClassName(
                      popupTheme
                    )}`}
                  >
                    <h3 className='headline-3b'>{timeToShow}</h3>
                    <br />
                    <h3 className='button-3'>{formatDate(endDate)}</h3>
                  </div>
                );
              }}
            </CountdownCircleTimer>
          )}
      </div>

      <motion.h2 className={s.rankingTitle}>
        Aktualny ranking placówek
      </motion.h2>

      <div
        className={s.close}
        onClick={() => {
          if (close) {
            close();
          }
        }}
      >
        <p className='button-2'>Zamknij</p>
        <CloseIcon />
      </div>

      <div className={s.ranking}>
        <div className={s.top}>
          {[1, 2, 3].map((place) => {
            const prize = contest[
              `place${place}Prize` as keyof IContest
            ] as IPrize;
            const quantity = contest[
              `place${place}Quantity` as keyof IContest
            ] as string;

            return (
              <Fragment key={place}>
                {ranking[place - 1] && ranking[place - 1].schoolVote !== 0 && (
                  <div className={s.topEntry}>
                    <div className={s.entryVotes}>
                      <img
                        className={s.badge}
                        src={getBadgeForPlace(place)}
                        alt='badge'
                      />

                      <div className={s.schoolVotes}>
                        <h4>{ranking[place - 1].school.name}</h4>
                        <h3 className='add-bold'>
                          {place} msc - {ranking[place - 1].schoolVotes} głosów
                        </h3>
                      </div>
                    </div>

                    {prize &&
                      ranking[place - 1].schoolVotes >= prize.minVotesToWin && (
                        <div className={s.prize}>
                          <div>
                            {quantity !== null && <h4>{quantity} szt.</h4>}
                            <h4>{prize.name}</h4>
                          </div>

                          <img
                            className={s.prizeImage}
                            src={`${process.env.REACT_APP_HOST_URL}${
                              prizes?.[prize.id]?.media?.url
                            }`}
                            alt='prize'
                          />
                        </div>
                      )}
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>

        <div className={s.rest}>
          {[...Array(ranking.length + 1).keys()].slice(4).map((place) => (
            <Fragment key={place}>
              {ranking[place - 1].schoolVotes !== 0 && ranking[place - 1] && (
                <>
                  <p className='body-1 add-bold'>
                    {place} - {ranking[place - 1].schoolVotes} głosów
                  </p>
                  <p className='body-1'>{ranking[place - 1].school.name}</p>
                </>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withPopup(RankingPopup);
