import Airtable, { Records } from 'airtable';
import { useAppSelector } from '../redux/hooks';

export const useAirtable = () => {
  const apiKey = useAppSelector(
    (state) => state.adminUser.userData.airtableApiKey
  );

  Airtable.configure({
    endpointUrl: 'https://api.airtable.com',
    apiKey: apiKey,
  });
};

export const useGetCalendarBase = () => {
  return Airtable.base('appx96ML6tMgQgFyq');
};

export const filterAndMap = <T,>(
  records: Records<any>,
  fieldMapping: object,
  optionalFields?: Array<string>
): T[] => {
  const filteredRecords = records.filter(({ fields }) =>
    Object.keys(fieldMapping).every(
      (key) => key in fields || optionalFields?.includes(key)
    )
  );

  return filteredRecords.map(({ fields }) => {
    let result: any = {};

    Object.entries(fieldMapping).forEach(([airtableKey, objectKey]) => {
      result[objectKey] = fields[airtableKey];
    });

    return result;
  });
};
