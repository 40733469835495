import { IBasePopupProps, withPopup } from '../withPopup';
import { CaretCircleRightIcon } from '../../Icons/icons';
import Button from '../../Button/Button';
import Image from './favorites.webp';
import button from '../../Button/Button.module.scss';
import s from './NotQualifiedToFinalRoundPopup.module.scss';

const NotQualifiedToFinalRoundPopup = ({ close }: IBasePopupProps) => {
  return (
    <div className={s.container}>
      <img width='248px' className={s.center} src={Image} alt='votes' />
      <h3 className='headline-3b'>Nie możesz oddać głosu.</h3>
      <p className='body-3'>
        Praca nie przeszła do drugiego etapu głosowania.
        <br />
        Zdobyła zbyt małą liczbę głosów.
        <br />
        Zapraszamy do udziału w przyszłym konkursie.
      </p>
      <Button
        onClick={() => {
          if (close) {
            close();
          }
        }}
        className={`${button.button} ${button.login} ${s.center} ${s.button}`}
        icon={<CaretCircleRightIcon />}
      >
        <p className='button-5'>OK</p>
      </Button>
    </div>
  );
};

export default withPopup(NotQualifiedToFinalRoundPopup);
