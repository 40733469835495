import s from './WeekView.module.scss';
import v from '../Views.module.scss';
import { style, time } from '../../../const';
import AnimatorPopup from '../../Popups/AnimatorPopup/AnimatorPopup';
import SchoolPopup from '../../Popups/SchoolPopup/SchoolPopup';
import {
  formatDate,
  getWorkDatesBetween,
  getDateWorkWeek,
  getWeekdayLocaleString,
  minutesSince,
} from '../../../../../utils/datetime';
import { useMemo, useState } from 'react';
import {
  Animator,
  useGetGrades,
  useGetLessons,
  useGetSchools,
  useGetTeachers,
  School,
  Teacher,
  Grade
} from '../../../../../api/calendar';
import { abbreviateSchoolName } from '../../../../../utils/string';
import { useAppSelector } from '../../../../../redux/hooks';

interface WeekViewProps {
  animators: { [index: string]: Animator };
}

const WeekView = ({animators}: WeekViewProps) => {
  const currentDate = useAppSelector(
    (state) => new Date(state.calendar.currentDate)
  );
  const hourRows = 4;
  const rowMinutes = 60 / hourRows;

  const range = useMemo(() => getDateWorkWeek(currentDate), [currentDate]);
  const days = useMemo(
    () =>
      getWorkDatesBetween(range).map(
        (date) => `${getWeekdayLocaleString(date)} - ${formatDate(date)}`
      ),
    [range]
  );

  const { data: lessons } = useGetLessons(range);

  const schoolIds = useMemo(() => {
    if (lessons) {
      return [...new Set(lessons.filter((lesson) => lesson.school).map((lesson) => lesson.school[0]))];
    } else {
      return [];
    }
  }, [lessons]);

  const teacherIds = useMemo(() => {
    if (lessons) {
      return [...new Set(lessons.filter((lesson) => lesson.teacher).map((lesson) => lesson.teacher[0]))];
    } else {
      return [];
    }
  }, [lessons]);

  const gradeIds = useMemo(() => {
    if (lessons) {
      return [...new Set(lessons.filter((lesson) => lesson.grade).map((lesson) => lesson.grade[0]))];
    } else {
      return [];
    }
  }, [lessons]);

  const { data: schools } = useGetSchools(schoolIds);
  const { data: teachers } = useGetTeachers(teacherIds);
  const { data: grades } = useGetGrades(gradeIds);

  const [popup, setPopup] = useState<JSX.Element | null>();

  return animators && lessons && schools && teachers && grades ? (
    <div className={v.container}>
      {popup}

      <div className={s.weekDays}>
        <p className='body-2 add-bold'>Dzień</p>

        {days.map((day, index) => (
          <p key={index} className='body-2'>
            {day}
          </p>
        ))}
      </div>

      <div className={v.weekAnimators}>
        <p className='body-2 add-bold'>Animator</p>

        {days.map((_, dayIndex) => (
          <div key={dayIndex} className={v.animators}>
            {Object.values(animators).map((animator) => (
              <div
                key={animator.columnId}
                className={v.animator}
                onClick={(e) => {
                  setPopup(
                    <AnimatorPopup
                      close={() => setPopup(null)}
                      x={e.pageX}
                      y={e.pageY}
                      firstName={animator.firstName}
                      lastName={animator.lastName}
                      id={animator.id}
                      columnId={animator.columnId}
                    />
                  );
                }}
              >
                <div
                  className={`${v.ribbon} ${
                    style.ribbons[animator.columnId % style.ribbonsSize()]
                  }`}
                />
                <p className='field-2'>{`A${animator.id}`}</p>
              </div>
            ))}

            {dayIndex !== days.length - 1 && <div className={v.separator} />}
          </div>
        ))}
      </div>

      <div className={v.weekCalendar}>
        <div className={s.hours}>
          {Array.from(
            { length: time.hours() + 1 },
            (_, i) => i + time.startHour
          ).map((hour) => (
            <div className={v.hour} key={hour}>
              <p className='button-2'>{hour}:00</p>
              <div className={v.hourLine} />
            </div>
          ))}
        </div>

        {days.map((_, dayIndex) => (
          <div key={dayIndex} className={s.calendarDay}>
            {[
              ...Array(
                Object.keys(animators).length * (time.hours() * hourRows)
              ),
            ].map((_, index) => (
              <div key={index} className={s.calendarCell} />
            ))}

            {lessons
              .filter((lesson) => lesson.dayIndex === dayIndex)
              .map((lesson) => {
                const animator = animators[lesson.animator?.[0]];

                let school: School | null = null;
                if (lesson.school) {
                  school = schools[lesson.school?.[0]];
                }

                let teacher: Teacher | null = null;
                if (lesson.teacher) {
                  teacher = teachers[lesson.teacher?.[0]];
                }

                let grade: Grade | null = null;
                if (lesson.grade) {
                  grade = grades[lesson.grade?.[0]];
                }

                return (
                  animator && (
                    <div
                      key={lesson.airtableId}
                      className={`${s.calendarEntry} ${v.calendarEntry} ${
                        style.ribbons[animator.columnId % style.ribbonsSize()]
                      }`}
                      style={{
                        height: `calc(${s.cellHeight} * ${
                          minutesSince(lesson.startTime, lesson.endTime) /
                          rowMinutes
                        } - ${s.cellBorder})`,
                        top: `calc(${s.cellHeight} * ${
                          minutesSince(time.startHourStr(), lesson.startTime) /
                          rowMinutes
                        })`,
                        left: `calc(${animator.columnId} * ${v.animatorWidth})`,
                      }}
                      onClick={(e) => {
                        if (school && grade && teacher) {
                          setPopup(
                            <SchoolPopup
                              close={() => setPopup(null)}
                              x={e.pageX}
                              y={e.pageY}
                              address={school.address}
                              startTime={lesson.startTime}
                              endTime={lesson.endTime}
                              name={school.name}
                              gradeIdTeacher={grade.gradeIdTeacher}
                              animatorFirstName={animator.firstName}
                              columnId={animator.columnId}
                              teacherEmail={teacher.email}
                              teacherPhoneNumber={teacher.phoneNumber}
                              airtableLink={`https://airtable.com/appx96ML6tMgQgFyq/tbl0peE3jxzPEcGhM/viwJ2SlGLUVrWw8vz/${lesson.airtableId}`}
                            />
                          );
                        }
                      }}
                    >
                      {school &&
                      <p className='field-3 add-bold'>
                        {abbreviateSchoolName(school.name)}
                      </p>}
                    </div>
                  )
                );
              })}
          </div>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default WeekView;
