import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAxiosAuthConfig } from '../utils/axios';

export interface IComment {
  comment: string;
  workId: string | undefined;
  authorId: number | undefined;
}

export const usePostComment = () => {
  const config = useAxiosAuthConfig();

  return useMutation({
    mutationFn: ({ comment, workId, authorId }: IComment) =>
      axios.post(
        '/comments?id=' + workId,
        {
          data: {
            text: comment,
            work: workId,
            author: authorId,
          },
        },
        config
      ),
  });
};

export const useGetComments = (workId: string | undefined) => {
  const qs = require('qs');
  const query = qs.stringify(
    {
      filters: {
        work: {
          id: {
            $eq: workId,
          },
        },
      },
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    }
  );
  return useQuery({
    queryKey: ['get-comments', workId],
    queryFn: () =>
      axios.get(`/comments?${query}`).then((res) => {
        return res.data?.data;
      }),
  });
};
