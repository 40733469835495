import s from './Cards.module.scss';
import grid from '../../../../components/Card/FlexCardAlt.module.scss';
import Button from '../../../../components/Button/Button';
import { AddToFavoritesIcon } from '../../../../components/Icons/icons';
import globalColors from '../../../../styles/_colors.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import AddedToFavoritesPopup from '../../../../components/Popups/AddedToFavouritesPopup/AddedToFavouritesPopup';
import { useEffect, useState } from 'react';
import WithoutAccountPopup from '../../../../components/Popups/WithoutAccountPopup/WithoutAccountPopup';
import { useFavoriteUpdate } from '../../../../api/auth';
import { useAppDispatch } from '../../../../redux/hooks';
import { setFavoriteSchools } from '../../../../redux/slices/userSlice';
import { Divider } from '../../../../components/Divider/Divider';
import schoolStyles from '../../SchoolPages.module.scss';
import { scrollToId } from '../../../../utils/scroll';
import { sortAlphabeticallyAndNumerically } from '../../../../utils/sorting';

interface Props {
  cardData: any;
  cardWinner: any;
  cardRegular: any;
  cardWinnerImgStyle: any;
  cardAvatarImgStyle: any;
  color: string;
  actionButtonText: string;
  additionalText: string;
  showResultsSummary: boolean;
  showResultsText: string;
  noFavorites?: boolean;
  loggedIn?: boolean;
  user?: any;
}

const Schools = ({
  cardData,
  cardWinner,
  cardRegular,
  cardWinnerImgStyle,
  cardAvatarImgStyle,
  color,
  actionButtonText,
  noFavorites,
  loggedIn,
  user,
  showResultsSummary,
  showResultsText,
}: Props) => {
  const navigate = useNavigate();
  const { hash } = useLocation();

  const sortedCards = cardData?.sort(sortAlphabeticallyAndNumerically('name'));

  const dispatch = useAppDispatch();

  const [popupVisibleAddFavorites, setPopupVisibleAddFavorites] =
    useState(false);

  const [popupVisibleNoAccount, setPopupVisibleNoAccount] = useState(false);

  const favorites = useFavoriteUpdate();

  const addToFavorites = (cardId: number) => {
    const arr = [...user?.userData?.favoriteSchools, cardId];
    favorites.mutate(
      {
        favoriteSchools: arr,
      },
      {
        onSuccess: async () => {
          dispatch(setFavoriteSchools(arr));
          setPopupVisibleAddFavorites(true);
        },
        onError: async (e: any) => {
          console.log(e);
        },
      }
    );
  };

  const removeFromFavorites = (cardId: number) => {
    const arr = user?.userData?.favoriteSchools?.filter(
      (elem: any) => elem !== cardId
    );
    favorites.mutate(
      {
        favoriteSchools: arr,
      },
      {
        onSuccess: async () => {
          dispatch(setFavoriteSchools(arr));
        },
        onError: async (e: any) => {
          console.log(e);
        },
      }
    );
  };

  useEffect(() => {
    if (sortedCards && sortedCards.length > 0 && hash === '#schools-cards') {
      scrollToId('schools-cards', 50, 'start');
    }
  }, [hash, sortedCards]);

  return (
    <>
      <AddedToFavoritesPopup
        visible={popupVisibleAddFavorites}
        setVisible={setPopupVisibleAddFavorites}
      ></AddedToFavoritesPopup>
      <WithoutAccountPopup
        otherIncentive='Dodawanie szkół do ulubionych jest zarezerowane dla zarejestrowanych użytkowników. Dzięki tej funkcji masz szybszy dostęp do prac swojego dziecka i innych uczniów z jego szkoły.'
        visible={popupVisibleNoAccount}
        setVisible={setPopupVisibleNoAccount}
      ></WithoutAccountPopup>
      {showResultsSummary && cardData?.length > 0 ? (
        <>
          <div className={schoolStyles.divider}>
            <Divider height={'2px'} backgroundColor={'#89939E'} />
          </div>

          <div className={s.resultsSummary}>
            <span className='body-2'>Wyniki wyszukiwania: </span>
            <span className='body-2 add-bold'>
              {showResultsText} {cardData?.length}
            </span>
          </div>
        </>
      ) : (
        <> </>
      )}
      {sortedCards && (
        <div id='schools-cards' className={`${s.schools} ${grid.allCards}`}>
          {sortedCards.map((card: any, index: number) => {
            if (index === 0) {
              return (
                <div
                  onClick={(e) => {
                    navigate(window.location.pathname + '/' + card.id);
                  }}
                  style={{
                    backgroundColor: globalColors.winner1,
                  }}
                  key={index}
                  className={`${grid.singleCard}`}
                >
                  <div className={s.header}>
                    <p
                      style={{
                        color: globalColors.white2,
                      }}
                      className='button-5'
                    >
                      {card.address}
                    </p>

                    {!noFavorites ? (
                      loggedIn ? (
                        <AddToFavoritesIcon
                          onClick={(e: any) => {
                            e.stopPropagation();
                            user?.userData?.favoriteSchools?.find(
                              (elem: any) => elem === card.id
                            ) === undefined
                              ? addToFavorites(card.id)
                              : removeFromFavorites(card.id);
                          }}
                          stroke={globalColors.white2}
                          fill={
                            user?.userData?.favoriteSchools?.find(
                              (elem: any) => elem === card.id
                            ) !== undefined
                              ? globalColors.white2
                              : 'none'
                          }
                        />
                      ) : (
                        <AddToFavoritesIcon
                          onClick={(e: any) => {
                            e.stopPropagation();
                            setPopupVisibleNoAccount(true);
                          }}
                          fill={'none'}
                          stroke={globalColors.white2}
                        />
                      )
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <p
                    style={{
                      width: '100%',
                      color: globalColors.white2,
                      textAlign: 'start',
                    }}
                    className={`${s.schoolName} body-1`}
                  >
                    {card.name}
                  </p>
                  <img
                    style={cardWinnerImgStyle}
                    src={cardWinner}
                    alt='szkoła biorąca udział w lekcjach Pana Tablet'
                  ></img>
                  <div className={s.bottomWinner}>
                    <p
                      className='body-3'
                      style={{ color: globalColors.white2 }}
                    >
                      {/*{additionalText} {card.participatingGrades}*/}
                    </p>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(window.location.pathname + '/' + card.id);
                      }}
                      className='button-2'
                    >
                      <span
                        className='add-underline'
                        style={{ color: globalColors.white2 }}
                      >
                        {actionButtonText}
                      </span>
                    </Button>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  onClick={(e) => {
                    navigate(window.location.pathname + '/' + card.id);
                  }}
                  key={index}
                  className={grid.singleCard}
                >
                  {!noFavorites && (
                    <div className={s.header}>
                      <div></div>
                      <h4 style={{ color: color }}>
                        {/*{additionalText} {card.participatingGrades}*/}
                      </h4>
                      {loggedIn ? (
                        <AddToFavoritesIcon
                          onClick={(e: any) => {
                            e.stopPropagation();
                            user?.userData?.favoriteSchools?.find(
                              (elem: any) => elem === card.id
                            ) === undefined
                              ? addToFavorites(card.id)
                              : removeFromFavorites(card.id);
                          }}
                          fill={
                            user?.userData?.favoriteSchools?.find(
                              (elem: any) => elem === card.id
                            ) !== undefined
                              ? globalColors.orange1
                              : 'none'
                          }
                        />
                      ) : (
                        <AddToFavoritesIcon
                          onClick={(e: any) => {
                            e.stopPropagation();
                            setPopupVisibleNoAccount(true);
                          }}
                          fill={'none'}
                        />
                      )}
                    </div>
                  )}
                  <img
                    style={cardAvatarImgStyle}
                    src={cardRegular}
                    alt='szkoła biorąca udział w lekcjach Pana Tablet'
                  ></img>
                  <p className={`${s.schoolName} body-1`}>{card.name}</p>
                  <p className={`${s.addressOrName} button-5`}>
                    {card.address}
                  </p>
                  <Button
                    onClick={(e: any) => {
                      e.stopPropagation();
                      navigate(window.location.pathname + '/' + card.id);
                    }}
                    className='button-2'
                  >
                    <span className='add-underline' style={{ color: color }}>
                      {actionButtonText}
                    </span>
                  </Button>
                </div>
              );
            }
          })}
        </div>
      )}
    </>
  );
};

export default Schools;
