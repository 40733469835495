import ContestImage from './contest.webp';
import s from './CurrentContest.module.scss';
import button from '../../../../components/Button/Button.module.scss';
import { CaretCircleRightIcon } from '../../../../components/Icons/icons';
import Button from '../../../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { SchoolType, useGetContests } from '../../../../api/contest';
import { useMemo } from 'react';
import {
  ContestStatus,
  getValidContests,
} from '../../../../utils/api/contests';
import { formatDate } from '../../../../utils/datetime';
import globalColors from '../../../../styles/_colors.scss';
import { motion } from 'framer-motion';
import {
  h2Variants,
  h3Variants,
  pVariants,
  revealButtonVariants,
} from '../../../../components/Animations/TextTransitions';
import { rotateAndScaleVariants } from '../../../../components/Animations/ImageTransitions';
import { parseDateWithZone } from '../../../../utils/api/timezone';

interface IProps {
  schoolType: SchoolType;
}

const CurrentContest = ({ schoolType }: IProps) => {
  const contests = useGetContests();
  const validContest = useMemo(
    () => getValidContests(Object.values(contests.data))[schoolType],
    [contests, schoolType]
  );

  const navigate = useNavigate();
  return validContest?.contestType === ContestStatus.Active ? (
    <div className={s.container}>
      <div className={s.header}>
        <motion.h2
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, amount: 0.3 }}
          variants={h2Variants}
          className='headline-2 add-bold'
          custom={1}
        >
          Aktualny konkurs
        </motion.h2>

        <motion.p
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, amount: 0.3 }}
          variants={pVariants}
          custom={1}
          className='body-2'
        >
          Nie martw się, zanim pojawi się konkurs zostaniesz automatycznie
          powiadomiony o dacie jego rozpoczęcia oraz szczegółach.
        </motion.p>
      </div>

      <div className={s.contest}>
        <motion.img
          whileInView='visible'
          viewport={{ once: true, amount: 0.2 }}
          variants={rotateAndScaleVariants}
          src={ContestImage}
          alt='contest'
        />
        <div className={s.info}>
          <div className={s.dates}>
            <p className='button-5'>
              Rozpoczęcie głosowania:{' '}
              <b>
                {formatDate(parseDateWithZone(validContest.qualificationFrom))}
              </b>
            </p>

            <p className='button-5'>
              Zakończenie głosowania:{' '}
              <b>{formatDate(parseDateWithZone(validContest.mainRoundTo))}</b>
            </p>
          </div>

          <motion.h3
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true, amount: 0.3 }}
            variants={h3Variants}
            className='headline-3b'
          >
            {validContest.name}
          </motion.h3>
          <motion.p
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true, amount: 0.3 }}
            variants={pVariants}
            className='body-2'
          >
            {validContest.description}
          </motion.p>
          <motion.div
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true, amount: 0.3 }}
            custom={1}
            variants={revealButtonVariants}
          >
            <Button
              onClick={() =>
                navigate(window.location.pathname + `/${validContest.id}`)
              }
              className={`${button.button} ${button.voting}`}
              icon={<CaretCircleRightIcon fill={globalColors.white5} />}
            >
              <p className='button-4'>Dowiedz się więcej</p>
            </Button>
          </motion.div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default CurrentContest;
