import s from './Header.module.scss';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import Decoration1 from './image-decorations/decoration-1.webp';
import Decoration2 from './image-decorations/decoration-2.webp';
import Decoration3 from './image-decorations/decoration-3.webp';
import Decoration4 from './image-decorations/decoration-4.webp';
import Decoration5 from './image-decorations/decoration-5.webp';
import Decoration6 from './image-decorations/decoration-6.webp';
import Decoration7 from './image-decorations/decoration-7.webp';
import { motion } from 'framer-motion';
import {
  h1Variants,
  h4Variants,
} from '../../../../components/Animations/TextTransitions';
import { useMediaQuery } from 'usehooks-ts';

interface IProps {
  images: Array<string>;
}

const Header = ({ images }: IProps) => {
  const mobile = useMediaQuery('(max-width: 768px)');
  return (
    <div className={s.header}>
      <div className={s.tabletImage1}>
        <img src={images[0]} alt='tablet-1' />
        <img src={Decoration1} alt='decoration-1' />
      </div>
      <div className={s.tabletImage2}>
        <img src={images[1]} alt='tablet-2' />
        <img src={Decoration2} alt='decoration-2' />
      </div>
      <div className={s.tabletImage3}>
        <img src={images[2]} alt='tablet-3' />
        <img src={Decoration3} alt='decoration-3' />
      </div>
      <div className={s.tabletImage4}>
        <img src={images[3]} alt='tablet-4' />
        <img src={Decoration4} alt='decoration-4' />
      </div>
      <div className={s.tabletImage5}>
        <img src={images[4]} alt='tablet-5' />
        <img src={Decoration5} alt='decoration-5' />
      </div>
      <div className={s.tabletImage6}>
        <img src={images[5]} alt='tablet-6' />
        <img src={Decoration6} alt='decoration-6' />
        <img src={Decoration7} alt='decoration-7' />
      </div>
      <div className={s.headerMiddle}>
        <motion.h1
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, amount: 0.3 }}
          variants={h1Variants}
          custom={1}
          className='headline-1b'
        >
          Niech wygra kreatywność i zabawa.
        </motion.h1>
        <motion.h4 initial='hidden' animate='visible' variants={h4Variants}>
          Zobacz pracę Twojego dziecka i innych uczniów z klasy.<br></br>Będąc
          zalogowanym, masz pełny dostęp do wszystkich funkcjonalności.
        </motion.h4>
        <SearchBar style={{ width: mobile ? '100%' : '450px' }} />
      </div>
    </div>
  );
};

export default Header;
