import {
  animate,
  motion,
  useMotionValue,
  useMotionValueEvent,
  useTransform,
} from 'framer-motion';
import { useEffect, useState } from 'react';
import { h2Variants } from '../../../../components/Animations/TextTransitions';
import s from './DataBannerAlt.module.scss';

interface Props {
  img: string;
  className: string;
  school: any;
  classAttendance: number;
  style?: React.CSSProperties;
}

const DataBanner = ({
  img,
  className,
  style,
  classAttendance,
  school,
}: Props) => {
  const attendance = useMotionValue(0);
  const roundedAttendance = useTransform(attendance, Math.round);

  useEffect(() => {
    let animationAttendance;
    if (classAttendance) {
      animationAttendance = animate(attendance, classAttendance, {
        duration: 5,
      });
    }

    return animationAttendance?.stop;
  }, [attendance, classAttendance]);

  useMotionValueEvent(roundedAttendance, 'change', (latest) => {
    setAttendees(latest);
  });

  const [attendees, setAttendees] = useState(0);

  return (
    <div style={style} className={`${s.dataBanner} ${className}`}>
      <motion.h2
        className='headline-2'
        initial='hidden'
        whileInView='visible'
        viewport={{ once: true, amount: 0.3 }}
        variants={h2Variants}
      >
        <strong>
          <span
            style={
              attendees < 10
                ? { width: 'calc(1.5rem + 1.2vw)' }
                : attendees < 100
                ? { width: 'calc(3.5rem + 1.2vw)' }
                : { width: 'calc(6rem + 1.2vw)' }
            }
            className={s.motionVal}
          >
            {attendees}
          </span>
          %
        </strong>{' '}
        {school?.schoolType?.id === 1 ? 'grup' : 'klas'} w {school?.name} bierze
        udział w zajęciach Pana Tableta.
      </motion.h2>
      <img src={img} alt={'wybierz szkołę Pana Tableta'}></img>
    </div>
  );
};

export default DataBanner;
