import { Dispatch, SetStateAction } from 'react';
import { ReactComponent as ArrowDown } from './arrow-down.svg';
import { InputNumber } from 'primereact/inputnumber';
import s from './Counter.module.scss';

interface IProps {
  min?: number;
  value: number;
  setValue?: Dispatch<SetStateAction<number>>;
  inputClassName?: string;
  allowEmpty?: boolean;
  className?: string;
  disabled?: boolean;
}

const Counter = ({
  min,
  value,
  setValue,
  inputClassName,
  className,
  disabled,
  allowEmpty = false,
}: IProps) => {
  return (
    <div className={`${s.counter} ${disabled ? s.disabled : ''} ${className}`}>
      <InputNumber
        disabled={disabled}
        allowEmpty={allowEmpty}
        min={min}
        value={value}
        onValueChange={(e) => {
          if (setValue && e.value) {
            setValue(e.value);
          }
        }}
        className={`${s.input} ${inputClassName}`}
      />

      <div className={s.buttons}>
        <ArrowDown
          className={disabled ? s.disabled : ''}
          onClick={() => {
            if (setValue && !disabled) {
              setValue(value + 1);
            }
          }}
          style={{ transform: 'rotate(180deg)' }}
        />
        <ArrowDown
          className={disabled ? s.disabled : ''}
          onClick={() => {
            if (setValue && !disabled && (min === undefined || value > min)) {
              setValue(value - 1);
            }
          }}
        />
      </div>
    </div>
  );
};

export default Counter;
