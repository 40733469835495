import { NavLink, useNavigate } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { CaretCircleRightIcon } from '../Icons/icons';
import s from './Breadcrumbs.module.scss';
import globalColors from '../../styles/_colors.scss';
import Button from '../Button/Button';
import React from 'react';
import { useMediaQuery } from 'usehooks-ts';

interface Props {
  grades?: string;
  grade?: string;
  groups?: string;
  group?: string;
  workId?: string;
}

const Breadcrumbs = ({ grades, grade, groups, group, workId }: Props) => {
  const mobile = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();
  const routes = [
    { path: '/kindergarten', breadcrumb: 'Wybierz przedszkole' },
    { path: '/kindergarten/:kindergartenId', breadcrumb: groups },
    { path: '/kindergarten/:kindergartenId/:groupId', breadcrumb: group },
    {
      path: '/kindergarten/:kindergartenId/:groupId/:workId',
      breadcrumb: workId,
    },
    { path: '/elementary', breadcrumb: 'Wybierz szkołę' },
    { path: '/elementary/:schoolId', breadcrumb: grades },
    { path: '/elementary/:schoolId/:gradeId', breadcrumb: grade },
    { path: '/elementary/:schoolId/:gradeId/:workId', breadcrumb: workId },
    { path: '/', breadcrumb: 'Główna' },
  ];
  const breadcrumbs = useBreadcrumbs(routes);
  const breadcrumbsLength = breadcrumbs.length - 1;

  return (
    <div className={s.breadcrumbs}>
      <Button onClick={() => navigate(-1)}>
        <CaretCircleRightIcon fill={globalColors.black1} />
      </Button>
      <div className={s.labels}>
        {breadcrumbs.map(({ match, breadcrumb }: any, index: number) => {
          return (
            <React.Fragment key={index}>
              {mobile ? (
                index === breadcrumbsLength - 1 && (
                  <>
                    <NavLink
                      key={match.pathname}
                      className='button-3 add-underline'
                      to={match.pathname}
                    >
                      {breadcrumb.props.children}
                    </NavLink>
                  </>
                )
              ) : (
                <>
                  <NavLink
                    key={match.pathname}
                    className='button-3 add-underline'
                    to={match.pathname}
                  >
                    {breadcrumb.props.children}
                  </NavLink>
                  {index === breadcrumbsLength ? <></> : <p>-</p>}
                </>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Breadcrumbs;
