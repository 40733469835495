import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { CalendarView } from '../../pages/CalendarPage/const';

interface Calendar {
  currentDate: string;
  selectedView: CalendarView;
}

const initialState: Calendar = {
  currentDate: new Date().toString(),
  selectedView: CalendarView.Week,
};

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setCurrentDate: (state, action: PayloadAction<Date>) => {
      state.currentDate = action.payload.toString();
    },
    setSelectedView: (state, action: PayloadAction<CalendarView>) => {
      state.selectedView = action.payload;
    },
  },
});

export const { setCurrentDate, setSelectedView } = calendarSlice.actions;

export default calendarSlice.reducer;
