import s from './ChooseClass.module.scss';
import Dropdown from '../../../../components/Input/Dropdown/Dropdown';
import { useGetQualifiedSchools } from '../../../../api/work';
import { useParams } from 'react-router-dom';
import { useGetContest } from '../../../../api/contest';
import { useLocation } from '../../../../utils/location';
import { useSchoolParams } from '../../../../utils/api/school';
import { motion } from 'framer-motion';
import {
  h2Variants,
  pVariants,
} from '../../../../components/Animations/TextTransitions';

const ChooseClass = () => {
  const schoolType = window.location.pathname
    .split('contests/')
    .pop()
    ?.split('/')[0];
  const location = useLocation();
  const { schoolId, gradeId } = useSchoolParams();
  const { contestId } = useParams();
  const { data: contest } = useGetContest(contestId ?? '');
  const { data: schoolsData } = useGetQualifiedSchools({
    timeframe: contest
      ? {
          startDate: contest.qualificationFrom,
          endDate: contest.mainRoundTo,
        }
      : null,
    schoolType: contest?.schoolType?.id,
  });
  const schools = Object.values(schoolsData);

  return contest && schools.length > 0 ? (
    <div className={s.container}>
      <div className={s.header}>
        <motion.h2
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, amount: 0.3 }}
          variants={h2Variants}
          className='headline-2 add-bold'
          custom={1}
        >
          Wybierz szkołę i klasę
        </motion.h2>
        <motion.p
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, amount: 0.3 }}
          variants={pVariants}
          className='body-2'
        >
          Aby zobaczyć galerię prac, wybierz interesującą Cię szkołę oraz klasę
          i zobacz galerię, na którą możesz zagłosować.
        </motion.p>
      </div>
      <div className={s.dropdowns}>
        {schools.map((school: any) => (
          <div
            key={school.id}
            className={`${s.dropdown} ${
              schoolId === school.id.toString() ? s.selected : ''
            }`}
          >
            <p className='body-2 add-bold'>{school.name}</p>

            <Dropdown
              options={school.grades}
              inputClassName='button-3'
              setValue={
                (value) =>
                  location.push(
                    `/contests/${schoolType}/${contestId}/${school.id}/${
                      typeof value === 'string' ? value : value(gradeId ?? '')
                    }`
                  )
                // navigate(
                //   `/contests/${schoolType}/${contestId}/${school.id}/${
                //     typeof value === 'string' ? value : value(gradeId ?? '')
                //   }`
                // )
              }
              value={schoolId === school.id.toString() ? gradeId ?? '' : ''}
              placeholder='Wybierz klasę'
              onChange={() => {
                location.push('#contest-works');
              }}
            />
          </div>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ChooseClass;
