import s from './ContestCards.module.scss';
import PrimarySchoolContestImage from './primary-school-contest.webp';
import Button from '../../../../components/Button/Button';
import button from '../../../../components/Button/Button.module.scss';
import { CaretCircleRightIcon } from '../../../../components/Icons/icons';
import globalColors from '../../../../styles/_colors.scss';
import KindergartenContestImage from './kindergarten-contest.webp';
import { SchoolType, useGetContests } from '../../../../api/contest';
import {
  ContestStatus,
  getValidContests,
} from '../../../../utils/api/contests';
import { useCallback, useMemo } from 'react';
import { formatDate } from '../../../../utils/datetime';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  hCardVariants,
  RevealHCard,
} from '../../../../components/Animations/CardTransitions';
import { parseDateWithZone } from '../../../../utils/api/timezone';

const ContestCards = () => {
  const contests = useGetContests();

  const validContests = useMemo(
    () => getValidContests(Object.values(contests.data)),
    [contests]
  );

  const navigate = useNavigate();

  const getContestDataForSchoolType = useCallback(
    (schoolType: SchoolType) => {
      switch (validContests[schoolType]?.contestType) {
        case ContestStatus.Active:
          return {
            buttonLabel: 'Zobacz szczegóły',
            status: (
              <>
                Status konkursu:
                <br />
                <b>Rozpoczęty</b>
              </>
            ),
            details: (
              <>
                <div className='body-1'>Aktualny konkurs</div>
                <div className={s.texts}>
                  <p className='body-3'>
                    {schoolType === SchoolType.PrimarySchool
                      ? 'Konkurs dla szkół podstawowych'
                      : 'Konkurs dla przedszkoli'}
                  </p>
                  <h2 className={s.headline}>
                    {validContests[schoolType].name}
                  </h2>
                </div>
                <p className='body-1'>
                  Czas na głosowanie:
                  <br />
                  <b>
                    {formatDate(
                      parseDateWithZone(
                        validContests[schoolType].qualificationFrom
                      )
                    )}{' '}
                    -{' '}
                    {formatDate(
                      parseDateWithZone(validContests[schoolType].mainRoundTo)
                    )}
                  </b>
                </p>
              </>
            ),
          };
        case ContestStatus.Upcoming:
          return {
            buttonLabel: 'Zobacz szczegóły',
            status: (
              <>
                Najbliższy konkurs:
                <br />
                <b>
                  {formatDate(
                    parseDateWithZone(
                      validContests[schoolType].qualificationFrom
                    )
                  )}
                </b>
              </>
            ),
            details: (
              <>
                <div className='body-1'>Aktualny konkurs</div>
                <div className={s.texts}>
                  <p className='body-3'>
                    {schoolType === SchoolType.PrimarySchool
                      ? 'Konkurs dla szkół podstawowych'
                      : 'Konkurs dla przedszkoli'}
                  </p>
                  <h2 className={s.headline}>
                    {validContests[schoolType].name}
                  </h2>
                </div>
                <p className='body-1'>
                  Czas na głosowanie:
                  <br />
                  <b>
                    {formatDate(
                      parseDateWithZone(
                        validContests[schoolType].qualificationFrom
                      )
                    )}{' '}
                    -{' '}
                    {formatDate(
                      parseDateWithZone(validContests[schoolType].mainRoundTo)
                    )}
                  </b>
                </p>
              </>
            ),
          };
        default:
          return {
            buttonLabel: 'Zobacz zakończone konkursy',
            status: <></>,
          };
      }
    },
    [validContests]
  );

  const hasActiveContest = useCallback(
    (schoolType: SchoolType) =>
      validContests[schoolType]?.contestType === ContestStatus.Active,
    [validContests]
  );

  const hasUpcomingContest = useCallback(
    (schoolType: SchoolType) =>
      validContests[schoolType]?.contestType === ContestStatus.Upcoming,
    [validContests]
  );

  return contests.isLoading ? (
    <></>
  ) : (
    <div className={s.contestCards}>
      <RevealHCard className={`${s.contestCard} ${s.statusCard}`}>
        <motion.div
          onClick={() => {
            navigate('/contests/elementary');
          }}
          variants={hCardVariants}
          custom={-100}
        >
          <div className={`body-3 ${s.status}`}>
            {getContestDataForSchoolType(SchoolType.PrimarySchool).status}
          </div>
          <img
            width={'407px'}
            src={PrimarySchoolContestImage}
            alt='primary-school-contest'
          />
          <h2 className='headline-2 add-bold'>Konkursy szkół podstawowych</h2>
          <Button
            onClick={() =>
              navigate(
                '/contests/elementary/' +
                  validContests[SchoolType.PrimarySchool].id
              )
            }
            className={`${button.button} ${button.contests}`}
            icon={<CaretCircleRightIcon fill={globalColors.blue4} />}
          >
            <p className='button-5'>
              {
                getContestDataForSchoolType(SchoolType.PrimarySchool)
                  .buttonLabel
              }
            </p>
          </Button>
        </motion.div>
      </RevealHCard>
      {(hasActiveContest(SchoolType.PrimarySchool) ||
        hasUpcomingContest(SchoolType.PrimarySchool)) && (
        <RevealHCard
          className={`${s.contestCard} ${s.currentContestCard} ${
            hasActiveContest(SchoolType.Kindergarten) ||
            hasUpcomingContest(SchoolType.Kindergarten)
              ? s.small
              : s.big
          } ${s.primarySchool}`}
        >
          <motion.div
            onClick={() =>
              navigate(
                '/contests/elementary/' +
                  validContests[SchoolType.PrimarySchool].id
              )
            }
            style={{
              width: '100%',
              height: '100%',
            }}
            variants={hCardVariants}
            custom={100}
          >
            {getContestDataForSchoolType(SchoolType.PrimarySchool).details}
          </motion.div>
        </RevealHCard>
      )}
      {!hasActiveContest(SchoolType.PrimarySchool) &&
        !hasActiveContest(SchoolType.Kindergarten) &&
        !hasUpcomingContest(SchoolType.PrimarySchool) &&
        !hasUpcomingContest(SchoolType.Kindergarten) && (
          <RevealHCard
            className={`${s.contestCard} ${s.currentContestCard} ${s.big} ${s.calendar}`}
          >
            <motion.div
              style={{
                width: '100%',
                height: '100%',
              }}
              onClick={() =>
                window.open('https://pl-pl.facebook.com/PanTablet/', '_blank')
              }
              variants={hCardVariants}
              custom={100}
            >
              <div />
              <div className={s.texts}>
                <h2 className={s.headline}>Aktualnie brak konkursów</h2>
              </div>
              <Button
                onClick={() =>
                  window.open('https://pl-pl.facebook.com/PanTablet/', '_blank')
                }
                className={`${button.button} ${button.ranking}`}
                icon={<CaretCircleRightIcon />}
              >
                <p className='button-5'>Śledź nas na Facebooku</p>
              </Button>
            </motion.div>
          </RevealHCard>
        )}
      <RevealHCard className={`${s.contestCard} ${s.statusCard}`}>
        <motion.div
          onClick={() => navigate('/contests/kindergarten')}
          variants={hCardVariants}
          custom={-100}
        >
          <div className={`body-3 ${s.status}`}>
            {getContestDataForSchoolType(SchoolType.Kindergarten).status}
          </div>
          <img
            width={'316px'}
            src={KindergartenContestImage}
            alt='kindergarten-contest'
          />
          <h2 className='headline-2 add-bold'>Konkursy dla przedszkoli</h2>
          <Button
            onClick={() => navigate('/contests/kindergarten')}
            className={`${button.button} ${button.contests}`}
            icon={<CaretCircleRightIcon fill={globalColors.blue4} />}
          >
            <p className='button-5'>
              {getContestDataForSchoolType(SchoolType.Kindergarten).buttonLabel}
            </p>
          </Button>
        </motion.div>
      </RevealHCard>
      {(hasActiveContest(SchoolType.Kindergarten) ||
        hasUpcomingContest(SchoolType.Kindergarten)) && (
        <RevealHCard
          className={`${s.contestCard} ${s.currentContestCard} ${
            hasActiveContest(SchoolType.PrimarySchool) ||
            hasUpcomingContest(SchoolType.PrimarySchool)
              ? s.small
              : s.big
          } ${s.kindergarten}`}
        >
          <motion.div
            onClick={() =>
              navigate(
                '/contests/kindergarten/' +
                  validContests[SchoolType.Kindergarten].id
              )
            }
            style={{
              width: '100%',
              height: '100%',
            }}
            variants={hCardVariants}
            custom={100}
          >
            {getContestDataForSchoolType(SchoolType.Kindergarten).details}
          </motion.div>
        </RevealHCard>
      )}
    </div>
  );
};

export default ContestCards;
