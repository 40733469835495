export const abbreviateSchoolName = (schoolName: string) => {
  let result = '';

  schoolName.split(' ').every((word) => {
    const isNumber = !isNaN(parseInt(word));

    if (word !== 'nr' && !isNumber) {
      let filteredWord = '';

      if (word.charAt(0).toUpperCase() === word.charAt(0)) {
        [...word].forEach((letter) => {
          if (letter.toUpperCase() === letter) {
            filteredWord += letter;
          }
        });

        if (filteredWord.length > 0) {
          result += `${filteredWord}`;
        }
      }

      return true;
    }

    result += ` ${word}`;
    return !isNumber; // break loop on a number
  });

  return result;
};
