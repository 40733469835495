import globalColors from '../../styles/_colors.scss';
import SubHeader from '../../components/SubHeader/SubHeader';
import Header from './components/Header/Header';
import SocialMedia from '../../components/SocialMedia/SocialMedia';
import FinishedContestsList from './components/FinishedContestsList/FinishedContestsList';
import FinishedContestsListImage from './components/FinishedContestsList/primary-school.webp';
import ContestsCreated, {
  Theme,
} from './components/ContestsCreated/ContestsCreated';
import ContestsCreatedImage from './components/ContestsCreated/primary-school.webp';
import { SchoolType } from '../../api/contest';
import { motion } from 'framer-motion';
import { useGetTop50Works } from '../../api/work';
import { useEffect, useState } from 'react';

const ElementaryContestsListFinishedPage = () => {
  const { data } = useGetTop50Works();
  const [images, setImages] = useState([]);

  useEffect(() => {
    const urls = data?.map((elem: any) => {
      return process.env.REACT_APP_HOST_URL + elem?.media?.[0]?.url;
    });

    setImages(urls);
  }, [data]);

  return (
    <motion.div
      initial='hidden'
      animate='visible'
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <SubHeader color={globalColors.regular1Kinder} />
      <Header finished images={images} />
      <ContestsCreated
        theme={Theme.PrimarySchool}
        image={ContestsCreatedImage}
      />
      <FinishedContestsList
        schoolType={SchoolType.PrimarySchool}
        image={FinishedContestsListImage}
      />
      <SocialMedia />
    </motion.div>
  );
};

export default ElementaryContestsListFinishedPage;
