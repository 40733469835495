export function sortAlphabeticallyAndNumerically(property?: string) {
  return function (a: any, b: any) {
    let stringA: string;
    let stringB: string;

    if (property) {
      // If property is provided, we're comparing object properties
      stringA = a[property].match(/[\D]+/g) || [''];
      stringB = b[property].match(/[\D]+/g) || [''];
    } else {
      // If no property is provided, we're comparing strings directly
      stringA = a.match(/[\D]+/g) || [''];
      stringB = b.match(/[\D]+/g) || [''];
    }

    const stringComparison = stringA[0].localeCompare(stringB[0], 'pl');

    if (stringComparison !== 0) return stringComparison; // If string parts are not equal

    let numA = property ? a[property].match(/[\d]+/g) : a.match(/[\d]+/g);
    let numB = property ? b[property].match(/[\d]+/g) : b.match(/[\d]+/g);

    if (numA && numB) {
      // Both names have numerical parts
      return parseInt(numA[0]) - parseInt(numB[0]);
    }

    // If one or both names don't have a numerical part, just compare the string parts
    return stringComparison;
  };
}
