import s from './FinishedContests.module.scss';
import button from '../../../../components/Button/Button.module.scss';
import { CaretCircleRightIcon } from '../../../../components/Icons/icons';
import Button from '../../../../components/Button/Button';
import globalColors from '../../../../styles/_colors.scss';
import { useNavigate } from 'react-router-dom';
import {
  hCardVariants,
  RevealHCard,
} from '../../../../components/Animations/CardTransitions';
import { motion } from 'framer-motion';
import {
  h2Variants,
  pVariants,
  revealButtonVariants,
} from '../../../../components/Animations/TextTransitions';
import { useGetRandomWork } from '../../../../api/work';

interface IProps {
  image: string;
}

const FinishedContests = ({ image }: IProps) => {
  const navigate = useNavigate();
  const schoolType = window.location.pathname
    .split('contests/')
    .pop()
    ?.split('/')[0];

  const { data } = useGetRandomWork();

  const exampleImg =
    process.env.REACT_APP_HOST_URL + data?.[0]?.media?.[0]?.url;

  const exampleAuthor =
    data?.[0]?.author +
    ' - ' +
    data?.[0]?.grade?.name +
    ' - ' +
    data?.[0]?.school?.name;

  const exampleWorkSchoolType =
    data?.[0]?.school?.schoolType?.id === 1 ? 'kindergarten' : 'elementary';

  const exampleWorkPath = `/${exampleWorkSchoolType}/${data?.[0]?.school?.id}/${data?.[0]?.grade?.id}/${data?.[0]?.id}`;

  return (
    <div className={s.cards}>
      <RevealHCard>
        <motion.div
          onClick={() => navigate(`/contests/${schoolType}/finished`)}
          initial='offscreen'
          whileInView='onscreen'
          viewport={{ once: true, amount: 0.3 }}
          variants={hCardVariants}
          custom={-100}
          className={s.card}
        >
          <div className={s.texts}>
            <motion.h2
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true, amount: 0.3 }}
              variants={h2Variants}
              custom={1}
              className='headline-2 add-bold'
            >
              Zakończone konkursy
            </motion.h2>
            <motion.p
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true, amount: 0.3 }}
              variants={pVariants}
              custom={1}
              className='body-2'
            >
              Zobacz, jakie Kreatywne Konkursy już się odbyły i które szkoły i
              przedszkola oddały najwięcej głosów!
            </motion.p>
            <motion.div
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true, amount: 0.3 }}
              variants={revealButtonVariants}
              custom={1}
            >
              <Button
                className={`${button.button} ${button.contests} ${s.button}`}
                icon={<CaretCircleRightIcon fill={globalColors.blue4} />}
                onClick={() => navigate(`/contests/${schoolType}/finished`)}
              >
                <p className='button-6'>Czytaj więcej</p>
              </Button>
            </motion.div>
          </div>
          <img
            className={s.finishedContests}
            src={image}
            alt='finished-contests'
          />
        </motion.div>
      </RevealHCard>
      <RevealHCard>
        <motion.div
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(exampleWorkPath)}
          initial='offscreen'
          whileInView='onscreen'
          viewport={{ once: true, amount: 0.3 }}
          className={s.card}
          variants={hCardVariants}
          custom={100}
        >
          <img src={exampleImg} alt='example' />
          <p className='button-3'>{exampleAuthor}</p>
        </motion.div>
      </RevealHCard>
    </div>
  );
};

export default FinishedContests;
