import { useQueryClient } from '@tanstack/react-query';
import { EditorState, Modifier } from 'draft-js';
import { useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { usePostComment } from '../../../../api/comment';
import {
  BoldIcon,
  ItalicIcon,
  SendIcon,
  UnderlineIcon,
  EmojiIcon,
} from '../../../../components/Icons/icons';
import WithoutAccountPopup from '../../../../components/Popups/WithoutAccountPopup/WithoutAccountPopup';
import { useAppSelector } from '../../../../redux/hooks';
import s from './TextEditor.module.scss';
import { useSchoolParams } from '../../../../utils/api/school';

interface CommentProps {
  callback: () => void;
  workId: string | undefined;
  comment: string;
  userData: any;
}

const Comment = ({ workId, comment, callback, userData }: CommentProps) => {
  const postComment = usePostComment();
  const queryClient = useQueryClient();
  const [popupVisibleNoAccount, setPopupVisibleNoAccount] = useState(false);

  const addComment = () => {
    if (!userData) {
      setPopupVisibleNoAccount(true);
    } else {
      if (comment !== '') {
        postComment.mutate(
          {
            comment: comment,
            workId: workId,
            authorId: userData?.id,
          },
          {
            onSuccess: async () => {
              queryClient.invalidateQueries(['get-comments']);
              callback();
            },
            onError: async (e: any) => {
              console.log(e);
            },
          }
        );
      }
    }
  };

  return (
    <>
      <WithoutAccountPopup
        otherIncentive='Dodawanie komentarzy jest zarezerowane dla zarejestrowanych użytkowników.'
        visible={popupVisibleNoAccount}
        setVisible={setPopupVisibleNoAccount}
      ></WithoutAccountPopup>
      <SendIcon style={{ cursor: 'pointer' }} onClick={() => addComment()} />
    </>
  );
};

const TextEditor = () => {
  const params = useSchoolParams();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const editorRef = useRef<any>();
  const userData = useAppSelector((state) => state.user.userData);

  const onPostCommentSuccess = () => {
    editorRef.current.editor.blur();
    const blocks = editorState.getCurrentContent().getBlockMap().toList();
    const updatedSelection = editorState.getSelection().merge({
      anchorKey: blocks.first().get('key'),
      anchorOffset: 0,
      focusKey: blocks.last().get('key'),
      focusOffset: blocks.last().getLength(),
    });
    const newContentState = Modifier.removeRange(
      editorState.getCurrentContent(),
      updatedSelection,
      'forward'
    );
    setEditorState(
      EditorState.push(editorState, newContentState, 'remove-range')
    );
  };

  return (
    <Editor
      ref={editorRef}
      placeholder='Tutaj skomentujesz pracę swojego dziecka...'
      editorState={editorState}
      toolbarClassName={s.toolbarClassName}
      wrapperClassName={s.wrapperClassName}
      editorClassName={s.editorClassName}
      onEditorStateChange={setEditorState}
      toolbarCustomButtons={[
        <Comment
          userData={userData}
          callback={onPostCommentSuccess}
          comment={editorState.getCurrentContent().getPlainText()}
          workId={params.workId}
        />,
      ]}
      toolbar={{
        options: ['inline', 'emoji'],
        inline: {
          inDropdown: false,
          className: undefined,
          component: undefined,
          dropdownClassName: undefined,
          options: ['bold', 'italic', 'underline'],
          bold: { icon: BoldIcon, className: undefined },
          italic: { icon: ItalicIcon, className: undefined },
          underline: { icon: UnderlineIcon, className: undefined },
        },
        emoji: {
          icon: EmojiIcon,
          className: undefined,
          component: undefined,
          popupClassName: undefined,
          emojis: [
            '😀',
            '😁',
            '😂',
            '😃',
            '😉',
            '😋',
            '😎',
            '😍',
            '😗',
            '🤗',
            '🤔',
            '😣',
            '😫',
            '😴',
            '😌',
            '🤓',
            '😛',
            '😜',
            '😠',
            '😇',
            '😷',
            '😈',
            '👻',
            '😺',
            '😸',
            '😹',
            '😻',
            '😼',
            '😽',
            '🙀',
            '🙈',
            '🙉',
            '🙊',
            '👼',
            '👮',
            '🕵',
            '💂',
            '👳',
            '🎅',
            '👸',
            '👰',
            '👲',
            '🙍',
            '🙇',
            '🚶',
            '🏃',
            '💃',
            '⛷',
            '🏂',
            '🏌',
            '🏄',
            '🚣',
            '🏊',
            '⛹',
            '🏋',
            '🚴',
            '👫',
            '💪',
            '👈',
            '👉',
            '👉',
            '👆',
            '🖕',
            '👇',
            '🖖',
            '🤘',
            '🖐',
            '👌',
            '👍',
            '👎',
            '✊',
            '👊',
            '👏',
            '🙌',
            '🙏',
            '🐵',
            '🐶',
            '🐇',
            '🐥',
            '🐸',
            '🐌',
            '🐛',
            '🐜',
            '🐝',
            '🍉',
            '🍄',
            '🍔',
            '🍤',
            '🍨',
            '🍪',
            '🎂',
            '🍰',
            '🍾',
            '🍷',
            '🍸',
            '🍺',
            '🌍',
            '🚑',
            '⏰',
            '🌙',
            '🌝',
            '🌞',
            '⭐',
            '🌟',
            '🌠',
            '🌨',
            '🌩',
            '⛄',
            '🔥',
            '🎄',
            '🎈',
            '🎉',
            '🎊',
            '🎁',
            '🎗',
            '🏀',
            '🏈',
            '🎲',
            '🔇',
            '🔈',
            '📣',
            '🔔',
            '🎵',
            '🎷',
            '💰',
            '🖊',
            '📅',
            '✅',
            '❎',
            '💯',
          ],
        },
      }}
    />
  );
};

export default TextEditor;
