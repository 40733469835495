import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface AdminUserData {
  jwt: string;
  airtableApiKey: string;
}

interface AdminUser {
  userData: AdminUserData;
  loggedIn: boolean;
}

const initialState: AdminUser = {
  userData: {
    jwt: '',
    airtableApiKey: '',
  },
  loggedIn: false,
};

export const adminUserSlice = createSlice({
  name: 'adminUser',
  initialState,
  reducers: {
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    setUserData: (state, action: PayloadAction<AdminUserData>) => {
      state.userData = action.payload;
    },
  },
});

export const { setLoggedIn, setUserData } = adminUserSlice.actions;

export default adminUserSlice.reducer;
