import s from './MonthView.module.scss';
import v from '../Views.module.scss';
import { CalendarView, style, time } from '../../../const';
import { useMemo, useState } from 'react';
import {
  formatDate,
  getDateWorkMonth,
  getMonthFullWorkRange,
  getWorkDatesBetween,
  minutesSince,
} from '../../../../../utils/datetime';
import { Animator, useGetLessons } from '../../../../../api/calendar';
import AnimatorPopup from '../../Popups/AnimatorPopup/AnimatorPopup';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import {
  setCurrentDate,
  setSelectedView,
} from '../../../../../redux/slices/calendarSlice';

interface MonthViewProps {
  animators: { [index: string]: Animator };
}

const MonthView = ({animators}: MonthViewProps) => {
  const currentDate = useAppSelector(
    (state) => new Date(state.calendar.currentDate)
  );
  const dispatch = useAppDispatch();
  const days = ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek'];
  const dayRows = 4;
  const hourStep = 3;
  const rowMinutes = hourStep * 60;

  const daySteps = Math.ceil(time.hours() / hourStep) + 1;

  const range = useMemo(
    () => getMonthFullWorkRange(getDateWorkMonth(currentDate)),
    [currentDate]
  );
  const dates = useMemo(() => getWorkDatesBetween(range), [range]);
  const monthWeeks = dates.length / days.length;

  const { data: lessons } = useGetLessons(range);

  const [popup, setPopup] = useState<JSX.Element | null>();

  return lessons && animators ? (
    <div className={v.container}>
      {popup}

      <div className={s.weekDaysWrapper}>
        <div />

        <div className={s.weekDays}>
          {days.map((day, index) => (
            <div className={s.weekDay} key={index}>
              <p className='body-2'>{day}</p>
              {index !== days.length - 1 && <div className={v.separator} />}
            </div>
          ))}
        </div>
      </div>

      <div className={v.weekAnimators}>
        <p className='body-2 add-bold'>Animator</p>

        {days.map((_, dayIndex) => (
          <div key={dayIndex} className={v.animators}>
            {Object.values(animators).map((animator) => (
              <div
                key={animator.columnId}
                className={v.animator}
                onClick={(e) => {
                  setPopup(
                    <AnimatorPopup
                      close={() => setPopup(null)}
                      x={e.pageX}
                      y={e.pageY}
                      firstName={animator.firstName}
                      lastName={animator.lastName}
                      id={animator.id}
                      columnId={animator.columnId}
                    />
                  );
                }}
              >
                <div
                  className={`${v.ribbon} ${
                    style.ribbons[animator.columnId % style.ribbonsSize()]
                  }`}
                />
                <p className='field-2'>{`A${animator.id}`}</p>
              </div>
            ))}

            {dayIndex !== days.length - 1 && <div className={v.separator} />}
          </div>
        ))}
      </div>

      <div className={`${v.weekCalendar} ${s.weekCalendar}`}>
        <div className={s.hourRanges}>
          {[...Array(monthWeeks)].map((_, index) => (
            <div key={index} className={s.hours}>
              {Array.from(
                { length: daySteps },
                (_, i) => i * hourStep + time.startHour
              ).map((hour) => (
                <div className={v.hour} key={hour}>
                  <p className='button-2'>{hour}:00</p>
                  <div className={v.hourLine} />
                </div>
              ))}
            </div>
          ))}
        </div>

        {days.map((_, dayIndex) => (
          <div key={dayIndex} className={s.calendarWeek}>
            {[...Array(monthWeeks)].map((_, weekIndex) => (
              <div
                key={weekIndex}
                className={s.calendarDay}
                onClick={() => {
                  dispatch(
                    setCurrentDate(dates[weekIndex * days.length + dayIndex])
                  );
                  dispatch(setSelectedView(CalendarView.Week));
                }}
              >
                <div className={s.calendarColumns}>
                  {[...Array(Object.keys(animators).length)].map(
                    (_, columnIndex) => (
                      <div className={s.calendarColumn} key={columnIndex} />
                    )
                  )}
                </div>

                {lessons
                  .filter(
                    (lesson) =>
                      weekIndex * days.length + dayIndex === lesson.dayIndex
                  )
                  .map((lesson) => {
                    const animator = animators[lesson.animator[0]];

                    return (
                      animator && (
                        <div
                          key={lesson.airtableId}
                          className={`${s.calendarEntry} ${v.calendarEntry} ${
                            style.ribbons[
                              animator.columnId % style.ribbonsSize()
                            ]
                          }`}
                          style={{
                            height: `calc(${s.cellHeight} * ${
                              minutesSince(lesson.startTime, lesson.endTime) /
                              rowMinutes
                            } - ${s.cellBorder})`,
                            top: `calc(${s.cellHeight} * ${
                              minutesSince(
                                time.startHourStr(),
                                lesson.startTime
                              ) / rowMinutes
                            })`,
                            right: `calc(${
                              Object.keys(animators).length -
                              1 -
                              animator.columnId
                            } * ${s.entryWidth} * ${v.animatorWidth})`,
                          }}
                        />
                      )
                    );
                  })}

                {[...Array(dayRows)].map((_, rowIndex) => (
                  <div key={rowIndex} className={s.calendarCell}>
                    {rowIndex === 0 && (
                      <p className='body-2'>
                        {formatDate(dates[weekIndex * days.length + dayIndex])}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default MonthView;
