import s from './Items.module.scss';
import Button from '../../../../../components/Button/Button';
import button from '../../../../../components/Button/Button.module.scss';
import { DateStampPlaceholderIcon } from '../../../../../components/Icons/icons';
import { useGetTop5Works } from '../../../../../api/work';
import { formatDate, yearBook } from '../../../../../utils/datetime';
import { SchoolType, useGetContests } from '../../../../../api/contest';
import { useNavigate } from 'react-router-dom';
import { parseDateWithZone } from '../../../../../utils/api/timezone';
import { Fragment, useMemo } from 'react';

interface ContestDate {
  id: number;
  mainRoundTo: string;
}

const GalleryItems = () => {
  const navigate = useNavigate();
  const contests = useGetContests();

  const lastContest = (Object.values(contests?.data) as ContestDate[]).sort(
    (a, b) =>
      parseDateWithZone(b.mainRoundTo) > parseDateWithZone(a.mainRoundTo)
        ? 1
        : -1
  );
  const lastContestId = lastContest?.[0]?.id;

  const { data } = useGetTop5Works(lastContestId ?? 18);
  const works = useMemo(() => {
    if (data?.length > 0 && data?.length < 5) {
      return Array.from({ length: 5 }, (_, i) => data[i] ?? null);
    } else if (data?.length > 0) {
      return data;
    } else {
      return [];
    }
  }, [data]);

  const getWorkPlaceholderColor = (index: number) => {
    switch (index) {
      case 1:
        return '#9dc624';

      case 2:
        return '#ff7124';

      case 3:
        return '#ff4848';

      case 4:
        return '#0e9aff';

      case 5:
        return '#03cb8f';
    }
  };

  const onClickGallery = (work: any) => {
    const idPath = `${work.school.id}/${work.grade.id}/${work.id}`;

    switch (work.school.schoolType.id as SchoolType) {
      case SchoolType.PrimarySchool:
        navigate(`/elementary/${idPath}`);
        break;

      case SchoolType.Kindergarten:
        navigate(`/kindergarten/${idPath}`);
        break;
    }
  };

  return (
    <div className={s.galleryItems}>
      {works?.map((work: any, i: number) => (
        <Fragment key={i}>
          {work ? (
            <div
              onClick={() => onClickGallery(work)}
              key={work.id}
              className={`${s[`slideshowItem${i + 1}`]} ${s.slideshowItem}`}
            >
              <img
                src={process.env.REACT_APP_HOST_URL + work.media[0].url}
                alt='Prace dzieci na platformie'
              ></img>
              <section className={s.dateStamp}>
                <span>{formatDate(work.createdAt)}</span>
                <DateStampPlaceholderIcon
                  fill={getWorkPlaceholderColor(i + 1)}
                />
              </section>
              <section className={s.content}>
                <p>{work.votes} GŁOSÓW</p>
                <aside>
                  <p>{work.topic.category.name}</p>
                  <p>
                    {work.school.name.length > 100
                      ? work.school.name.substr(0, 99) + '...'
                      : work.school.name}
                  </p>
                  <p>
                    Klasa {work.grade.name}/{yearBook(work.createdAt)}
                  </p>
                </aside>
                <section className={s.workBottom}>
                  <p>{work.author}</p>
                  <Button
                    onClick={() => onClickGallery(work)}
                    className={`${button.button} ${button.gallery}`}
                  >
                    <span>Galeria</span>
                  </Button>
                </section>
              </section>
              <div className={s.content}></div>
            </div>
          ) : (
            <div className={`${s.slideshowItem}`} />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default GalleryItems;
