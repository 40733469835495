import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { mapArrayToObject } from '../utils/array';
import { IApiObject, IMediaObject } from '../utils/api/api';

export interface IPrize extends IApiObject {
  name: string;
  description: string;
  media: IMediaObject | null;
  minVotesToWin: number;
}

interface IPrizeObject {
  [key: number]: IPrize;
}

export const useGetPrizes = () => {
  return useQuery<IPrizeObject>({
    queryKey: ['prizes'],
    queryFn: () =>
      axios.get('/prizes?populate=*').then((res) =>
        mapArrayToObject(
          res.data?.data,
          (obj: any) => obj.id,
          (obj: any) => obj
        )
      ),
    placeholderData: {},
  });
};
