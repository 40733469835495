import { motion } from 'framer-motion';
import { MenuItem } from '../MenuItem/MenuItem';
import Register from '../../Header/MobilePictures/rejestracja.webp';
import Login from '../../Header/MobilePictures/logowanie.webp';
import School from '../../Header/MobilePictures/podstawowa.webp';
import Kindergarten from '../../Header/MobilePictures/przedszkole.webp';
import Contest from '../../Header/MobilePictures/konkursy.webp';
import Logout from '../../Header/MobilePictures/wyloguj.webp';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { logOut } from '../../../redux/slices/userSlice';

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const Navigation = () => {
  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector((state) => state.user.loggedIn);
  const categories = useMemo(
    () => [
      {
        img: School,
        desc: 'Wybór szkoły podstawowej',
        url: '/elementary',
      },
      {
        img: Kindergarten,
        desc: 'Wybór przedszkola',
        url: '/kindergarten',
      },
      {
        img: Contest,
        desc: 'Konkursy i nagrody',
        url: '/contests',
      },
      {
        img: loggedIn ? null : Register,
        desc: loggedIn ? '' : 'Zarejestruj się',
        url: loggedIn ? '' : '/pre-register',
      },
      {
        img: loggedIn ? Logout : Login,
        desc: loggedIn ? 'Wyloguj się' : 'Zaloguj się',
        url: loggedIn ? '/' : '/pre-login',
        fn: loggedIn ? () => dispatch(logOut()) : null,
      },
    ],
    [loggedIn, dispatch]
  );
  return (
    <motion.ul variants={variants}>
      {categories.map((category: any, index: number) => (
        <MenuItem
          img={category.img}
          desc={category.desc}
          url={category.url}
          fn={category.fn}
          key={index}
        />
      ))}
    </motion.ul>
  );
};
