import s from './Intro.module.scss';
import KindergartenElements from './KindergartenElements';
import SchoolElements from './SchoolElements';
import MonitorElements from './MonitorElements';
import { motion } from 'framer-motion';
import {
  h1Variants,
  h4Variants,
} from '../../../../components/Animations/TextTransitions';

const Intro = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.playground}>
        <MonitorElements />
        <SchoolElements />
        <KindergartenElements />
      </div>
      <motion.div initial='hidden' animate='visible'>
        <motion.h1 className='headline-1b' variants={h1Variants}>
          Śledź postępy, głosuj, bądź dumny!
        </motion.h1>
        <motion.h4 variants={h4Variants}>
          Odkrywaj talenty swojego dziecka z Panem Tabletem.<br></br>Zaloguj
          się, by otrzymać wszystkie materiały!
        </motion.h4>
      </motion.div>
    </div>
  );
};

export default Intro;
