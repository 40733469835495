import s from './SchoolPopup.module.scss';
import { withPopup, BasePopupProps } from '../withPopup';
import { style } from '../../../const';

interface Props {
  name: string;
  address: string;
  gradeIdTeacher: string;
  teacherEmail: string;
  teacherPhoneNumber: string;
  animatorFirstName: string;
  startTime: string;
  endTime: string;
  columnId: number;
  airtableLink: string;
}

const SchoolPopup = ({
  popupClassName,
  name,
  address,
  gradeIdTeacher,
  teacherEmail,
  teacherPhoneNumber,
  animatorFirstName,
  startTime,
  endTime,
  columnId,
  airtableLink,
}: BasePopupProps & Props) => {
  return (
    <div
      className={`${popupClassName} ${s.container} ${
        style.ribbons[columnId % style.ribbonsSize()]
      }`}
    >
      <p className='body-2 add-bold'>
        {name}
        <br />
        {address}
      </p>

      <div className={s.info}>
        <p className='body-3'>{gradeIdTeacher}</p>

        <p className='button-2'>
          {startTime}-{endTime}, Animator: {animatorFirstName}
        </p>
      </div>

      <div className={s.info}>
        <div className={s.contactInfo}>
          {teacherEmail && <p className='body-3'>{teacherEmail}</p>}
          {teacherPhoneNumber && <p className='body-3'>{teacherPhoneNumber}</p>}
        </div>

        <a
          target='_blank'
          rel='noopener noreferrer'
          href={airtableLink}
          className='button-2'
        >
          Link do Airtable
        </a>
      </div>
    </div>
  );
};

export default withPopup(SchoolPopup);
