import s from './AnimatorPopup.module.scss';
import { withPopup, BasePopupProps } from '../withPopup';
import { style } from '../../../const';

interface Props {
  firstName: string;
  lastName: string;
  id: number;
  columnId: number;
}

const AnimatorPopup = ({
  popupClassName,
  firstName,
  lastName,
  id,
  columnId,
}: BasePopupProps & Props) => {
  return (
    <div
      className={`${popupClassName} ${s.container} ${
        style.ribbons[columnId % style.ribbonsSize()]
      }`}
    >
      <p className='body-2 add-bold'>
        {firstName} {lastName}
      </p>
      <p className='button-2'>Animator A{id}</p>
    </div>
  );
};

export default withPopup(AnimatorPopup);
