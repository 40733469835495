import { Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';
import { CanVoteOnWork, getContestForWork, useCanVoteOnWork } from './contests';
import CannotVotePopup from '../../components/Popups/CannotVotePopup/CannotVotePopup';
import ThankYouVotingPopup from '../../components/Popups/ThankYouVotingPopup/ThankYouVotingPopup';
import { useGetContests } from '../../api/contest';
import { useQueryClient } from '@tanstack/react-query';
import { useAddVote } from '../../api/ranking';
import { useGetVoted } from '../../api/votes';
import NotQualifiedToFinalRoundPopup from '../../components/Popups/NotQualifiedToFinalRoundPopup/NotQualifiedToFinalRoundPopup';

export const useVoting = (
  card: any,
  loggedIn: boolean,
  popupNotLoggedIn: Dispatch<SetStateAction<boolean>>
): [ReactNode, (e: any) => void] => {
  const { data: votedWorks } = useGetVoted();
  const { data: contests } = useGetContests();
  const contest = useMemo(
    () => (card ? getContestForWork(card, Object.values(contests)) : null),
    [card, contests]
  );
  const canVote = useCanVoteOnWork(votedWorks, card, contest);
  const queryClient = useQueryClient();
  const addVote = useAddVote();

  const increment = (card: any) => {
    addVote.mutate(
      {
        schoolId: card.school.id,
        gradeId: card.grade.id,
        contestId: contest?.id,
        workId: card.id,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: ['ranking'],
          });
          await queryClient.invalidateQueries({
            predicate: (query) =>
              query.queryKey[0] === 'worksInfinite' &&
              query.queryKey[1] === card.school.id.toString() &&
              query.queryKey[2] === card.grade.id.toString(),
          });
          await queryClient.invalidateQueries({
            queryKey: ['votes'],
          });
          await queryClient.invalidateQueries({
            queryKey: ['works'],
          });
          setThankYouVotingPopup(true);
        },
        onError: async (e: any) => {
          console.log(e);
        },
      }
    );
  };
  const onClickIncrement = (e: any) => {
    e.stopPropagation();
    if (loggedIn) {
      if (canVote === CanVoteOnWork.Can) {
        increment(card);
      } else if (canVote === CanVoteOnWork.CantNotQualified ) {
        setNotQualifiedToFinalRoundPopup(true);
      } else {
        setCannotVotePopup(true);
      }
    } else {
      popupNotLoggedIn(true);
    }
  };
  const [thankYouVotingPopup, setThankYouVotingPopup] = useState(false);
  const [cannotVotePopup, setCannotVotePopup] = useState(false);
  const [notQualifiedToFinalRoundPopup, setNotQualifiedToFinalRoundPopup] =
    useState(false);

  return [
    <>
      <CannotVotePopup
        canVote={canVote}
        contest={contest}
        visible={cannotVotePopup}
        setVisible={setCannotVotePopup}
      />
      <ThankYouVotingPopup
        cardId={card?.id}
        visible={thankYouVotingPopup}
        setVisible={setThankYouVotingPopup}
      />
      <NotQualifiedToFinalRoundPopup
        visible={notQualifiedToFinalRoundPopup}
        setVisible={setNotQualifiedToFinalRoundPopup}
      />
    </>,
    onClickIncrement,
  ];
};
