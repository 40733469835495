import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { useEffect } from 'react';
import { logOut } from '../redux/slices/userSlice';
import { useCookies } from 'react-cookie';

export const useUserSession = () => {
  const rememberMe = useAppSelector((state) => state.user.rememberMe);
  const cookies = useCookies(['session'])[0];
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (cookies) {
      if (!rememberMe && !cookies.session) {
        dispatch(logOut());
      }
    }
  }, [dispatch, rememberMe, cookies]);
};
