import { IBasePopupProps, withPopup } from '../withPopup';
import Image from './checkmark.webp';
import Button from '../../Button/Button';
import button from '../../Button/Button.module.scss';
import s from './ThankYouVoting.module.scss';
import { useGetContests } from '../../../api/contest';
import { useEffect, useMemo } from 'react';
import {
  getContestForWork,
  getSchoolsRankingObject,
  getVotesLeft,
} from '../../../utils/api/contests';
import { useSchoolParams } from '../../../utils/api/school';
import { useGetRanking } from '../../../api/ranking';
import { useGetWorks } from '../../../api/work';
import { useGetVoted } from '../../../api/votes';

interface IProps {
  cardId: string;
}

const ThankYouVotingPopup = ({
  close,
  visible,
  cardId,
}: IBasePopupProps & IProps) => {
  const { data: works } = useGetWorks({ workId: cardId });
  const card = works?.[0];
  const params = useSchoolParams();
  const schoolId = params.schoolId;

  const { data: votedWorks } = useGetVoted();
  const { data: contests } = useGetContests();
  const contest = useMemo(
    () => (card ? getContestForWork(card, Object.values(contests)) : null),
    [card, contests]
  );
  const { data: rankingApi, refetch: refetchRanking } = useGetRanking({
    contestId: contest?.id ? +contest.id : -1,
  });
  const schoolRanking: any = useMemo(
    () => getSchoolsRankingObject(rankingApi),
    [rankingApi]
  );
  const school = useMemo(
    () => (schoolId ? schoolRanking[schoolId] : null),
    [schoolRanking, schoolId]
  );
  const votesLeft = useMemo(
    () =>
      contest && card && votedWorks ? getVotesLeft(votedWorks, contest) : 0,
    [votedWorks, card, contest]
  );

  useEffect(() => {
    if (visible) {
      refetchRanking().then(() => {});
    }
  }, [refetchRanking, visible]);

  return contest && card && school ? (
    <div className={s.container}>
      <img src={Image} alt='checkmark' />

      <div className={s.content}>
        <div className={s.header}>
          <h3 className='headline-3b'>Dziękujemy za oddanie głosu na pracę.</h3>
          <p className='body-1'>
            Możesz jeszcze oddać{' '}
            <span className='headline-3b'>{votesLeft}</span> głosów na inne
            prace. Zachęcamy do czynnego udziału w naszej społeczności.
          </p>
        </div>

        <div className={s.buttons}>
          <Button
            onClick={() => {
              if (close) {
                close();
              }
            }}
            className={`${button.button} ${button.voting}`}
          >
            <p className='button-5'>Świetnie, głosuję dalej</p>
          </Button>

          <p
            style={{ cursor: 'pointer' }}
            onClick={() => {
              window.open('https://pl-pl.facebook.com/PanTablet/', '_blank');
              if (close) {
                close();
              }
            }}
            className='field-1'
          >
            Społeczność Pana Tableta
          </p>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default withPopup(ThankYouVotingPopup);
