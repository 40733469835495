import { motion } from 'framer-motion';
import { h4Variants } from '../../../../components/Animations/TextTransitions';
import s from './IntroMessage.module.scss';

const IntroMessage = () => {
  return (
    <div className={s.wrapper}>
      <motion.div
        initial='hidden'
        animate='visible'
        exit={{ opacity: 0, transition: { duration: 1 } }}
        variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      >
        <motion.h4 variants={h4Variants}>
          Odkrywaj talenty swojego dziecka z Panem Tabletem.<br></br>Zaloguj
          się, by otrzymać wszystkie materiały!
        </motion.h4>
      </motion.div>
    </div>
  );
};

export default IntroMessage;
