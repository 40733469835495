import Logo from '../PageBar/logo.svg';
import { Link } from 'react-router-dom';
import pageBar from '../PageBar/PageBar.module.scss';
import s from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={`${pageBar.bar} ${s.footer}`}>
      <div className={pageBar.left}>
        <Link to='/'>
          <img src={Logo} alt='logo' />
        </Link>

        <div className={`${pageBar.links} `}>
          <Link
            to='#'
            onClick={() =>
              window.open(
                'https://docs.google.com/document/d/1hDSifKJMemoZnoglJYlZq7PKqYABbsrT',
                '_blank'
              )
            }
          >
            <p className='button-2 add-underline'>Regulamin</p>
          </Link>
          <Link
            to='#'
            onClick={() =>
              window.open(
                'https://docs.google.com/document/d/1dms5BuY3q7uuZhB2ICgpNMhH_Xfn3b98',
                '_blank'
              )
            }
          >
            <p className='button-2 add-underline'>Polityka Prywatności</p>
          </Link>
        </div>
      </div>

      <div className={`${pageBar.right} `}>
        <Link
          to='#'
          onClick={() => window.open('https://dchouse.pl/', '_blank')}
        >
          <p className='button-2 add-underline'>
            Realizacja: Dimensione Creativa 2023
          </p>
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
