import { MouseEventHandler, useCallback } from 'react';
import ImageGallery from 'react-image-gallery';
import Button from '../../../../components/Button/Button';
import { CaretCircleRightIcon } from '../../../../components/Icons/icons';
import globalColors from '../../../../styles/_colors.scss';
import { useGetWorks } from '../../../../api/work';
import { useSchoolParams } from '../../../../utils/api/school';
import { useLocation } from '../../../../utils/location';

interface NavButtonProps {
  arrowType: string;
  onClick: MouseEventHandler<HTMLElement>;
}

interface WorkGalleryProps {
  cardData: any;
  contestWorks?: any;
}

const renderNavButton = ({ arrowType, onClick }: NavButtonProps) => {
  return (
    <Button
      onClick={onClick}
      className={`image-gallery-icon image-gallery-${arrowType}-nav`}
    >
      <CaretCircleRightIcon width={48} height={48} fill={globalColors.blue4} />
    </Button>
  );
};

const WorkGallery = ({ cardData, contestWorks }: WorkGalleryProps) => {
  const location = useLocation();
  const { schoolId, gradeId } = useSchoolParams();
  const urlPrefix = process.env.REACT_APP_HOST_URL;
  const { data: gradeWorks } = useGetWorks({
    school: schoolId,
    grade: gradeId,
  });
  const authorWorks = (
    contestWorks?.length > 0 ? contestWorks : gradeWorks
  )?.filter((work: any) => work.author === cardData?.[0]?.author);
  const works = authorWorks.map((work: any) => work.id);
  const currentIndex = works.indexOf(cardData?.[0]?.id);
  const onClick = useCallback(
    (id: number) => {
      location.push(id.toString());
    },
    [location]
  );
  const images = authorWorks.map((work: any) => ({
    original: urlPrefix + work.media?.[0]?.url,
  }));
  return (
    <>
      <ImageGallery
        startIndex={currentIndex}
        showPlayButton={false}
        onSlide={(index) => onClick(works[index])}
        renderRightNav={(onClick) =>
          renderNavButton({
            arrowType: 'right',
            onClick: onClick,
          })
        }
        renderLeftNav={(onClick) =>
          renderNavButton({
            arrowType: 'left',
            onClick: onClick,
          })
        }
        showBullets={true}
        showThumbnails={false}
        showFullscreenButton={false}
        items={images}
      />
    </>
  );
};

export default WorkGallery;
