import { IContest, SchoolType } from '../../api/contest';
import { parseDateWithZone } from './timezone';

export enum ContestStatus {
  Active,
  Upcoming,
  Finished,
}

export interface IValidContest extends IContest {
  contestType: ContestStatus;
}

interface IValidContests {
  [schoolTypeId: number]: IValidContest;
}

export const getContestStatus = (contest: IContest): ContestStatus => {
  const startDate = parseDateWithZone(contest.qualificationFrom);
  const endDate = parseDateWithZone(contest.mainRoundTo);

  const now = contest?.todaysDate
    ? parseDateWithZone(contest.todaysDate)
    : parseDateWithZone(new Date().toISOString());

  if (now < startDate) {
    return ContestStatus.Upcoming;
  } else if (now >= startDate && now <= endDate) {
    return ContestStatus.Active;
  }
  return ContestStatus.Finished;
};

export const canVoteOnContestDate = (contest: IContest): boolean => {
  const now = contest?.todaysDate
    ? parseDateWithZone(contest.todaysDate)
    : parseDateWithZone(new Date().toISOString());
  return (
    (now >= parseDateWithZone(contest.qualificationFrom) &&
      now <= parseDateWithZone(contest.qualificationTo)) ||
    (now >= parseDateWithZone(contest.mainRoundFrom) &&
      now <= parseDateWithZone(contest.mainRoundTo))
  );
};

export const getValidContestForSchoolType = (
  contests: Array<IContest>,
  schoolType: SchoolType
): IValidContest | null => {
  let validContest: IValidContest | null = null;
  for (let contest of contests) {
    if (contest.schoolType.id === schoolType) {
      const status = getContestStatus(contest);
      if (status === ContestStatus.Upcoming) {
        validContest = {
          ...contest,
          contestType: ContestStatus.Upcoming,
        };
      } else if (status === ContestStatus.Active) {
        return {
          ...contest,
          contestType: status,
        };
      }
    }
  }

  return validContest;
};

export const getValidContests = (contests: Array<IContest>): IValidContests => {
  let validContests: IValidContests = {};

  let validPrimaryContest = getValidContestForSchoolType(
    contests,
    SchoolType.PrimarySchool
  );
  let validKindergartenContest = getValidContestForSchoolType(
    contests,
    SchoolType.Kindergarten
  );

  if (validPrimaryContest) {
    validContests[SchoolType.PrimarySchool] = validPrimaryContest;
  }

  if (validKindergartenContest) {
    validContests[SchoolType.Kindergarten] = validKindergartenContest;
  }

  return validContests;
};

export const getFinishedContests = (
  contests: Array<IContest>,
  schoolType: SchoolType
) =>
  contests.filter((contest) => {
    const endDate = parseDateWithZone(contest.mainRoundTo);

    const now = contest?.todaysDate
      ? parseDateWithZone(contest.todaysDate)
      : parseDateWithZone(new Date().toISOString());

    return now > endDate && contest.schoolType.id === schoolType;
  });

export function getMinimumVotesToQualify(work: any, contest: IContest): number {
  // Parse ISO strings into milliseconds
  const workDateMillis = parseDateWithZone(work?.createdAt);
  const qualificationToDateMillis = parseDateWithZone(contest?.qualificationTo);

  // Convert milliseconds back into Date objects
  const workDate = new Date(workDateMillis);
  const qualificationToDate = new Date(qualificationToDateMillis);

  // Adjust Date objects to the start of the day
  workDate.setHours(0, 0, 0, 0);
  qualificationToDate.setHours(0, 0, 0, 0);

  // Calculate the difference in full days between the end of the competition and the day of the work
  const differenceInDays = Math.ceil(
    Math.abs(qualificationToDate.getTime() - workDate.getTime()) /
      (1000 * 60 * 60 * 24) + 1
  );

  // Calculate the minimum number of votes needed to move on to the next stage
  const minimumVotes = differenceInDays * contest?.multiplier;

  return minimumVotes;
}

export function doesProceedToNextStage(work: any, contest: IContest): boolean {
  if (isWorkForContest(contest, work)) {
    // Get minimum votes to qualify to 2nd round
    const minimumVotes = getMinimumVotesToQualify(work, contest);

    // If work is qualified or not
    const isQualified = work?.votes?.length >= minimumVotes;

    return isQualified;
  } else {
    return false;
  }
}

export const isWorkForContest = (contest: IContest, work: any) => {
  const startDate = parseDateWithZone(contest?.worksFrom);
  const endDate = parseDateWithZone(contest?.worksTo);
  const workDate = parseDateWithZone(work?.createdAt);

  return (
    contest?.schoolType.id === work?.school?.schoolType?.id &&
    workDate >= startDate &&
    workDate <= endDate
  );
};

export const isQualificationTime = (contest: IContest) => {
  const now = contest?.todaysDate
    ? parseDateWithZone(contest.todaysDate)
    : parseDateWithZone(new Date().toISOString());
  return (
    now >= parseDateWithZone(contest?.qualificationFrom) &&
    now <= parseDateWithZone(contest?.qualificationTo)
  );
};

export const isFinalRoundTime = (contest: IContest) => {
  const now = contest?.todaysDate
    ? parseDateWithZone(contest.todaysDate)
    : parseDateWithZone(new Date().toISOString());
  return (
    now >= parseDateWithZone(contest?.mainRoundFrom) &&
    now <= parseDateWithZone(contest?.mainRoundTo)
  );
};

export const getWorksForContest = (contest: IContest, works: Array<any>) =>
  works.filter((work) => isWorkForContest(contest, work));

export const getContestForWork = (work: any, contests: Array<IContest>) =>
  contests.filter((contest) => isWorkForContest(contest, work))?.[0];

export const getSchoolsRankingObject = (ranking: Array<any>) => {
  let schools: any = {};

  for (let rankingEntry of ranking) {
    if (rankingEntry.school.id in schools) {
      schools[rankingEntry.school.id].schoolVotes +=
        rankingEntry.votes.length ?? 0;
    } else {
      schools[rankingEntry.school.id] = {
        ...rankingEntry,
        schoolVotes: rankingEntry.votes.length ?? 0,
      };
    }
  }

  return schools;
};

export const getSchoolsRanking = (ranking: Array<any>) => {
  let sortedSchools = Object.values(getSchoolsRankingObject(ranking));
  sortedSchools
    .sort((a: any, b: any) => (a.schoolVotes > b.schoolVotes ? 1 : -1))
    .reverse();
  return sortedSchools;
};

export const getGradeVotes = (
  ranking: Array<any>,
  schoolId: string,
  gradeId: string
) =>
  ranking.reduce(
    (acc, work) =>
      work.school.id.toString() === schoolId &&
      work.grade.id.toString() === gradeId
        ? acc + work.votes.length
        : acc,
    0
  );

export const getSchoolsParticipating = (works: Array<any>) => {
  let schools: any = {};

  for (let work of works) {
    if (!(work.school.id in schools)) {
      schools[work.school.id] = {
        ...work.school,
        grades: {},
      };
    }

    schools[work.school.id].grades[work.grade.id] = work.grade.name;
  }

  return schools;
};

export enum CanVoteOnWork {
  Can,
  CantAlreadyVoted,
  CantAboveContestLimit,
  CantNotQualified,
  Cannot,
}

export const useCanVoteOnWork = (
  votedWorks: any,
  work: any,
  contest: IContest | null
): CanVoteOnWork => {
  if (
    !votedWorks ||
    !work ||
    votedWorks.some((item: any) => item.workId.id === work.id)
  ) {
    return CanVoteOnWork.CantAlreadyVoted;
  }

  const contestsVotes: any = {};
  for (let item of votedWorks) {
    const itemContest = item.contestId;

    if (itemContest?.id in contestsVotes) {
      contestsVotes[itemContest?.id] += 1;
    } else {
      contestsVotes[itemContest?.id] = 1;
    }
  }

  if (contest) {
    let canVote = false;

    if (contest?.id in contestsVotes) {
      canVote = contestsVotes[contest?.id] < contest.numberOfVotes;
    } else {
      canVote = true;
    }

    if (canVote) {
      const isQualified = doesProceedToNextStage(work, contest);
      if (!isQualified && isFinalRoundTime(contest)) {
        return CanVoteOnWork.CantNotQualified;
      } else {
        return CanVoteOnWork.Can;
      }
    } else {
      return CanVoteOnWork.CantAboveContestLimit;
    }
  }

  return CanVoteOnWork.Cannot;
};

export const getVotesLeft = (votedWorks: any, contest: IContest) => {
  const contestsVotes: any = {};

  for (let item of votedWorks) {
    const itemContest = item?.contestId;

    if (itemContest?.id in contestsVotes) {
      contestsVotes[itemContest?.id] += 1;
    } else {
      contestsVotes[itemContest?.id] = 1;
    }
  }

  return contest?.id in contestsVotes
    ? contest?.numberOfVotes - contestsVotes[contest?.id]
    : 0;
};
