import s from './const.module.scss';

export enum CalendarView {
  Week,
  Month,
}

interface Style {
  ribbons: { [index: number]: any };
  ribbonsSize: () => number;
}

export const style: Style = {
  ribbons: {
    0: s.ribbon1,
    1: s.ribbon2,
    2: s.ribbon3,
    3: s.ribbon4,
    4: s.ribbon5,
    5: s.ribbon6,
    6: s.ribbon7,
    7: s.ribbon8,
  },
  ribbonsSize: () => Object.keys(style.ribbons).length,
};

export const time = {
  startHour: 7,
  endHour: 18,
  hours: () => time.endHour - time.startHour,
  startHourStr: () => `${String(time.startHour).padStart(2, '0')}:00`,
};
