import pageBar from '../PageBar/PageBar.module.scss';
import { CaretCircleRightIcon } from '../Icons/icons';
import button from '../../components/Button/Button.module.scss';
import s from './SubHeader.module.scss';
import globalColors from '../../styles/_colors.scss';
import Button from '../Button/Button';
import { animation } from '../../utils/animation';

interface Props {
  color: string;
}

const SubHeader = ({ color }: Props) => {
  return (
    <>
      <header
        style={{ backgroundColor: color }}
        className={`${pageBar.bar} ${s.subHeader}`}
      >
        <div className={pageBar.left}>
          <animation.h4>
            Chcesz wnieść naukę swojego dziecka na wyższy poziom?
          </animation.h4>
        </div>
        <div className={pageBar.right}>
          <Button
            className={`${button.button} ${button.appointment} `}
            icon={<CaretCircleRightIcon fill={globalColors.black1} />}
            onClick={() => window.open('https://ekstralekcja.pl', '_blank')}
          >
            <p>Umów się</p>
          </Button>
        </div>
      </header>
    </>
  );
};

export default SubHeader;
