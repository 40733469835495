import { FacebookShareButton } from 'react-share';
import s from './SingleWorkView.module.scss';
import globalColors from '../../../../styles/_colors.scss';
import Button from '../../../../components/Button/Button';
import { useGetWorks } from '../../../../api/work';
import { useEffect, useMemo, useState } from 'react';
import TextEditor from '../TextEditor/TextEditor';
import WorkGallery from '../WorkGallery/WorkGallery';
import Buttons from '../WorkGallery/components/Buttons/Buttons';
import AdditionalMaterials, {
  Style,
} from '../AdditionalMaterials/AdditionalMaterials';
import { formatDate } from '../../../../utils/datetime';
import { useVoting } from '../../../../utils/api/voting';
import { useAppSelector } from '../../../../redux/hooks';
import WithoutAccountPopup from '../../../../components/Popups/WithoutAccountPopup/WithoutAccountPopup';
import {
  ContestStatus,
  getContestForWork,
  getValidContests,
} from '../../../../utils/api/contests';
import { useGetContests } from '../../../../api/contest';
import button from '../../../../components/Button/Button.module.scss';
import { useGetTopic } from '../../../../api/topic';
import { useSchoolParams } from '../../../../utils/api/school';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAddShare } from '../../../../api/social-sharing';
import ContestInfo from '../ContestInfo/ContestInfo';
import { parseDateWithZone } from '../../../../utils/api/timezone';

interface Props {
  showAdditionalMaterials?: boolean;
  additionalWorksImg?: any;
  additionalWorksTitle?: string;
  additionalTasksImg?: any;
  additionalTasksTitle?: string;
  textEditorEnabled?: boolean;
  isElementary: boolean;
  contestWorks?: any;
}

interface WorkDetailsProps {
  cardData: any;
  topicData: any;
  textEditorEnabled?: boolean;
  isElementary: boolean;
}

const WorkDetails = ({
  cardData,
  topicData,
  textEditorEnabled = true,
  isElementary,
}: WorkDetailsProps) => {
  const loggedIn = useAppSelector((state) => state.user.loggedIn);
  const [popupVisibleNoAccount, setPopupVisibleNoAccount] = useState(false);
  const user = useAppSelector((state) => state.user);
  const [popups, onClickIncrement] = useVoting(
    cardData?.[0],
    loggedIn,
    setPopupVisibleNoAccount
  );
  const contests = useGetContests(cardData?.[0].school.schoolType.id);
  const validContest = useMemo(() => {
    if (contests?.data) {
      const workContest = getContestForWork(
        cardData?.[0],
        Object.values(contests.data)
      );
      if (workContest) {
        return getValidContests([workContest])?.[
          cardData?.[0].school.schoolType.id
        ];
      }
    }
  }, [contests, cardData]);
  const addShare = useAddShare();

  return (
    <>
      {popups}
      <WithoutAccountPopup
        otherIncentive='Dodawanie polubień jest zarezerowane dla zarejestrowanych użytkowników. Dzięki tej funkcji wspierasz swoje dziecko i inne w wygraniu konkursu. Nie zwlekaj z założeniem konta.'
        visible={popupVisibleNoAccount}
        setVisible={setPopupVisibleNoAccount}
      />
      <div className={s.workDetails}>
        <div>
          <span className='button-2'>Data dodania: </span>
          <span className='button-5'>
            {formatDate(cardData?.[0]?.createdAt)}
          </span>
        </div>
        <div className={s.titleAndContent}>
          <h4
            style={
              isElementary
                ? { color: globalColors.orange1 }
                : { color: globalColors.regular1Kinder }
            }
          >
            {cardData?.[0]?.author} {cardData?.[0]?.grade?.name}
          </h4>
        </div>
        <div className={s.titleAndContent}>
          <p className='body-1 add-bold'>Opis historii:</p>
          <ReactMarkdown
            className={s.workStory}
            rehypePlugins={[rehypeRaw]}
            children={topicData?.description}
          />
        </div>
        <div>
          <span className='body-2 add-bold'>Animator: </span>
          <span className='body-2'>{cardData?.[0]?.animator?.name}</span>
        </div>
        <div className={s.titleAndContent}>
          <p className='body-2 add-bold'>Kategoria edukacyjna:</p>
          <p className='body-3'>{topicData?.category?.name}</p>
        </div>
        <div>
          {textEditorEnabled && <TextEditor />}
          <div className={s.buttons}>
            <FacebookShareButton
              onClick={(e) => {
                e.stopPropagation();
                addShare.mutate({
                  type: 'facebook',
                  work: cardData?.[0].id,
                  school: cardData?.[0].school.id,
                  city: cardData?.[0].school.city.id,
                  voivodeship: cardData?.[0].school.voivodeship.id,
                  user:
                    loggedIn && user.userData ? user.userData.id : undefined,
                });
              }}
              className={`${button.button} ${button.share}`}
              url={process.env.REACT_APP_WEBSITE_URL + window.location.pathname}
              hashtag={'#nowoczesnaedukacja'}
            >
              <FontAwesomeIcon
                icon={faFacebook}
                style={{
                  width: '28px',
                  height: '28px',
                  color: '#0071e3',
                }}
              />
              <p className='button-3'>Udostępnij</p>
            </FacebookShareButton>
            {validContest &&
              (validContest.contestType === ContestStatus.Active ? (
                <Button onClick={onClickIncrement}>
                  <p
                    style={{ color: globalColors.orange1 }}
                    className='button-2 add-underline'
                  >
                    Zagłosuj na tę pracę
                  </p>
                </Button>
              ) : (
                <p style={{ color: globalColors.gray2 }} className='button-2'>
                  Głosowanie dostępne od{' '}
                  {formatDate(parseDateWithZone(validContest.mainRoundTo))}
                </p>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

const SingleWorkView = ({
  showAdditionalMaterials,
  additionalWorksImg,
  additionalWorksTitle,
  additionalTasksImg,
  additionalTasksTitle,
  textEditorEnabled,
  isElementary,
  contestWorks,
}: Props) => {
  const params = useSchoolParams();
  const [cardData, setCardData] = useState<any>(null);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);
  const works = useGetWorks({
    school: params.schoolId,
    grade: params.gradeId,
    workId: params.workId,
  });

  useEffect(() => {
    const { data } = works;
    if (data?.length >= 0) {
      setCardData(data);
    }
  }, [works]);

  const topicId = cardData?.[0]?.topic?.id;

  const { data: topicData } = useGetTopic({ topicId });

  const sectionToRender = useMemo(() => {
    if (selectedButtonIndex === 0) {
      return (
        cardData &&
        cardData.length > 0 && (
          <>
            <div className={s.contestSection}>
              <ContestInfo cardData={cardData} />
            </div>
            <div className={s.gallery}>
              <div>
                <WorkGallery cardData={cardData} contestWorks={contestWorks} />
                <Buttons
                  showTasks={additionalTasksImg !== undefined}
                  style={{ marginTop: '8rem' }}
                  callback={setSelectedButtonIndex}
                  selectedIndex={selectedButtonIndex}
                />
              </div>
              <WorkDetails
                isElementary={isElementary}
                cardData={cardData}
                topicData={topicData}
                textEditorEnabled={textEditorEnabled}
              />
            </div>
          </>
        )
      );
    } else if (selectedButtonIndex === 1) {
      return (
        additionalWorksImg && (
          <div className={s.materials}>
            <AdditionalMaterials
              style={Style.Normal}
              files={topicData?.workingMaterials}
              img={additionalWorksImg}
              title={additionalWorksTitle}
            />
            <Buttons
              showTasks={additionalTasksImg !== undefined}
              style={{ marginTop: '3rem' }}
              callback={setSelectedButtonIndex}
              selectedIndex={selectedButtonIndex}
            />
          </div>
        )
      );
    } else if (selectedButtonIndex === 2) {
      return (
        additionalTasksImg && (
          <div className={s.materials}>
            <AdditionalMaterials
              style={Style.Split}
              files={topicData?.homework}
              img={additionalTasksImg}
              title={additionalTasksTitle}
            />
            <Buttons
              showTasks={true}
              style={{ marginTop: '3rem' }}
              callback={setSelectedButtonIndex}
              selectedIndex={selectedButtonIndex}
            />
          </div>
        )
      );
    }
  }, [
    isElementary,
    selectedButtonIndex,
    additionalWorksImg,
    additionalWorksTitle,
    additionalTasksImg,
    additionalTasksTitle,
    cardData,
    topicData,
    textEditorEnabled,
    contestWorks,
  ]);

  return (
    <div id='single-work-view' className={s.singleWorkView}>
      {showAdditionalMaterials ? (
        <>{sectionToRender}</>
      ) : (
        <>
          <div className={s.contestSection}>
            <ContestInfo cardData={cardData} />
          </div>
          <div className={s.gallery}>
            <WorkGallery cardData={cardData} contestWorks={contestWorks} />
            {cardData?.length > 0 && (
              <WorkDetails
                isElementary={isElementary}
                cardData={cardData}
                topicData={topicData}
                textEditorEnabled={textEditorEnabled}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SingleWorkView;
