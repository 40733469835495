import grid from '../../../../components/Card/FlexCardAlt2.module.scss';
import { Card } from '../../../SchoolPages/components/ChildrenWorks/ChildrenWorks';
import { useGetInfiniteWorks } from '../../../../api/work';
import { useParams } from 'react-router-dom';
import { useGetContest } from '../../../../api/contest';
import WithoutAccountPopup from '../../../../components/Popups/WithoutAccountPopup/WithoutAccountPopup';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { scrollToId } from '../../../../utils/scroll';
import { useLocation } from '../../../../utils/location';
import { useSchoolParams } from '../../../../utils/api/school';
import { getWorksForContest } from '../../../../utils/api/contests';
import { useInView } from 'framer-motion';
import { setContestWorks } from '../../../../redux/slices/contestSlice';

const ContestWorks = () => {
  const dispatch = useAppDispatch();
  const contestWorks = useAppSelector((state) => state.contest.contestWorks);
  const refLoadMore = useRef(null);
  const shouldLoadMore = useInView(refLoadMore);
  const location = useLocation();
  const { hash } = location;
  const { schoolId, gradeId, workId } = useSchoolParams();
  const [popupVisibleNoAccount, setPopupVisibleNoAccount] = useState(false);
  const { contestId } = useParams();
  const { data: contest } = useGetContest(contestId ?? '');
  const { data: cardDataInfinite, fetchNextPage } = useGetInfiniteWorks({
    school: schoolId,
    grade: gradeId,
    timeframe: contest
      ? {
          startDate: contest.qualificationFrom,
          endDate: contest.mainRoundTo,
        }
      : null,
  });
  const cardData = useMemo(
    () =>
      cardDataInfinite?.pages
        ?.map((page) => page.data)
        .reduce((acc, curr) => [...acc, ...curr], []),
    [cardDataInfinite]
  );
  const loggedIn = useAppSelector((state) => state.user.loggedIn);

  useEffect(() => {
    if (cardData) {
      dispatch(
        setContestWorks(getWorksForContest(contest, cardData))
      );
    }
  }, [contest, cardData, dispatch]);

  useEffect(() => {
    if (contestWorks && hash === '#contest-works') {
      scrollToId('contest-works', 100, 'start');
    }
  }, [hash, contestWorks]);

  useEffect(() => {
    if (shouldLoadMore) {
      fetchNextPage().then(() => {});
    }
  }, [shouldLoadMore, fetchNextPage]);

  return contest && schoolId && gradeId && contestWorks ? (
    <>
      <WithoutAccountPopup
        otherIncentive='Dodawanie polubień jest zarezerowane dla zarejestrowanych użytkowników. Dzięki tej funkcji wspierasz swoje dziecko i inne w wygraniu konkursu. Nie zwlekaj z założeniem konta.'
        visible={popupVisibleNoAccount}
        setVisible={setPopupVisibleNoAccount}
      />
      <div id='contest-works' className={`${grid.allCards}`}>
        {contestWorks.map((card: any, index: number) => (
          <Card
            card={card}
            key={card.id}
            loggedIn={loggedIn}
            popupNotLoggedIn={setPopupVisibleNoAccount}
            imgStyle={{ width: '100%', height: '172px' }}
            onClick={() => {
              if (workId) {
                location.push(card.id);
              } else {
                location.push(window.location.pathname + '/' + card.id);
              }
              scrollToId('single-work-view', 500);
            }}
          />
        ))}
        <div ref={refLoadMore} />
      </div>
    </>
  ) : (
    <></>
  );
};

export default ContestWorks;
