import LoggingIn from './logging-in.webp';
import FailedLogin from './failed-login.webp';
import Switch from '../../../../components/Input/Switch/Switch';
import Input from '../../../../components/Input/Input';
import { CaretCircleRightIcon } from '../../../../components/Icons/icons';
import { Link, useNavigate } from 'react-router-dom';
import ButtonQuery from '../../../../query/ButtonQuery';
import { useCallback, useState } from 'react';
import {
  useValidableState,
  validateEmail,
  validatePassword,
} from '../../../../utils/validation';
import {
  useIsEmailTaken,
  useLogin,
  useResendEmailConfirmation,
} from '../../../../api/auth';
import { useQueryReset } from '../../../../utils/query';
import {
  setJWT,
  setLoggedIn,
  setRememberMe,
  setUserData,
} from '../../../../redux/slices/userSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Button from '../../../../components/Button/Button';
import button from '../../../../components/Button/Button.module.scss';
import s from './Login.module.scss';
import { ScaleX } from '../../../../components/Animations/PageTransitions';
import globalColors from '../../../../styles/_colors.scss';
import { withLoggedOut } from '../../../../utils/hoc/hoc';
import { useCookies } from 'react-cookie';
import WithoutAccountPopup from '../../../../components/Popups/WithoutAccountPopup/WithoutAccountPopup';
import ResendConfirmationPopup from '../../../../components/Popups/ResendConfirmationPopup/ResendConfirmationPopup';
import { motion } from 'framer-motion';

enum LoginFailReason {
  None,
  InvalidCredentials,
  EmailNotConfirmed,
}

enum InvaildInput {
  None,
  Email,
  Password,
}

const Login = () => {
  const dispatch = useAppDispatch();
  const setCookie = useCookies(['session'])[1];
  const rememberMe = useAppSelector((state) => state.user.rememberMe);

  const [email, setEmail, isEmailValid] = useValidableState<string>(
    '',
    validateEmail
  );
  const [password, setPassword, isPasswordValid] = useValidableState<string>(
    '',
    validatePassword
  );
  const [failedLogin, setFailedLogin] = useState(LoginFailReason.None);
  const [invalidInput, setInvalidInput] = useState(InvaildInput.None);
  const [popupVisible, setPopupVisible] = useState(false);
  const [resendPopupVisible, setResendPopupVisible] = useState(false);
  const navigate = useNavigate();
  const setQueryReset = useQueryReset(invalidInput !== InvaildInput.None);
  const login = useLogin();

  const isEmailTaken = useIsEmailTaken();
  const isFormValid =
    isEmailValid &&
    isPasswordValid &&
    !isEmailTaken.isLoading &&
    !isEmailTaken.isError;

  const handleError = useCallback(
    (axiosError: any, reset: () => void) => {
      const error: string = axiosError.response.data.error.message;

      setQueryReset(reset);

      if (error.toLowerCase().includes('invalid')) {
        isEmailTaken.mutate(
          { email },
          {
            onSuccess: (isTaken) => {
              if (isTaken) {
                setInvalidInput(InvaildInput.Password);
              } else {
                setInvalidInput(InvaildInput.Email);
              }
            },
            onError: (error) => {
              console.error(error);
            },
          }
        );

        setFailedLogin(LoginFailReason.InvalidCredentials);
      } else if (error.toLowerCase().includes('confirmed')) {
        setInvalidInput(InvaildInput.Email);
        setFailedLogin(LoginFailReason.EmailNotConfirmed);
      } else {
        console.error(`Unknown login error: ${error}`);
      }
    },
    // eslint-disable-next-line
    [email, setQueryReset]
  );

  const handleLogin = (axiosResponse: any) => {
    const data = axiosResponse.data;
    setCookie('session', true);
    dispatch(setJWT(data.jwt));
    dispatch(
      setUserData({
        email: data.user.email,
        firstName: data.user.firstname,
        lastName: data.user.lastname,
        favoriteSchools: data.user.favoriteSchools.map((e: any) => e.id),
        id: data.user.id,
      })
    );
    dispatch(setLoggedIn(true));
  };

  const getLoginMessage = (reason: LoginFailReason) => {
    switch (reason) {
      case LoginFailReason.InvalidCredentials:
        return 'Ups. Dane do logowania są niepoprawne. Spróbuj jeszcze raz.';

      case LoginFailReason.EmailNotConfirmed:
        return 'Ups. Potwierdź konto klikając w link przesłany do Ciebie podczas rejestracji.';

      default:
        return 'Zaloguj się i sprawdź co Twoje dziecko stworzyło na lekcjach Pana Tableta.';
    }
  };

  const resendEmailConfirmation = useResendEmailConfirmation();

  const send = () => {
    resendEmailConfirmation.mutate(
      {
        email: email,
      },
      {
        onSuccess: async () => {
          setResendPopupVisible(true);
        },
        onError: async (e: any) => {
          console.log(e);
        },
      }
    );
  };

  return (
    <>
      <WithoutAccountPopup
        visible={popupVisible}
        setVisible={setPopupVisible}
      />
      <ResendConfirmationPopup
        visible={resendPopupVisible}
        setVisible={setResendPopupVisible}
      />
      <div className={s.pageWrapper}>
        <motion.div
          initial='hidden'
          animate='visible'
          exit={{ opacity: 0, transition: { duration: 1 } }}
          className={s.container}
        >
          <img
            src={failedLogin !== LoginFailReason.None ? FailedLogin : LoggingIn}
            alt='logging-in'
          />
          <h2>{getLoginMessage(failedLogin)}</h2>
          <div className={s.form}>
            <Input
              keyfilter='email'
              errorMessage={
                invalidInput === InvaildInput.Email &&
                failedLogin !== LoginFailReason.EmailNotConfirmed
                  ? 'Podany email nie jest zarejestrowany'
                  : ''
              }
              value={email}
              setValue={(stateAction) => {
                if (invalidInput === InvaildInput.Email) {
                  setInvalidInput(InvaildInput.None);
                }

                setEmail(stateAction);
              }}
              placeholder='Adres email'
            />

            <Input
              value={password}
              errorMessage={
                invalidInput === InvaildInput.Password
                  ? 'Nieprawidłowe hasło'
                  : ''
              }
              setValue={(stateAction) => {
                if (invalidInput === InvaildInput.Password) {
                  setInvalidInput(InvaildInput.None);
                }

                setPassword(stateAction);
              }}
              placeholder='Hasło'
              password
            />

            <div className={s.formLine}>
              <Switch
                state={rememberMe}
                setState={() => dispatch(setRememberMe(!rememberMe))}
                label={<p className='button-3'>Zapamiętaj mnie</p>}
              />

              <Link to='/forgot-password' className='button-3 add-underline'>
                <span>Przypomnij hasło</span>
              </Link>
            </div>
            {failedLogin === LoginFailReason.EmailNotConfirmed && (
              <span
                className={`${s.resendEmail} add-underline`}
                onClick={() => send()}
              >
                E-mail nie dotarł? Kliknij TUTAJ aby wysłać ponownie.
              </span>
            )}
          </div>

          <div className={s.buttonsWrapper}>
            <div className={s.buttons}>
              <ButtonQuery
                style={{ width: '212px', height: '36px' }}
                disabled={!isFormValid || invalidInput !== InvaildInput.None}
                mutation={login}
                mutationParam={{ email, password }}
                onMutationError={handleError}
                className={`${button.button} ${button.login}`}
                icon={<CaretCircleRightIcon />}
                onMutationSuccess={handleLogin}
              >
                <p className='button-5'>Zaloguj się</p>
              </ButtonQuery>

              <p
                style={{ cursor: 'pointer' }}
                onClick={() => setPopupVisible(true)}
                className='field-1 add-underline'
              >
                Kontynuuj bez zalogowania
              </p>
            </div>
            {!failedLogin && (
              <div className={s.buttons}>
                <p className='button-3'>Nie posiadasz konta?</p>
                <Button
                  style={{ width: '212px', height: '36px' }}
                  onClick={() => navigate('/pre-register')}
                  className={`${button.button} ${button.registerOutline}`}
                >
                  <p className='button-5'>Zarejestruj się</p>
                </Button>
              </div>
            )}
          </div>
        </motion.div>
      </div>
      <ScaleX color={globalColors.blue4} />
    </>
  );
};

export default withLoggedOut(Login);
