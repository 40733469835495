import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

export const useGetGrades = (school: number, includeWork: boolean = true) => {
  return useQuery({
    queryKey: ['grades', school, includeWork],
    queryFn: () =>
      school !== undefined
        ? axios.get(`/grade/get-school-grades/${school}`).then((res) => {
            if (includeWork) {
              return res?.data;
            } else if (res?.data) {
              let result: any = {};

              Object.entries(res.data).forEach(
                ([gradeId, grade]: [string, any]) => {
                  result[gradeId] = grade.name;
                }
              );

              return result;
            }
          })
        : {},
  });
};
