import { IBasePopupProps, withPopup } from '../withPopup';
import { CaretCircleRightIcon } from '../../Icons/icons';
import Button from '../../Button/Button';
import Image from './favorites.webp';
import button from '../../Button/Button.module.scss';
import s from './CannotVotePopup.module.scss';
import { IContest } from '../../../api/contest';
import { formatDate } from '../../../utils/datetime';
import { CanVoteOnWork } from '../../../utils/api/contests';
import { parseDateWithZone } from '../../../utils/api/timezone';

interface IProps {
  contest: IContest | null;
  canVote: CanVoteOnWork;
}

const CannotVotePopup = ({
  canVote,
  close,
  contest,
}: IBasePopupProps & IProps) => {
  return contest ? (
    <div className={s.container}>
      <img width='248px' className={s.center} src={Image} alt='votes' />
      <h3 className='headline-3b'>Nie możesz zagłosować ponownie.</h3>
      <p className='body-3'>
        {canVote === CanVoteOnWork.Can &&
          `Głosowanie w konkursie dostępne od ${formatDate(
            parseDateWithZone(contest.qualificationFrom)
          )} do 
        ${formatDate(parseDateWithZone(contest.mainRoundTo))}`}
        {canVote === CanVoteOnWork.CantAlreadyVoted &&
          <span>Możesz oddać tylko 1 głos na daną pracę w danym konkursie.<br/>Zapraszamy do głosowania na inne prace.</span>}
        {canVote === CanVoteOnWork.CantAboveContestLimit &&
          'Możesz oddać tylko ' +
            contest.numberOfVotes +
            ' głosów w danym konkursie.'}
      </p>
      <Button
        onClick={() => {
          if (close) {
            close();
          }
        }}
        className={`${button.button} ${button.login} ${s.center} ${s.button}`}
        icon={<CaretCircleRightIcon />}
      >
        <p className='button-5'>OK</p>
      </Button>
    </div>
  ) : (
    <></>
  );
};

export default withPopup(CannotVotePopup);
