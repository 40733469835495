import { motion, Variants } from 'framer-motion';
import s from './CardTransitions.module.scss';

interface Props {
  className?: string;
  style?: any;
  children: JSX.Element[] | JSX.Element;
}

export const hCardVariants: Variants = {
  offscreen: (i: any) => ({
    x: i,
  }),
  onscreen: {
    x: 0,
    transition: {
      stiffness: 300,
      damping: 30,
      duration: 1,
      ease: 'easeInOut',
      times: 1,
    },
  },
};

export const revealVariants: Variants = {
  offscreen: {
    scale: 1.1,
  },
  onscreen: {
    scale: 1,
    transition: {
      duration: 1,
      ease: 'easeInOut',
      times: 1,
    },
  },
};

export const scrollDownVariants: Variants = {
  offscreen: {
    y: -100,
  },
  onscreen: {
    y: 20,
    transition: {
      type: 'spring',
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

export const RevealCard = ({ className, children, style }: Props) => {
  return (
    <motion.div
      style={style}
      className={`${s.revealCard} ${className}`}
      initial='offscreen'
      whileInView='onscreen'
      viewport={{ once: true, amount: 0.3 }}
    >
      {children}
    </motion.div>
  );
};

export const RevealHCard = ({ className, children, style }: Props) => {
  return (
    <motion.div
      style={style}
      className={`${s.revealHCard} ${className}`}
      initial='offscreen'
      whileInView='onscreen'
      viewport={{ once: true, amount: 0.3 }}
    >
      {children}
    </motion.div>
  );
};

export const ScrollDownCard = ({ className, children }: Props) => {
  return (
    <motion.div
      className={className}
      initial='offscreen'
      whileInView='onscreen'
      viewport={{ once: false, amount: 0.3 }}
    >
      {children}
    </motion.div>
  );
};
