import { motion } from 'framer-motion';
import { h1Variants } from '../../../../components/Animations/TextTransitions';
import Button from '../../../../components/Button/Button';
import s from './IntroMessageAlt.module.scss';
import button from '../../../../components/Button/Button.module.scss';
import { CaretCircleRightIcon } from '../../../../components/Icons/icons';
import globalColors from '../../../../styles/_colors.scss';

interface Props {
  image: any;
  imgStyle: React.CSSProperties;
  h1?: string;
}

const IntroMessageAlt = ({ image, imgStyle, h1 }: Props) => {
  const childrenWorksSection = document.querySelector(
    "[class*='categoryTitle']"
  );

  return (
    <motion.div
      className={s.introMessageAlt}
      initial='hidden'
      animate='visible'
      exit={{ opacity: 0, transition: { duration: 1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
    >
      <div className={s.texts}>
        <motion.h1 className='headline-1b' variants={h1Variants}>
          {h1
            ? h1
            : 'Galeria prac. Oglądaj, komentuj, udostępniaj i bądź dumny.'}
        </motion.h1>
        <Button
          onClick={() =>
            childrenWorksSection?.scrollIntoView({ behavior: 'smooth' }) ??
            undefined
          }
          className={`${button.button} ${button.browseSchoolGallery}`}
          icon={<CaretCircleRightIcon fill={globalColors.blue4} />}
        >
          <p className='button-2'>Przeglądaj galerię</p>
        </Button>
      </div>
      <img style={imgStyle} src={image} alt='brand hero Pana Tableta'></img>
    </motion.div>
  );
};

export default IntroMessageAlt;
