import {
  animate,
  motion,
  useMotionValue,
  useMotionValueEvent,
  useTransform,
} from 'framer-motion';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { h2Variants } from '../../../../components/Animations/TextTransitions';
import s from './DataBanner.module.scss';

interface Props {
  img: string;
  imgStyle: any;
  className: string;
  style?: React.CSSProperties;
  hStyle?: React.CSSProperties;
}

const DataBanner = ({ img, className, style, hStyle, imgStyle }: Props) => {
  const countStudents = useMotionValue(0);
  const countTeachers = useMotionValue(0);

  const roundedStudents = useTransform(countStudents, Math.round);
  const roundedTeachers = useTransform(countTeachers, Math.round);

  useEffect(() => {
    const animationStudents = animate(countStudents, 21430, { duration: 8 });
    return animationStudents.stop;
  }, [countStudents]);

  useEffect(() => {
    const animationTeachers = animate(countTeachers, 1208, { duration: 6 });
    return animationTeachers.stop;
  }, [countTeachers]);

  useMotionValueEvent(roundedStudents, 'change', (latest) => {
    setStudents(latest);
  });

  useMotionValueEvent(roundedTeachers, 'change', (latest) => {
    setTeachers(latest);
  });

  const [students, setStudents] = useState(0);

  const [teachers, setTeachers] = useState(0);

  const mobile = useMediaQuery('(max-width: 768px)');

  return (
    <div style={style} className={`${s.dataBanner} ${className}`}>
      <motion.h2
        style={hStyle}
        className='headline-2'
        initial='hidden'
        whileInView='visible'
        viewport={{ once: true, amount: 0.3 }}
        variants={h2Variants}
      >
        <strong>
          <span
            style={{
              display: 'inline-block',
              width: mobile ? '10rem' : '15rem',
            }}
          >
            {students}
          </span>
          uczniów oraz{' '}
          <span
            style={{
              display: 'inline-block',
              width: mobile ? '7.5rem' : '11.5rem',
            }}
          >
            {teachers}
          </span>{' '}
          nauczycieli korzysta z naszych zajęć
        </strong>
      </motion.h2>
      <img style={imgStyle} src={img} alt={'wybierz szkołę Pana Tableta'}></img>
    </div>
  );
};

export default DataBanner;
