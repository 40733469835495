import Button from '../../../../components/Button/Button';
import { CaretCircleRightIcon } from '../../../../components/Icons/icons';
import s from './Contest.module.scss';
import globalColors from '../../../../styles/_colors.scss';
import button from '../../../../components/Button/Button.module.scss';
import { useNavigate } from 'react-router-dom';

interface Props {
  media: any;
}

const Contest = ({ media }: Props) => {
  const navigate = useNavigate();
  return (
    <div className={s.contest}>
      <div className={s.media}>{media}</div>
      <div className={s.texts}>
        <h4>
          W Konkursie Kreatywnym liczy się każdy głos oddany na wybrane prace.
          Koniecznie się zaloguj i oddaj swoje głosy, by pomóc szkole wygrać!
        </h4>
      </div>
      <div className={s.contestIncentive} onClick={() => navigate('/contests')}>
        <h2>Konkurs Kreatywny</h2>
        <p className='body-2'>
          Zapraszamy do udziału w Konkursie Kreatywnym Pana Tableta, w którym
          mogą brać udział wszystkie szkoły i przedszkola, w których prowadzimy
          zajęcia multimedialne.
        </p>
        <Button
          onClick={() => navigate('/contests')}
          className={`${button.button} ${button.ranking}`}
          icon={<CaretCircleRightIcon fill={globalColors.white2} />}
        >
          <p className='button-2'>Zobacz aktualne konkursy</p>
        </Button>
      </div>
    </div>
  );
};

export default Contest;
