import s from './FinishedContestsList.module.scss';
import { IContest, SchoolType, useGetContests } from '../../../../api/contest';
import { Fragment, useMemo } from 'react';
import {
  getFinishedContests,
  getSchoolsRanking,
} from '../../../../utils/api/contests';
import { formatDate } from '../../../../utils/datetime';
import { IPrize } from '../../../../api/prize';
import { motion } from 'framer-motion';
import {
  h1VariantsH,
  h3Variants,
  h4Variants,
} from '../../../../components/Animations/TextTransitions';
import { useGetRanking } from '../../../../api/ranking';
import { parseDateWithZone } from '../../../../utils/api/timezone';
import { useMediaQuery } from 'usehooks-ts';

interface IProps {
  image: string;
  schoolType: SchoolType;
}

const FinishedContestsList = ({ image, schoolType }: IProps) => {
  const contests = useGetContests();
  const finishedContests = useMemo(
    () => getFinishedContests(Object.values(contests.data), schoolType),
    [contests.data, schoolType]
  );
  const { data: rankingApi } = useGetRanking({});

  const mobile = useMediaQuery('(max-width: 768px)');

  return (
    <div className={s.contests}>
      {finishedContests.map((contest, index) => {
        const ranking: any = getSchoolsRanking(
          rankingApi.filter(
            (rankingItem: any) => contest.id === rankingItem.contest.id
          )
        );

        return (
          <div key={index} className={s.contest}>
            {mobile ? (
              <>
                <div className={s.details}>
                  <div className={s.dates}>
                    <p className='button-5'>
                      Rozpoczęcie głosowania:{' '}
                      <b>
                        {formatDate(
                          parseDateWithZone(contest.qualificationFrom)
                        )}
                      </b>
                    </p>

                    <p className='button-5'>
                      Zakończenie głosowania:{' '}
                      <b>
                        {formatDate(parseDateWithZone(contest.mainRoundTo))}
                      </b>
                    </p>
                  </div>
                  <motion.h3
                    initial='hidden'
                    whileInView='visible'
                    viewport={{ once: true, amount: 0.3 }}
                    variants={h3Variants}
                    className='headline-3b'
                  >
                    {contest.name}
                  </motion.h3>
                  <p className='body-2'>{contest.description}</p>
                  <div className={s.standings}>
                    <div className={s.first}>
                      <motion.img
                        src={image}
                        alt='first-place'
                        initial={{ x: -100 }}
                        whileInView={{ x: 0 }}
                        viewport={{ once: true, amount: 0.3 }}
                        transition={{
                          duration: 2,
                        }}
                      />
                      <motion.h4
                        initial='hidden'
                        animate='visible'
                        variants={h4Variants}
                        className='headline-4'
                      >
                        {ranking[0] && (
                          <>
                            {ranking[0].school.name}
                            <br />
                            Nagroda: <b>{contest.place1Prize?.name}</b>
                          </>
                        )}
                      </motion.h4>
                    </div>
                    {[2, 3].map((place, key) => {
                      const prize = contest[
                        `place${place}Prize` as keyof IContest
                      ] as IPrize;
                      return (
                        <Fragment key={place}>
                          {ranking[place - 1] && (
                            <motion.div
                              initial='hidden'
                              whileInView='visible'
                              viewport={{ once: false, amount: 0.3 }}
                              variants={h1VariantsH}
                              custom={key + 1}
                            >
                              <h3 className='headline-3b'>{place}</h3>
                              <h4>
                                {ranking[place - 1].school.name}
                                {prize ? (
                                  <>
                                    {' '}
                                    - <b>{prize.name}</b>
                                  </>
                                ) : (
                                  ''
                                )}
                              </h4>
                            </motion.div>
                          )}
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={s.first}>
                  <motion.img
                    src={image}
                    alt='first-place'
                    initial={{ x: -100 }}
                    whileInView={{ x: 0 }}
                    viewport={{ once: true, amount: 0.3 }}
                    transition={{
                      duration: 2,
                    }}
                  />
                  <motion.h4
                    initial='hidden'
                    animate='visible'
                    variants={h4Variants}
                    className='headline-4'
                  >
                    {ranking[0] && (
                      <>
                        {ranking[0].school.name}
                        <br />
                        Nagroda: <b>{contest.place1Prize?.name}</b>
                      </>
                    )}
                  </motion.h4>
                </div>
                <div className={s.details}>
                  <div className={s.dates}>
                    <p className='button-5'>
                      Rozpoczęcie głosowania:{' '}
                      <b>
                        {formatDate(
                          parseDateWithZone(contest.qualificationFrom)
                        )}
                      </b>
                    </p>

                    <p className='button-5'>
                      Zakończenie głosowania:{' '}
                      <b>
                        {formatDate(parseDateWithZone(contest.mainRoundTo))}
                      </b>
                    </p>
                  </div>
                  <motion.h3
                    initial='hidden'
                    whileInView='visible'
                    viewport={{ once: true, amount: 0.3 }}
                    variants={h3Variants}
                    className='headline-3b'
                  >
                    {contest.name}
                  </motion.h3>
                  <p className='body-2'>{contest.description}</p>
                  <div className={s.standings}>
                    {[2, 3].map((place, key) => {
                      const prize = contest[
                        `place${place}Prize` as keyof IContest
                      ] as IPrize;
                      return (
                        <Fragment key={place}>
                          {ranking[place - 1] && (
                            <motion.div
                              initial='hidden'
                              whileInView='visible'
                              viewport={{ once: false, amount: 0.3 }}
                              variants={h1VariantsH}
                              custom={key + 1}
                            >
                              <h3 className='headline-3b'>{place}</h3>
                              <h4>
                                {ranking[place - 1].school.name}
                                {prize ? (
                                  <>
                                    {' '}
                                    - <b>{prize.name}</b>
                                  </>
                                ) : (
                                  ''
                                )}
                              </h4>
                            </motion.div>
                          )}
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default FinishedContestsList;
