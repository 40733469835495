import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetWorksForFavorites } from '../../../api/work';
import s from './FavoriteSchools.module.scss';
import Lottie from 'react-lottie-player';
import school from './components/lottie-school.json';
import { useAppSelector } from '../../../redux/hooks';
import { animation } from '../../../utils/animation';

const Item = (props: any) => {
  const [lottieInView, setLottieInView] = useState(false);
  return (
    <>
      <div
        onMouseOver={() => setLottieInView(true)}
        onMouseLeave={() => setLottieInView(false)}
        className={s.item}
        onClick={props.onClick}
      >
        <li>{props.title}</li>
        <Lottie
          play={lottieInView}
          animationData={school}
          style={{ width: 34, height: 34 }}
        />
        <div>{props.children}</div>
      </div>
    </>
  );
};

const Dropdown = (props: any) => {
  return (
    <div className={s.dropdown}>
      <animation.p className={`${s.dropdownTitle} add-underline`}>
        {props.title}
      </animation.p>
      {props?.children?.length > 0 && (
        <div className={s.dropdownChildren}>{props.children}</div>
      )}
    </div>
  );
};

const SubMenu = (props: any) => {
  return <div className={s.subMenu}>{props.children}</div>;
};

const FavoriteSchools = () => {
  const [favSchools, setFavSchools] = useState<any>([]);
  const works = useGetWorksForFavorites({ school: favSchools });
  const [schools, setSchools] = useState<any>(null);
  const userData = useAppSelector((state) => state.user.userData);
  const navigate = useNavigate();
  const { data } = works;

  useEffect(() => {
    setFavSchools(userData?.favoriteSchools);
  }, [userData]);

  useEffect(() => {
    let uniqueRecords: any = [];
    if (data?.length >= 0) {
      data.forEach((elem: any) => {
        const index = uniqueRecords.findIndex(
          (ur: any) => ur.id === elem.school.id
        );
        if (!uniqueRecords.find((ur: any) => ur.id === elem.school.id)) {
          uniqueRecords.push({
            ...elem.school,
            grade: [elem.grade],
          });
        } else if (
          uniqueRecords.find((ur: any) => ur.id === elem.school.id) &&
          !uniqueRecords[index].grade.find((ur: any) => ur.id === elem.grade.id)
        ) {
          uniqueRecords[index].grade = [
            ...uniqueRecords[index].grade,
            elem.grade,
          ];
        }
      });
      setSchools(uniqueRecords);
    }
  }, [data]);

  return (
    <div className={s.favoriteSchools}>
      <Dropdown title='Twoje szkoły'>
        {schools?.map((school: any, index: number) => {
          const schoolType =
            school.schoolType.id === 1 ? '/kindergarten/' : '/elementary/';
          return (
            <React.Fragment key={index}>
              <Item
                title={school.name}
                onClick={() => navigate(schoolType + school.id)}
              >
                <SubMenu>
                  {school?.grade
                    ?.sort((a: any, b: any) => a.name.localeCompare(b.name))
                    .map((grade: any, gradeIndex: number) => {
                      return (
                        <Item
                          key={gradeIndex}
                          title={grade.name}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            navigate(schoolType + school.id + '/' + grade.id);
                          }}
                        ></Item>
                      );
                    })}
                </SubMenu>
              </Item>
            </React.Fragment>
          );
        })}
      </Dropdown>
    </div>
  );
};

export default FavoriteSchools;
