import { useGetCities } from '../../../../../api/city';
import { useGetSchoolsByCity } from '../../../../../api/school';
import { useGetVoivodeships } from '../../../../../api/voivodeship';
import DropdownQuery from '../../../../../query/Input/DropdownQuery';
import { ISchoolSetupData } from '../Register';
import s from '../Register.module.scss';

interface Props {
  schoolSetup: ISchoolSetupData;
  index: number;
}

const StepFour = ({ schoolSetup, index }: Props) => {
  const voivodeships = useGetVoivodeships([1, 2]);
  const cities = useGetCities(schoolSetup.voivodeship, [1, 2]);
  const schools = useGetSchoolsByCity(schoolSetup.city, [1, 2]);
  return (
    <div key={index} className={s.formGrid}>
      <h4 style={{ gridColumn: 'span 2' }}>Wybrana szkoła {index + 1}</h4>

      <DropdownQuery
        disabled
        inputClassName='body-3'
        query={voivodeships}
        value={schoolSetup.voivodeship}
        placeholder='Wybierz województwo'
      />

      <DropdownQuery
        disabled
        inputClassName='body-3'
        query={cities}
        value={schoolSetup.city}
        placeholder='Wybierz miasto'
      />

      <DropdownQuery
        disabled
        inputClassName='body-3'
        query={schools}
        value={schoolSetup.school}
        placeholder='Wybierz szkołę'
      />
    </div>
  );
};

export default StepFour;
