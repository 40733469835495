import BreadCrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import SearchBar from '../../../components/SearchBar/SearchBar';
import SocialMedia from '../../../components/SocialMedia/SocialMedia';
import SubHeader from '../../../components/SubHeader/SubHeader';
import Banner from '../components/Banner/Banner';
import IntroMessage from '../components/IntroMessage/IntroMessage';
import s from '../SchoolPages.module.scss';
import BannerImage from './components/choose-elementary.webp';
import Filters from '../components/Filters/Filters';
import DataBanner from '../components/DataBanner/DataBanner';
import BannerImg from './components/data-banner-elementary.webp';
import Cards from '../components/Cards/Cards';
import cardRegular from './components/elementary-book.webp';
import cardWinner from './components/1stPlaceElementary.webp';
import globalColors from '../../../styles/_colors.scss';
import { useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { useMediaQuery } from 'usehooks-ts';
import globals from '../../../App.scss';

const ElementaryPage = () => {
  const [res, setRes] = useState(null);
  const schoolData = (filterResult: any) => {
    setRes(filterResult);
  };
  const user = useAppSelector((state) => state.user);
  const mobile = useMediaQuery('(max-width: 768px)');

  return (
    <>
      <SubHeader color={globalColors.green1} />
      <BreadCrumbs />
      <Banner img={BannerImage} headline='Wybierz szkołę' />
      <div className={s.messageAndSearchbar}>
        <IntroMessage />
        <SearchBar
          style={{
            width: mobile ? globals.mobileWidth : '30%',
            minWidth: '270px',
          }}
        />
      </div>
      <DataBanner
        img={BannerImg}
        imgStyle={{
          position: mobile ? 'unset' : 'absolute',
          width: mobile ? '100%' : '55%',
          height: mobile ? 'unset' : '353px',
          bottom: '-11%',
          right: '0',
        }}
        hStyle={{
          position: mobile ? 'unset' : 'relative',
          top: '3rem',
          left: '5rem',
        }}
        className='gradient-elementary-school-1'
      />
      {/* schooltype 2 is elementary school in backend */}
      <Filters
        callback={schoolData}
        schoolType={2}
        shortView={true}
        schoolFilterTitle='Szkoły'
      />
      <Cards
        user={user}
        cardData={res}
        cardWinner={cardWinner}
        cardRegular={cardRegular}
        cardWinnerImgStyle={{
          width: mobile ? '100%' : '183px',
          height: '200px',
        }}
        cardAvatarImgStyle={{ width: '87px', height: '116px' }}
        color={globalColors.blue4}
        actionButtonText={'Wybierz szkołę'}
        additionalText='Liczba klas:'
        loggedIn={!!user.userData}
        showResultsSummary={true}
        showResultsText='Liczba szkół biorących udział w lekcjach Pana Tableta:'
      />
      <SocialMedia />
    </>
  );
};

export default ElementaryPage;
