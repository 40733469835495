import globalColors from '../../styles/_colors.scss';
import SubHeader from '../../components/SubHeader/SubHeader';
import Header from './components/Header/Header';
import SocialMedia from '../../components/SocialMedia/SocialMedia';
import ContestsCreated, {
  Theme,
} from './components/ContestsCreated/ContestsCreated';
import ContestsCreatedImage from './components/ContestsCreated/kindergarten.webp';
import FinishedContests from './components/FinishedContests/FinishedContests';
import FinishedContestsImage from './components/FinishedContests/kindergarten.webp';
import ContestRulesImage from './components/ContestRules/kindergarten.webp';
import ContestRules from './components/ContestRules/ContestRules';
import CurrentRankingImage from './components/CurrentRanking/kindergarten.webp';
import CurrentRanking from './components/CurrentRanking/CurrentRanking';
import { PopupTheme } from '../../components/Popups/RankingPopup/RankingPopup';
import ChooseClass from './components/ChooseClass/ChooseClass';
import ContestWorks from './components/ContestWorks/ContestWorks';
import SingleWorkView from '../SchoolPages/components/SingleWorkView/SingleWorkView';
import { motion } from 'framer-motion';
import { useSchoolParams } from '../../utils/api/school';
import { useGetTop50Works } from '../../api/work';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';

const KindergartenContestPage = () => {
  const { workId } = useSchoolParams();
  const contestWorks = useAppSelector((state) => state.contest.contestWorks);

  const { data } = useGetTop50Works();
  const [images, setImages] = useState([]);

  useEffect(() => {
    const urls = data?.map((elem: any) => {
      return process.env.REACT_APP_HOST_URL + elem?.media?.[0]?.url;
    });

    setImages(urls);
  }, [data]);

  return (
    <motion.div
      initial='hidden'
      animate='visible'
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <SubHeader color={globalColors.regular1Kinder} />
      <Header images={images} />
      <ContestsCreated
        theme={Theme.Kindergarten}
        image={ContestsCreatedImage}
      />
      <CurrentRanking theme={PopupTheme.Green} image={CurrentRankingImage} />
      <ChooseClass />
      <ContestWorks />
      {workId && (
        <SingleWorkView
          contestWorks={contestWorks}
          isElementary={false}
          textEditorEnabled={false}
        />
      )}
      <ContestRules image={ContestRulesImage} />
      <FinishedContests image={FinishedContestsImage} />
      <SocialMedia />
    </motion.div>
  );
};

export default KindergartenContestPage;
