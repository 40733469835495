import Logo from './logo.svg';
import Point1 from './point-1.webp';
import Point2 from './point-2.webp';
import { CaretCircleRightIcon } from '../../../../components/Icons/icons';
import button from '../../../../components/Button/Button.module.scss';
import s from './PreLogin.module.scss';
import card from '../../../../components/Card/FlexCard.module.scss';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { withLoggedOut } from '../../../../utils/hoc/hoc';
import { ScaleX } from '../../../../components/Animations/PageTransitions';
import globalColors from '../../../../styles/_colors.scss';
import Button from '../../../../components/Button/Button';
import {
  h1Variants,
  h4Variants,
} from '../../../../components/Animations/TextTransitions';
import {
  RevealCard,
  revealVariants,
} from '../../../../components/Animations/CardTransitions';

const PreLogin = () => {
  const navigate = useNavigate();
  return (
    <>
      <motion.div
        initial='hidden'
        animate='visible'
        exit={{ opacity: 0, transition: { duration: 1 } }}
      >
        <div className={s.header}>
          <img src={Logo} alt='logo' />
          <motion.h1 initial='hidden' animate='visible' variants={h1Variants}>
            Logowanie
          </motion.h1>
          <Button
            style={{ width: '139px', height: '40px' }}
            className={`${button.button} ${button.login}`}
            icon={<CaretCircleRightIcon />}
            onClick={() => navigate('/login')}
          >
            <p className='button-4'>Zaloguj się</p>
          </Button>
          <motion.h4
            style={{ color: globalColors.gray7 }}
            initial='hidden'
            animate='visible'
            variants={h4Variants}
          >
            Odkrywaj talenty swojego dziecka z Panem Tabletem.<br></br>Zaloguj
            się, by otrzymać wszystkie materiały!
          </motion.h4>
        </div>
        <div className={s.wrapper}>
          <div className={card.allCards}>
            <RevealCard>
              <motion.div variants={revealVariants} className={card.singleCard}>
                <div className={s.cardWrapper}>
                  <div className={s.cardUpper}>
                    <img src={Point1} alt='point-1' />
                  </div>
                  <div className={s.cardBottom}>
                    <h2>Co dają lekcje Pana Tableta?</h2>
                    <p className='body-2'>
                      Na zajęciach dzieci uczą się korzystać z nowych
                      technologii i tworzą swoje multimedialne prace. Zajęcia
                      rozbudzają zainteresowania i aktywność twórczą, a prace
                      dzieci zbierane i prezentowane są na tej platformie!
                    </p>
                  </div>
                </div>
              </motion.div>
            </RevealCard>
            <RevealCard>
              <motion.div variants={revealVariants} className={card.singleCard}>
                <div className={s.cardWrapper}>
                  <div className={s.cardUpper}>
                    <img src={Point2} alt='point-2' />
                  </div>
                  <div className={s.cardBottom}>
                    <h2>Jakie korzyści daje logowanie?</h2>
                    <p className='body-2'>Tylko zalogowany użytkownik:</p>
                    <p className='body-2'>
                      - ma możliwość oddania głosu w konkursie;
                    </p>
                    <p className='body-2'>
                      - może korzystać z wszystkich dostępnych materiałów na
                      stronie;
                    </p>
                    <p className='body-2'>
                      - może polubić, udostępnić i skomentować pracę dziecka.
                    </p>
                  </div>
                </div>
              </motion.div>
            </RevealCard>
          </div>
        </div>
      </motion.div>
      <ScaleX color={globalColors.blue4} />
    </>
  );
};

export default withLoggedOut(PreLogin);
