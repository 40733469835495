import { useQuery } from '@tanstack/react-query';
import { filterAndMap, useGetCalendarBase } from '../utils/airtable';
import { formatDate, getWorkDatesBetween } from '../utils/datetime';
import { DateTime } from 'luxon';

export interface Animator {
  airtableId: string;
  id: number;
  columnId: number;
  firstName: string;
  lastName: string;
}

export const useGetAnimators = (groupSize: number = 6) => {
  const base = useGetCalendarBase();

  const fieldMapping = {
    'Record ID Animatora': 'airtableId',
    'Numer porządkowy': 'id',
    Imię: 'firstName',
    Nazwisko: 'lastName',
  };

  return useQuery({
    queryKey: ['airtable/animators'],
    queryFn: async () => {
      const results: Array<{ [index: string]: Animator }> = [];
      const records = await base('Pracownicy')
        .select({
          fields: Object.keys(fieldMapping),
          filterByFormula: `AND(
            {Status}="Aktywny",
            FIND("edukator", LOWER({Stanowisko}))!=0
          )`,
        })
        .all();

      const animators = filterAndMap<Animator>(records, fieldMapping);
      animators.sort((a, b) => (a.id > b.id ? 1 : -1));

      animators.forEach((animator, index) => {
        const groupIndex = Math.floor(index / groupSize);
        const animatorIndex = index % groupSize;

        if (!results[groupIndex]) {
          results[groupIndex] = {};
        }

        results[groupIndex][animator.airtableId] = {
          ...animator,
          columnId: animatorIndex,
        };
      });

      return results;
    },
  });
};

interface Lesson {
  airtableId: string;
  animator: Array<string>;
  date: string;
  startTime: string;
  endTime: string;
  teacher: Array<string>;
  school: Array<string>;
  dayIndex: number;
  grade: Array<string>;
}

export const useGetLessons = (range: [Date, Date]) => {
  const base = useGetCalendarBase();
  const startDate = formatDate(range[0]);
  const endDate = formatDate(
    DateTime.fromJSDate(range[1]).plus({ day: 1 }).toJSDate()
  );
  const dateFormat = 'DD.MM.YYYY';

  const fieldMapping = {
    'Identyfikator (ID) zajęć': 'airtableId',
    Animator: 'animator',
    'Data zajęcia': 'date',
    'Godz. rozpoczęcia zajęć': 'startTime',
    'Godz. zakończenia zajęć': 'endTime',
    'Imię i nazwisko Nauczyciela': 'teacher',
    Placówka: 'school',
    'Klasa i oddział': 'grade',
  };

  const optionalFields = ['Imię i nazwisko Nauczyciela', 'Placówka', 'Klasa i oddział'];

  return useQuery({
    queryKey: ['airtable/lessons', startDate, endDate],
    queryFn: async () => {
      const records = await base('03 Plan Zajęć')
        .select({
          fields: Object.keys(fieldMapping),
          filterByFormula: `AND(
            {Termin Zajęć} >= DATETIME_PARSE("${startDate}", "${dateFormat}"),
            {Termin Zajęć} <= DATETIME_PARSE("${endDate}", "${dateFormat}")
          )`,
        })
        .all();

      return filterAndMap<Lesson>(records, fieldMapping, optionalFields)
        .filter((lesson) => {
          const lessonDate = new Date(lesson.date);

          // not on a weekend
          return lessonDate.getDay() !== 0 && lessonDate.getDay() !== 6;
        })
        .map((lesson) => {
          const lessonDate = new Date(lesson.date);
          const rangeStart = range[0];

          return {
            ...lesson,
            dayIndex: getWorkDatesBetween([rangeStart, lessonDate]).length - 1,
          };
        });
    },
  });
};

export interface School {
  airtableId: string;
  address: string;
  name: string;
}

export const useGetSchools = (schoolIds: Array<string>) => {
  const base = useGetCalendarBase();

  const fieldMapping = {
    'Pełny adres': 'address',
    'Pełna nazwa placówki': 'name',
    id: 'airtableId',
  };

  return useQuery({
    queryKey: ['airtable/schools', schoolIds],
    queryFn: async () => {
      const results: { [index: string]: School } = {};
      const records = await base('Placówki')
        .select({
          fields: Object.keys(fieldMapping),
          filterByFormula: `SEARCH(RECORD_ID(), "${schoolIds.join(
            ','
          )}") != ""`,
        })
        .all();

      const schools = filterAndMap<School>(records, fieldMapping);

      schools.forEach((school) => {
        results[school.airtableId] = {
          ...school,
        };
      });

      return results;
    },
  });
};

export interface Teacher {
  airtableId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export const useGetTeachers = (teacherIds: Array<string>) => {
  const base = useGetCalendarBase();

  const fieldMapping = {
    'Airtable ID - Osoby': 'airtableId',
    Imię: 'firstName',
    Nazwisko: 'lastName',
    Email: 'email',
    Telefon: 'phoneNumber',
  };

  const optionalFields = ['Email', 'Telefon'];

  return useQuery({
    queryKey: ['airtable/teachers', teacherIds],
    queryFn: async () => {
      const results: { [index: string]: Teacher } = {};
      const records = await base('Osoby')
        .select({
          fields: Object.keys(fieldMapping),
          filterByFormula: `SEARCH(RECORD_ID(), "${teacherIds.join(
            ','
          )}") != ""`,
        })
        .all();

      const teachers = filterAndMap<Teacher>(
        records,
        fieldMapping,
        optionalFields
      );

      teachers.forEach((teacher) => {
        results[teacher.airtableId] = {
          ...teacher,
        };
      });

      return results;
    },
  });
};

export interface Grade {
  airtableId: string;
  gradeIdTeacher: string;
}

export const useGetGrades = (gradeIds: Array<string>) => {
  const base = useGetCalendarBase();

  const fieldMapping = {
    'Airtable ID - Klasy': 'airtableId',
    'ID Klasy': 'gradeIdTeacher',
  };

  return useQuery({
    queryKey: ['airtable/grades', gradeIds],
    queryFn: async () => {
      const results: { [index: string]: Grade } = {};
      const records = await base('Klasy')
        .select({
          fields: Object.keys(fieldMapping),
          filterByFormula: `SEARCH(RECORD_ID(), "${gradeIds.join(',')}") != ""`,
        })
        .all();

      const grades = filterAndMap<Grade>(records, fieldMapping);

      grades.forEach((grade) => {
        results[grade.airtableId] = {
          ...grade,
        };
      });

      return results;
    },
  });
};
