import { useRef } from 'react';
import { Cycle, motion } from 'framer-motion';
import { useDimensions } from './use-dimensions';
import { MenuToggle } from './MenuToggle/MenuToggle';
import { Navigation } from './Navigation/Navigation';
import h from './Hamburger.module.scss';
import { Divider } from '../Divider/Divider';
import {
  FacebookIcon,
  // InstagramIcon
  YoutubeIcon,
} from '../Icons/icons';
import pageBar from '../PageBar/PageBar.module.scss';
import globals from '../../App.scss';
import SearchBar from '../SearchBar/SearchBar';
import n from './Navigation/Navigation.module.scss';

interface Props {
  toggleOpen: Cycle;
  isOpen: boolean;
}

const visibilityVariants = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
  },
};

const container = {
  open: {
    width: '100%',
  },
  closed: {
    width: '0',
    transition: {
      when: 'afterChildren',
    },
  },
};

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: 'circle(0.001px at 40px 40px)',
    transition: {
      delay: 0.5,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
};

const Footer = () => {
  return (
    <footer
      style={{
        position: 'absolute',
        padding: '1.5em 0',
        bottom: '0',
        width: '100%',
      }}
      className={pageBar.bar}
    >
      <div style={{ margin: '0' }} className={pageBar.right}>
        <div className={n.mobileSocialMedia}>
          {/* <InstagramIcon /> */}
          <YoutubeIcon
            onClick={() =>
              window.open(
                'https://www.youtube.com/@pantablet4748/videos',
                '_blank'
              )
            }
          />
          <FacebookIcon
            onClick={() =>
              window.open('https://pl-pl.facebook.com/PanTablet/', '_blank')
            }
          />
        </div>
      </div>
    </footer>
  );
};

export const Hamburger = ({ toggleOpen, isOpen }: Props) => {
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  return (
    <motion.nav
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      custom={height}
      ref={containerRef}
      className={h.nav}
      variants={container}
    >
      <motion.div className={h.background} variants={sidebar} />
      <motion.div
        style={{ position: 'relative', zIndex: 1 }}
        variants={visibilityVariants}
      >
        <SearchBar
          className={n.searchBar}
          style={{ width: globals.mobileWidth }}
        />
        <Divider />
      </motion.div>
      <Navigation />
      <motion.div variants={visibilityVariants}>
        <Footer />
      </motion.div>
      <MenuToggle toggle={() => toggleOpen()} />
    </motion.nav>
  );
};
