import { useValidableState, validateEmail } from '../../../../utils/validation';
import Input, { IInputTheme } from '../../../../components/Input/Input';
import ForgotPasswordImage from './forgot-password.webp';
import WrongEmailImage from './wrong-email.webp';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useForgotPassword, useIsEmailTaken } from '../../../../api/auth';
import Button from '../../../../components/Button/Button';
import { NextIcon, PencilIcon } from '../../../../components/Icons/icons';
import ForgotPasswordSuccessPopup from '../../../../components/Popups/ForgotPasswordSuccessPopup/ForgotPasswordSuccessPopup';
import ForgotPasswordPopup from '../../../../components/Popups/ForgotPasswordPopup/ForgotPasswordPopup';
import { ErrorTheme } from '../../../../utils/hoc/hoc';
import s from './ForgotPassword.module.scss';
import button from '../../../../components/Button/Button.module.scss';
import { motion } from 'framer-motion';
import {
  h1Variants,
  h2Variants,
} from '../../../../components/Animations/TextTransitions';

const ForgotPassword = () => {
  const [email, setEmail, isEmailValid] = useValidableState<string>(
    '',
    validateEmail
  );
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupSuccessVisible, setPopupSuccessVisible] = useState(false);
  const [wrongEmail, setWrongEmail] = useState(false);
  const navigate = useNavigate();
  const forgotPassword = useForgotPassword();
  const isEmailTaken = useIsEmailTaken();

  return (
    <>
      <ForgotPasswordSuccessPopup
        visible={popupSuccessVisible}
        setVisible={setPopupSuccessVisible}
      />
      <ForgotPasswordPopup
        disabled={isEmailTaken.isLoading || isEmailTaken.isError}
        visible={popupVisible}
        setVisible={setPopupVisible}
        accept={() =>
          isEmailTaken.mutate(
            { email },
            {
              onSuccess: (isTaken) => {
                setPopupVisible(false);

                if (isTaken) {
                  forgotPassword.mutate(
                    { email },
                    { onSuccess: () => setPopupSuccessVisible(true) }
                  );
                } else {
                  setWrongEmail(true);
                }
              },
              onError: (error) => {
                setPopupVisible(false);
                console.error(error);
              },
            }
          )
        }
      />
      <motion.div
        initial='hidden'
        animate='visible'
        exit={{ opacity: 0, transition: { duration: 1 } }}
        className={s.container}
      >
        <div className={`${s.card} ${s.wideCard}`}>
          <motion.h1 variants={h1Variants} className='headline-1a'>
            {wrongEmail ? 'Błędnie podano login' : 'Przypomnij swoje hasło'}
          </motion.h1>
          <img
            className={s.image}
            src={wrongEmail ? WrongEmailImage : ForgotPasswordImage}
            alt='forgot-password'
          />
        </div>

        <div
          className={`${s.card} ${s.regularCard} ${wrongEmail ? s.error : ''}`}
        >
          <p className='body-3'>
            Wpisz swój adres mailowy podany podczas rejestracji. Na podany adres
            wyślemy link aktywacyjny, umożliwiający ustanowienie nowego hasła.
          </p>

          <Input
            theme={IInputTheme.Transparent}
            icon={
              isEmailValid && !wrongEmail ? (
                <NextIcon onClick={() => setPopupVisible(true)} />
              ) : wrongEmail ? (
                <PencilIcon />
              ) : undefined
            }
            className={s.input}
            value={email}
            setValue={(stateAction) => {
              setEmail(stateAction);
              setWrongEmail(false);
            }}
            errorMessage={
              wrongEmail
                ? 'Podany adres e-mail nie istnieje w bazie. Sprawdź ponownie i popraw dane.'
                : ''
            }
            errorTheme={ErrorTheme.White}
            floatingPlaceholder='E-mail'
            placeholder='Wpisz adres email'
          />
        </div>

        <div className={`${s.card} ${s.regularCard}`}>
          <motion.h2
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true, amount: 0.3 }}
            variants={h2Variants}
          >
            Nie posiadasz konta?
          </motion.h2>
          <Button
            style={{ width: '169px', height: '40px' }}
            onClick={() => navigate('/register')}
            className={`${button.button} ${button.forgotPasswordOutline}`}
          >
            <p className='button-4'>Zarejestruj się</p>
          </Button>
        </div>
      </motion.div>
    </>
  );
};

export default ForgotPassword;
