import SubHeader from '../../../../../components/SubHeader/SubHeader';
import globalColors from '../../../../../styles/_colors.scss';
import s from '../../../SchoolPages.module.scss';
import BreadCrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs';
import IntroMessage from '../../../components/IntroMessageAlt/IntroMessageAlt';
import BrandHero from './components/brand-hero-elementary.webp';
import Contest from '../../../components/Contest/Contest';
import Media from './components/chart-placeholder.webp';
import { Divider } from '../../../../../components/Divider/Divider';
import ChildrenWorks from '../../../components/ChildrenWorks/ChildrenWorks';
import { useEffect, useState } from 'react';
import { useGetWorks } from '../../../../../api/work';
import { useAppSelector } from '../../../../../redux/hooks';
import SocialMedia from '../../../../../components/SocialMedia/SocialMedia';
import { useSchoolParams } from '../../../../../utils/api/school';
import { useMediaQuery } from 'usehooks-ts';

const GradePage = () => {
  const params = useSchoolParams();
  const works = useGetWorks({
    school: params.schoolId,
    grade: params.gradeId,
  });
  const [resWorks, setResWorks] = useState<any>(null);
  useEffect(() => {
    const { data } = works;
    if (data?.length >= 0) {
      setResWorks(data);
    }
  }, [works]);
  const user = useAppSelector((state) => state.user);
  const loggedIn = !!user.userData;
  const mobile = useMediaQuery('(max-width: 768px)');

  return (
    <>
      <SubHeader color={globalColors.green1} />
      <BreadCrumbs
        grades={resWorks?.[0]?.school?.name}
        grade={resWorks?.[0]?.grade?.name}
      />
      <IntroMessage
        image={BrandHero}
        imgStyle={{
          width: mobile ? '100%' : '47%',
          height: mobile ? '250px' : 'auto',
        }}
      />
      <Contest media={<img src={Media} alt='Konkursy z Panem Tablet'></img>} />
      <div className={s.divider}>
        <Divider height={'2px'} backgroundColor={'#89939E'} />
      </div>
      <ChildrenWorks
        params={{
          school: params.schoolId,
          grade: params.gradeId,
        }}
        imgStyle={{ width: '100%', height: '172px' }}
        loggedIn={loggedIn}
      />
      <SocialMedia />
    </>
  );
};

export default GradePage;
