export const mapArrayToObject = (
  array: Array<any>,
  objectKey: (obj: any) => any,
  objectFn: (obj: any) => any
) =>
  array.reduce((acc, curr) => {
    let exists = Object.values(acc).includes(objectFn(curr));
    if (!exists) {
      const key = objectKey(curr);
      acc[key] = objectFn(curr);
    }
    return acc;
  }, {});

export const randomPickOne = <T,>(array: Array<T>): T =>
  array[Math.floor(Math.random() * array.length)];

export const makeRepeated = <T,>(arr: Array<T>, repeats: number): Array<T> =>
  Array<T>().concat(...Array.from({ length: repeats }, () => arr));
