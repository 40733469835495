import { ComponentType, Dispatch, SetStateAction } from 'react';
import s from './Popup.module.scss';

export interface IBasePopupProps {
  close?: () => void;
  visible: boolean;
}

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

export const withPopup =
  <T extends IBasePopupProps>(Component: ComponentType<T>) =>
  (props: T & IProps) => {
    return props.visible ? (
      <div className={s.wrapper}>
        <div className={s.popup}>
          <Component {...props} close={() => props.setVisible(false)} />
        </div>
      </div>
    ) : (
      <></>
    );
  };
