interface Props {
  backgroundColor?: string;
  height?: string;
  width?: string;
}

export const Divider = ({
  backgroundColor = '#000000',
  height = '1px',
  width = '100%',
}: Props) => {
  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: backgroundColor,
        height: height,
        width: width,
      }}
    ></div>
  );
};
