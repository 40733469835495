import button from '../../../../components/Button/Button.module.scss';
import Button from '../../../../components/Button/Button';
import s from './Navigation.module.scss';
import { CalendarView } from '../../const';
import { useCallback, useMemo } from 'react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from '../../../../components/Icons/icons';
import {
  getDateRangeSubranges,
  getDateWorkWeek,
  getMonthLocaleString,
} from '../../../../utils/datetime';
import { DateTime } from 'luxon';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  setCurrentDate,
  setSelectedView,
} from '../../../../redux/slices/calendarSlice';

const Navigation = () => {
  const selectedView = useAppSelector((state) => state.calendar.selectedView);
  const currentDate = useAppSelector(
    (state) => new Date(state.calendar.currentDate)
  );
  const dispatch = useAppDispatch();
  const weekRange = useMemo(() => getDateWorkWeek(currentDate), [currentDate]);
  const weekSubranges = useMemo(
    () => getDateRangeSubranges(weekRange),
    [weekRange]
  );

  const dateText = useMemo(() => {
    switch (selectedView) {
      case CalendarView.Month:
        return `${getMonthLocaleString(
          currentDate
        )}, ${currentDate.getFullYear()}`;

      case CalendarView.Week:
        return `${weekSubranges[1].join('-')} ${weekSubranges[0].join(
          '-'
        )}, ${weekSubranges[2].join('-')}`;
    }
  }, [weekSubranges, currentDate, selectedView]);

  const changeDate = useCallback(
    (forwards: boolean) => {
      const luxonDate = DateTime.fromJSDate(currentDate);
      let newDate: Date;

      switch (selectedView) {
        case CalendarView.Month:
          newDate = forwards
            ? luxonDate.plus({ months: 1 }).toJSDate()
            : luxonDate.minus({ months: 1 }).toJSDate();
          break;

        case CalendarView.Week:
          newDate = forwards
            ? luxonDate.plus({ weeks: 1 }).toJSDate()
            : luxonDate.minus({ weeks: 1 }).toJSDate();
          break;
      }

      dispatch(setCurrentDate(newDate));
    },
    [currentDate, selectedView, dispatch]
  );

  return (
    <div className={s.container}>
      <div />

      <div className={s.views}>
        <Button
          className={`${button.button} ${
            selectedView === CalendarView.Week
              ? button.calendarActive
              : button.calendar
          }`}
          onClick={() => dispatch(setSelectedView(CalendarView.Week))}
        >
          <strong className='button-4'>Tygodniowy</strong>
        </Button>

        <Button
          className={`${button.button} ${
            selectedView === CalendarView.Month
              ? button.calendarActive
              : button.calendar
          }`}
          onClick={() => dispatch(setSelectedView(CalendarView.Month))}
        >
          <strong className='button-4'>Miesięczny</strong>
        </Button>
      </div>

      <div className={s.date}>
        <div className={s.navigationButton} onClick={() => changeDate(false)}>
          <ChevronLeftIcon />
        </div>

        <p className='button-2'>{dateText}</p>

        <div className={s.navigationButton} onClick={() => changeDate(true)}>
          <ChevronRightIcon />
        </div>
      </div>
    </div>
  );
};

export default Navigation;
