import { useEffect, useState } from 'react';

export const useQueryReset = (triggerCondition: boolean) => {
  const [queryReset, setQueryReset] = useState<() => void>();

  useEffect(() => {
    if (triggerCondition && queryReset) {
      queryReset();
    }
  }, [triggerCondition, queryReset]);

  return setQueryReset;
};
