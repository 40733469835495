export const rotateAndScaleVariants = {
  visible: {
    scale: [1.3, 1],
    rotate: [180, 0],

    borderRadius: ['100%', '0%'],
    transition: {
      duration: 2,
      ease: 'easeInOut',
    },
  },
};
