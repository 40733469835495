import s from './AdditionalMaterials.module.scss';
import { useMemo } from 'react';

export enum Style {
  Normal,
  Split,
}

interface Props {
  img: any;
  title: string | undefined;
  files: Array<any> | null;
  style: Style;
}

const AdditionalMaterials = ({ img, title, files, style }: Props) => {
  const onClick = (file: any) => {
    window.open(
      process.env.REACT_APP_HOST_URL + file.url,
      '_blank',
      'noreferrer'
    );
  };

  const filesJsx = useMemo(
    () =>
      files ? (
        <div className={s.files}>
          {files.map((file) => (
            <div
              className={`${s.file} ${
                style === Style.Split ? `${s.link} ${s.card}` : ''
              }`}
              onClick={style === Style.Split ? () => onClick(file) : undefined}
              key={file.id}
            >
              <p className='add-bold body-2'>
                Pobierz: {file.name.slice(0, -4)}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <></>
      ),
    [style, files]
  );

  return (
    <>
      <div
        onClick={style === Style.Normal ? () => onClick(files?.[0]) : undefined}
        className={`${s.additionalMaterials} ${
          style === Style.Normal ? s.link : ''
        }`}
      >
        <h3 className='headline-3b'>{title}</h3>
        <img alt={'Dodatkowe materiały do zajęć Pana Tableta'} src={img} />

        {style === Style.Normal && filesJsx}
      </div>
      {style === Style.Split && filesJsx}
    </>
  );
};

export default AdditionalMaterials;
