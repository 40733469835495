import { motion } from 'framer-motion';
import school1 from './school1.webp';
import school2 from './school2.webp';
import school3 from './school3.webp';
import school4 from './school4.webp';
import school5 from './school5.webp';
import s from './index.module.scss';

const SchoolElements = () => {
  return (
    <div className={s.wrapper}>
      <motion.img
        transition={{
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 2,
          delay: 3,
        }}
        animate={{
          y: 10,
          rotateZ: 10,
          scale: 1.3,
        }}
        src={school1}
        alt='pudełko z gitarą'
      ></motion.img>
      <motion.img
        transition={{
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 1,
          delay: 5,
        }}
        animate={{
          x: 20,
          z: 30,
          rotateZ: 15,
          scale: 1,
        }}
        src={school2}
        alt='plecak'
      ></motion.img>
      <motion.img
        transition={{
          repeat: Infinity,
          duration: 1.5,
          delay: 4,
        }}
        animate={{
          rotateY: 360,
          scale: 1,
        }}
        src={school3}
        alt='zarówka'
      ></motion.img>
      <motion.img
        transition={{
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 1.5,
          delay: 4,
        }}
        animate={{
          x: 15,
          scale: 1,
        }}
        src={school4}
        alt='akcesoria do pisania'
      ></motion.img>
      <motion.img
        transition={{
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 1,
          delay: 2,
        }}
        animate={{
          rotateZ: 15,
          scale: 1,
        }}
        src={school5}
        alt='tablet'
      ></motion.img>
    </div>
  );
};

export default SchoolElements;
