import { useAxiosAuthConfig } from '../utils/axios';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

interface IAddShare {
  type: string;
  work: number;
  school: number;
  city: number;
  voivodeship: number;
  user?: number;
}

export const useAddShare = () => {
  const config = useAxiosAuthConfig();

  return useMutation({
    mutationFn: (shareData: IAddShare) =>
      axios.post(
        '/social-sharings',
        {
          data: {
            type: shareData.type,
            work: shareData.work,
            school: shareData.school,
            city: shareData.city,
            voivodeship: shareData.voivodeship,
            user: shareData.user,
          },
        },
        config
      ),
  });
};
