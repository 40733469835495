import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { mapArrayToObject } from '../utils/array';

export const useGetSchoolById = (school: string | undefined) => {
  const qs = require('qs');
  const query = qs.stringify(
    {
      filters: {
        id: {
          $eq: school,
        },
      },
      populate: { city: true, voivodeship: true },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return useQuery({
    queryKey: ['schools', school],
    queryFn: () =>
      axios.get(`/schools?${query}`).then((res) => {
        return res.data?.data;
      }),
  });
};

export const useGetSchoolsByCity = (
  city: string,
  schoolType: number | Array<number>
) => {
  const qs = require('qs');
  const query = qs.stringify(
    {
      filters: {
        city: {
          id: {
            $eq: city,
          },
        },
        schoolType: {
          id: {
            $eq: schoolType,
          },
        },
        works: {
          id: {
            $null: false,
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return useQuery({
    queryKey: ['schools', city, schoolType],
    queryFn: () =>
      city.length > 0
        ? axios.get(`/schools?${query}`).then((res) => {
            return mapArrayToObject(
              res.data?.data,
              (obj: any) => obj.id,
              (obj: any) => obj.name
            );
          })
        : {},
  });
};

export const useGetSchools = (
  voivodeship: string,
  city: string,
  school: string,
  schoolType: number
) => {
  const qs = require('qs');
  const query = qs.stringify(
    {
      sort: ['participatingGrades:desc', 'name:desc'],
      filters: {
        works: {
          id: {
            $null: false,
          },
        },
        voivodeship: {
          id: {
            $eq: voivodeship,
          },
        },
        city: {
          id: {
            $eq: city,
          },
        },
        id:
          school !== ''
            ? {
                $eq: school,
              }
            : {},
        schoolType: {
          id: {
            $eq: schoolType,
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return useQuery({
    queryKey: ['schools', voivodeship, city, school, schoolType],
    queryFn: () =>
      city.length > 0
        ? axios.get(`/schools?${query}`).then((res) => {
            return res.data?.data;
          })
        : [],
  });
};

export const useGetSchoolsForSearchbar = (school: string) => {
  const qs = require('qs');
  const query = qs.stringify(
    {
      filters: school
        ? {
            name: {
              $containsi: school,
            },
          }
        : {},
      populate: {
        schoolType: true,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return useQuery({
    queryKey: ['schoolsForSearchbar', school],
    queryFn: () =>
      school.length > 0
        ? axios.get(`/schools?${query}`).then((res) => res.data?.data)
        : [],
    placeholderData: [],
  });
};
