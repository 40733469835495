import BreadCrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import SearchBar from '../../../components/SearchBar/SearchBar';
import SocialMedia from '../../../components/SocialMedia/SocialMedia';
import SubHeader from '../../../components/SubHeader/SubHeader';
import Banner from '../components/Banner/Banner';
import BannerImage from './components/choose-kindergarten.webp';
import DataBanner from '../components/DataBanner/DataBanner';
import Filters from '../components/Filters/Filters';
import IntroMessage from '../components/IntroMessage/IntroMessage';
import s from '../SchoolPages.module.scss';
import BannerImg from './components/data-banner-kindergarten.webp';
import Cards from '../components/Cards/Cards';
import cardRegular from './components/kindergarten-book.webp';
import cardWinner from './components/1stPlaceKindergarten.webp';
import globalColors from '../../../styles/_colors.scss';
import { useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { useMediaQuery } from 'usehooks-ts';
import globals from '../../../App.scss';

const KindergartenPage = () => {
  const [res, setRes] = useState(null);
  const schoolData = (props: any) => {
    setRes(props);
  };
  const user = useAppSelector((state) => state.user);
  const mobile = useMediaQuery('(max-width: 768px)');

  return (
    <>
      <SubHeader color={globalColors.blue4} />
      <BreadCrumbs />
      <Banner img={BannerImage} headline='Wybierz przedszkole' />
      <div className={s.messageAndSearchbar}>
        <IntroMessage />
        <SearchBar
          style={{
            width: mobile ? globals.mobileWidth : '30%',
            minWidth: '270px',
          }}
        />
      </div>
      <DataBanner
        img={BannerImg}
        imgStyle={{
          position: mobile ? 'unset' : 'absolute',
          width: mobile ? '100%' : '45%',
          height: mobile ? 'unset' : '434px',
          right: '7%',
          bottom: '-30%',
        }}
        hStyle={{
          position: mobile ? 'unset' : 'relative',
          top: '3rem',
          left: '5rem',
        }}
        className='gradient-kindergarten-school-1'
      />
      {/* schooltype 1 is kindergarten school in backend */}
      <Filters
        callback={schoolData}
        schoolType={1}
        shortView={true}
        schoolFilterTitle='Przedszkola'
      />
      <Cards
        user={user}
        cardData={res}
        cardWinner={cardWinner}
        cardRegular={cardRegular}
        cardWinnerImgStyle={{
          width: mobile ? '100%' : '370px',
          height: '238px',
        }}
        cardAvatarImgStyle={{ width: '106px', height: '138px' }}
        color={globalColors.regular1Kinder}
        actionButtonText='Wybierz przedszkole'
        additionalText='Liczba grup:'
        loggedIn={!!user.userData}
        showResultsSummary={true}
        showResultsText='Liczba przedszkoli biorących udział w lekcjach Pana Tableta:'
      />
      <SocialMedia />
    </>
  );
};

export default KindergartenPage;
