import { motion } from 'framer-motion';
import { useState } from 'react';
import { h1Variants } from '../../../../components/Animations/TextTransitions';
import s from './Banner.module.scss';

interface Props {
  img: string;
  headline: string;
}
const hiddenMask = `repeating-linear-gradient(to top, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 100px, rgba(0,0,0,1) 100px, rgba(0,0,0,1) 100px)`;
const visibleMask = `repeating-linear-gradient(to top, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,1) 0px, rgba(0,0,0,1) 100px)`;

const Banner = ({ img, headline }: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  return (
    <motion.div
      className={s.banner}
      initial={false}
      animate={
        isLoaded && isInView
          ? { WebkitMaskImage: visibleMask, maskImage: visibleMask }
          : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
      }
      transition={{ duration: 1, delay: 0 }}
      viewport={{ once: true }}
      onViewportEnter={() => setIsInView(true)}
    >
      <img
        src={img}
        alt={'wybierz szkołę Pana Tableta'}
        onLoad={() => setIsLoaded(true)}
      ></img>
      <motion.h1 className='headline-1a' variants={h1Variants}>
        {headline}
      </motion.h1>
    </motion.div>
  );
};

export default Banner;
