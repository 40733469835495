import { useRef } from 'react';

export const useScrollbarWidth = () => {
  const didCompute = useRef(false);
  const widthRef = useRef(0);

  if (didCompute.current) return widthRef.current;

  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  document.body.appendChild(outer);

  const inner = document.createElement('div');
  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  outer.parentNode?.removeChild(outer);

  didCompute.current = true;
  widthRef.current = scrollbarWidth;

  return scrollbarWidth;
};

export const useScrollbarWidthCSS = () => {
  document.documentElement.style.setProperty(
    '--scrollbar-width',
    useScrollbarWidth() + 'px'
  );
};

export const scrollToId = (
  elementId: string,
  delay: number = 100,
  block: ScrollLogicalPosition = 'center'
) =>
  setTimeout(() => {
    const element = document.getElementById(elementId);

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block,
      });
    }
  }, delay);
