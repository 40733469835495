import { motion } from 'framer-motion';
import { Dispatch, ReactElement, SetStateAction, useCallback } from 'react';
import s from './Switch.module.scss';

interface IProps {
  label: ReactElement;
  state: boolean;
  setState?: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
}

const spring = {
  type: 'spring',
  stiffness: 700,
  damping: 30,
};

const Switch = ({ label, state, setState, disabled }: IProps) => {
  const toggle = useCallback(() => {
    if (!disabled && setState) {
      setState(!state);
    }
  }, [disabled, state, setState]);

  return (
    <div
      className={`${s.wrapper} ${disabled ? s.disabled : ''}`}
      onClick={() => toggle()}
    >
      <div className={`${s.container} ${state ? s.on : s.off}`}>
        <motion.div
          className={`${s.circle} ${state ? s.on : s.off}`}
          layout
          transition={spring}
        />
      </div>

      {label}
    </div>
  );
};

export default Switch;
