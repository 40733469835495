import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Divider } from '../../Divider/Divider';
import s from './MenuItem.module.scss';

const variants = {
  open: {
    y: 0,
    opacity: 1,
    display: 'block',
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transitionEnd: { display: 'none' },
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

interface Props {
  img: string;
  desc: string;
  url: string;
  fn?: () => void;
}

export const MenuItem = ({ img, desc, url, fn }: Props) => {
  const navigate = useNavigate();
  const hamburger = document.querySelector("[class*='hamburgerButton']") as any;
  return (
    <motion.div variants={variants}>
      {img && (
        <>
          <div
            onClick={() => {
              hamburger?.click();
              fn ? fn() : navigate(url);
            }}
            className={s.wrapper}
          >
            <img src={img} alt={desc} />
            <p className='body-3'>{desc}</p>
          </div>
          <Divider width='95%' />
        </>
      )}
    </motion.div>
  );
};
