export const visible = { opacity: 1, y: 0, transition: { duration: 1 } };

export const revealAndScaleVariants = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: {
    x: [50, 150, 0],
    opacity: 1,
    scale: 1,
    transition: { duration: 1.5, delay: 0.1, ease: [0.5, 0.7, 0.9, 1.5] },
  },
};

export const revealButtonVariants = {
  hidden: { opacity: 0 },
  visible: (i: any) => ({
    opacity: 1,
    transition: { delay: i ?? 0 },
  }),
};

export const h1Variants = {
  hidden: { opacity: 0, y: -40 },
  visible: (i: any) => ({
    opacity: 1,
    y: 0,
    transition: { delay: i ?? 0 },
  }),
};

export const h1VariantsH = {
  hidden: { opacity: 0, x: -80 },
  visible: (i: any) => ({
    opacity: 1,
    x: 0,
    transition: { delay: i ?? 0 },
  }),
};

export const h2Variants = {
  hidden: { opacity: 0, y: -40 },
  visible: (i: any) => ({
    opacity: 1,
    y: 0,
    transition: { delay: i ?? 0 },
  }),
};

export const h3Variants = {
  hidden: { opacity: 0, y: -20 },
  visible: (i: any) => ({
    opacity: 1,
    y: 0,
    transition: { delay: i ?? 0 },
  }),
};

export const h4Variants = {
  hidden: { opacity: 0, y: 10 },
  visible: (i: any) => ({
    opacity: 1,
    y: 0,
    transition: { delay: i ?? 0 },
  }),
};

export const pVariants = {
  hidden: { x: 60 },
  visible: (i: any) => ({
    x: 0,
    transition: {
      stiffness: 300,
      damping: 30,
      duration: 2,
      ease: 'easeInOut',
      times: 1,
      delay: i ?? 0,
    },
  }),
};
