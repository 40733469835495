import { motion, useIsPresent } from 'framer-motion';
import ps from '../../components/PrivacyScreen/PrivacyScreen.module.scss';

interface Background {
  color: string;
}

export const ScaleX = ({ color }: Background) => {
  const isPresent = useIsPresent();
  return (
    <motion.div
      initial={{ scaleX: 1 }}
      animate={{ scaleX: 0, transition: { duration: 1, ease: 'circOut' } }}
      exit={{ scaleX: 1, transition: { duration: 1, ease: 'circIn' } }}
      style={{
        originX: isPresent ? 0 : 1,
        backgroundColor: color,
      }}
      className={ps.privacyScreen}
    />
  );
};

export const ScaleY = ({ color }: Background) => {
  const isPresent = useIsPresent();
  return (
    <motion.div
      initial={{ scaleY: 1 }}
      animate={{ scaleY: 0, transition: { duration: 1, ease: 'circOut' } }}
      exit={{ scaleX: 1, transition: { duration: 1, ease: 'circIn' } }}
      style={{
        originY: isPresent ? 0 : 1,
        backgroundColor: color,
      }}
      className={ps.privacyScreen}
    />
  );
};
