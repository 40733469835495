import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';

const ScrollToTop = (props: any) => {
  const location = useLocation();
  const [lastLocation, setLastLocation] = useState(location);
  const blacklist = useMemo(
    () => ['/elementary', '/kindergarten', '/contests'],
    []
  );
  const blacklist1 = useMemo(() => ['/elementary', '/kindergarten'], []);
  useEffect(() => {
    window.history.scrollRestoration = 'manual';

    const locationParts = location.pathname.split('/').length;
    const lastLocationParts = lastLocation.pathname.split('/').length;
    const blacklistMatching =
      blacklist.filter((el) => location.pathname.startsWith(el)).length !== 0;
    const blacklist1Matching =
      blacklist1.filter((el) => location.pathname.startsWith(el)).length !== 0;

    if (
      !blacklistMatching ||
      (blacklist1Matching && locationParts !== lastLocationParts) ||
      (location.pathname.startsWith('/contests') && locationParts < 6)
    ) {
      window.scroll(0, 0);
    }

    setLastLocation(location);
    // eslint-disable-next-line
  }, [location]);

  return <>{props.children}</>;
};
export default ScrollToTop;
