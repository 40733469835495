import Dropdown, {
  IProps as IDropdownProps,
  Options,
} from '../../components/Input/Dropdown/Dropdown';
import { UseQueryResult } from '@tanstack/react-query';
import { useEffect } from 'react';

interface IProps {
  query: UseQueryResult;
  onQueryError?: (error: any) => void;
}

const DropdownQuery = (props: IDropdownProps & IProps) => {
  const { onQueryError } = props;
  const { isLoading, isError, error, data } = props.query;

  useEffect(() => {
    if (isError && onQueryError) {
      onQueryError(error);
    }
  }, [isError, error, onQueryError]);

  return (
    <Dropdown
      {...props}
      options={data as Options}
      disabled={isLoading ? true : props.disabled}
      placeholder={isLoading ? 'Ładowanie...' : props.placeholder}
    />
  );
};

export default DropdownQuery;
