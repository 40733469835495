import * as React from 'react';
import { Button as PrimeButton, ButtonProps } from 'primereact/button';
import { IconType } from 'primereact/utils';

export interface IProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    'disabled' | 'ref'
  > {
  className?: string;
  disabled?: boolean;
  icon?: IconType<ButtonProps>;
  children?: React.ReactNode;
  style?: any;
}

const Button = ({
  className,
  disabled,
  icon,
  onClick,
  children,
  style,
}: IProps) => {
  return (
    <PrimeButton
      style={style}
      disabled={disabled}
      className={className}
      icon={icon}
      onClick={onClick}
    >
      {children}
    </PrimeButton>
  );
};

export default Button;
