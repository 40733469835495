import Image from './favorites.webp';
import { IBasePopupProps, withPopup } from '../withPopup';
import s from './NoFavoritesAddedPopup.module.scss';
import button from '../../Button/Button.module.scss';
import { CaretCircleRightIcon } from '../../Icons/icons';
import Button from '../../Button/Button';

interface IProps {
  otherIncentive?: string;
}

const NoFavoritesAddedPopup = ({
  close,
  otherIncentive,
}: IBasePopupProps & IProps) => {
  let standardIncentive =
    'Nie dodałeś żadnej szkoły do ulubionych. Poszukaj szkoły swojego dziecka i kliknij serduszko na karcie. Od tej pory zawsze będziesz miał dostęp "na skróty".';
  return (
    <div className={s.container}>
      <img width='294px' src={Image} alt='without-account' />
      <h3 className='headline-3b'>Brak ulubionych szkół</h3>
      <p className='body-3'>
        {otherIncentive ? otherIncentive : standardIncentive}
      </p>

      <div className={s.buttons}>
        <Button
          onClick={() => {
            if (close) {
              close();
            }
          }}
          className={`${button.button} ${button.login}`}
          icon={<CaretCircleRightIcon />}
        >
          <p className='button-5'>OK, dziękuję</p>
        </Button>
      </div>
    </div>
  );
};

export default withPopup(NoFavoritesAddedPopup);
