import { ComponentType, useEffect } from 'react';
import { AlertTriangleIcon } from '../../components/Icons/icons';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import s from './hoc.module.scss';

export enum ErrorTheme {
  Red,
  White,
}

interface IBase {
  error?: boolean;
}

interface IMessageProps {
  className?: string;
  errorMessage?: string;
  message?: string;
  errorTheme?: ErrorTheme;
}

export const withMessage =
  <T extends IBase>(Component: ComponentType<T>) =>
  (props: T & IMessageProps) => {
    const getErrorThemeClassName = (errorTheme: ErrorTheme) => {
      switch (errorTheme) {
        case ErrorTheme.Red:
          return s.themeRed;

        case ErrorTheme.White:
          return s.themeWhite;

        default:
          return '';
      }
    };

    return (
      <div className={`${s.container} ${props.className}`}>
        <Component {...props} error={props.errorMessage ? true : props.error} />

        {(props.errorMessage || props.message) && (
          <div
            className={`${s.message} ${
              props.errorMessage ? s.error : ''
            } ${getErrorThemeClassName(
              props.errorTheme ? props.errorTheme : ErrorTheme.Red
            )}`}
          >
            {props.errorMessage && <AlertTriangleIcon />}

            <p className='field-2'>
              {props.errorMessage ? props.errorMessage : props.message}
            </p>
          </div>
        )}
      </div>
    );
  };

export const withLoggedOut =
  <T extends {}>(Component: ComponentType<T>, redirect: string = '/') =>
  (props: T) => {
    const navigate = useNavigate();
    const loggedIn = useAppSelector((state) => state.user.loggedIn);

    useEffect(() => {
      if (loggedIn) {
        navigate(redirect);
      }
    }, [loggedIn, navigate]);

    return loggedIn ? <></> : <Component {...props} />;
  };
