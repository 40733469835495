import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface IContest {
  contestWorks: any;
}

const initialState: IContest = {
  contestWorks: [],
};

export const contestSlice = createSlice({
  name: 'contest',
  initialState,
  reducers: {
    setContestWorks: (state, action: PayloadAction<any>) => {
      state.contestWorks = action.payload;
    },
  },
});

export const { setContestWorks } = contestSlice.actions;

export default contestSlice.reducer;
