import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { mapArrayToObject } from '../utils/array';
import { IApiObject } from '../utils/api/api';
import { IPrize } from './prize';

export enum SchoolType {
  Kindergarten = 1,
  PrimarySchool,
}

interface ISchoolType extends IApiObject {
  name: string;
}

export interface IContest extends IApiObject {
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  votingDuration: number;
  schoolType: ISchoolType;
  place1Prize: IPrize;
  place2Prize: IPrize;
  place3Prize: IPrize;
  place1Quantity: number;
  place2Quantity: number;
  place3Quantity: number;
  worksFrom: string;
  worksTo: string;
  qualificationFrom: string;
  qualificationTo: string;
  mainRoundFrom: string;
  mainRoundTo: string;
  numberOfVotes: number;
  multiplier: number;
  todaysDate: string;
  activeContest: boolean;
}

export const useGetContests = (schoolIndex?: any) => {
  const qs = require('qs');
  const query = qs.stringify(
    {
      filters: {
        schoolType: {
          id: {
            $eq: schoolIndex ?? [1, 2],
          },
        },
      },
      populate: '*',
    },
    {
      encodeValuesOnly: true,
    }
  );

  return useQuery({
    queryKey: ['contests', schoolIndex],
    queryFn: () =>
      axios.get(`/contests?${query}`).then((res) => {
        return mapArrayToObject(
          res.data?.data,
          (obj: any) => obj.id,
          (obj: any) => obj
        );
      }),
    placeholderData: {},
  });
};

export const useGetContest = (contestId: string) => {
  return useQuery({
    queryKey: ['contest', contestId],
    queryFn: () =>
      contestId.length > 0
        ? axios
            .get(`/contests/${contestId}?populate=*`)
            .then((res) => res.data?.data)
        : null,
  });
};
