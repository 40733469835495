import { ReactElement } from 'react';
import { ReactComponent as Facebook } from './facebook.svg';
import { ReactComponent as Instagram } from './instagram.svg';
import { ReactComponent as Login } from './login.svg';
import { ReactComponent as Youtube } from './youtube.svg';
import { ReactComponent as CaretCircleRight } from './caret-circle-right.svg';
import { ReactComponent as Eye } from './eye.svg';
import { ReactComponent as Register } from './register.svg';
import { ReactComponent as AlertTriangle } from './alert-triangle.svg';
import { ReactComponent as Done } from './done.svg';
import { ReactComponent as ArrowDown } from './arrow-down.svg';
import { ReactComponent as RevealCard } from './open-card-details-icon.svg';
import { ReactComponent as RevealReverseCard } from './close-card-details-icon.svg';
import { ReactComponent as RevealCardPrimarySchool } from './podstawowa-icon.svg';
import { ReactComponent as RevealCardKindergarten } from './przedszkole-icon.svg';
import { ReactComponent as Magnifier } from './magnifier.svg';
import { ReactComponent as SearchTrigger } from './search-trigger.svg';
import { ReactComponent as Checkmark } from './checkmark.svg';
import { ReactComponent as Logout } from './logout.svg';
import { ReactComponent as Next } from './next.svg';
import { ReactComponent as Pencil } from './pencil.svg';
import { ReactComponent as CheckmarkSmall } from './checkmark-small.svg';
import { ReactComponent as DateStampPlaceholder } from './datestamp-placeholder.svg';
import { ReactComponent as AddToFavorites } from './add-to-favorites.svg';
import { ReactComponent as Share } from './share.svg';
import { ReactComponent as WorkLike } from './work-like.svg';
import { ReactComponent as Send } from './send.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as ChevronLeft } from './chevron-left.svg';
import { ReactComponent as ChevronRight } from './chevron-right.svg';
import Bold from './bold.svg';
import Underline from './underline.svg';
import Italic from './italic.svg';
import Emoji from './emoji.svg';

export const withIcon = (component: ReactElement) => (hocProps: any) => {
  return (
    <div>
      <component.type
        style={{ display: 'block', margin: 'auto' }}
        {...component.props}
        {...hocProps}
      />
    </div>
  );
};
export const FacebookIcon = withIcon(<Facebook fill='#f5f5f7' />);
export const InstagramIcon = withIcon(<Instagram fill='#f5f5f7' />);
export const LoginIcon = withIcon(<Login fill='#f5f5f7' />);
export const YoutubeIcon = withIcon(<Youtube fill='#f5f5f7' />);
export const CaretCircleRightIcon = withIcon(
  <CaretCircleRight fill='#fefefe' stroke='' />
);
export const EyeIcon = withIcon(<Eye fill='#89939e' />);
export const RegisterIcon = withIcon(<Register fill='#f5f5f7' />);
export const AlertTriangleIcon = withIcon(
  <AlertTriangle fill='url(#paint0_linear_329_2237)' />
);
export const DoneIcon = withIcon(<Done fill='#f5f5f7' />);
export const ArrowDownIcon = withIcon(<ArrowDown fill='#757d8a' />);
export const RevealCardIcon = withIcon(<RevealCard fill='#89939E' />);
export const RevealReverseCardIcon = withIcon(
  <RevealReverseCard fill='#F5F5F7' />
);
export const RevealCardPrimarySchoolIcon = withIcon(
  <RevealCardPrimarySchool fill='#F5F5F7' />
);
export const RevealCardKindergartenIcon = withIcon(
  <RevealCardKindergarten fill='#F5F5F7' />
);
export const MagnifierIcon = withIcon(<Magnifier stroke='#89939e' />);
export const SearchTriggerIcon = withIcon(<SearchTrigger />);
export const CheckmarkIcon = withIcon(<Checkmark fill='#4e73f8' />);
export const LogoutIcon = withIcon(<Logout fill='#f5f5f7' />);
export const NextIcon = withIcon(<Next fill='#f5f5f7' />);
export const PencilIcon = withIcon(<Pencil fill='#f5f5f7' />);
export const CheckmarkSmallIcon = withIcon(<CheckmarkSmall stroke='#f5f5f7' />);
export const DateStampPlaceholderIcon = withIcon(<DateStampPlaceholder />);
export const AddToFavoritesIcon = withIcon(<AddToFavorites />);
export const ShareIcon = withIcon(<Share />);
export const WorkLikeIcon = withIcon(<WorkLike />);
export const SendIcon = withIcon(<Send />);
export const EmojiIcon = Emoji;
export const BoldIcon = Bold;
export const ItalicIcon = Italic;
export const UnderlineIcon = Underline;
export const CloseIcon = withIcon(<Close fill='#f5f5f7' />);
export const ChevronLeftIcon = withIcon(<ChevronLeft fill='#0071e3' />);
export const ChevronRightIcon = withIcon(<ChevronRight fill='#0071e3' />);
