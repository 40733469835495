import Button from '../../../../components/Button/Button';
import { CaretCircleRightIcon } from '../../../../components/Icons/icons';
import s from './ContestBanner.module.scss';
import globalColors from '../../../../styles/_colors.scss';
import button from '../../../../components/Button/Button.module.scss';
import { ContestStatus } from '../../../../utils/api/contests';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../../utils/datetime';
import { parseDateWithZone } from '../../../../utils/api/timezone';

interface Props {
  media: any;
  imgStyle: any;
  data?: any;
  upcoming?: boolean;
}

interface BannerProps extends Props {
  loading: boolean;
}

const UpcomingContest = ({ media, imgStyle, data }: Props) => {
  return (
    <div className={s.contestBanner}>
      <img
        style={imgStyle}
        src={media}
        alt={'Konkursy z nagrodami Pana Tableta'}
      />
      <div className={s.contestIncentive}>
        <h4>
          Najbliższy konkurs:{' '}
          {formatDate(parseDateWithZone(data?.qualificationFrom))}
        </h4>
        <h2>{data?.name}</h2>
        <p className='body-2'>{data.description}</p>
        <Button
          onClick={() =>
            window.open('https://pl-pl.facebook.com/PanTablet/', '_blank')
          }
          className={`${button.button} ${button.ranking}`}
          icon={<CaretCircleRightIcon fill={globalColors.white2} />}
        >
          <p className='button-2'>Śledź nas na Facebooku</p>
        </Button>
      </div>
    </div>
  );
};

const ActiveContest = ({ media, imgStyle, data }: Props) => {
  const navigate = useNavigate();
  return (
    <div className={s.contestBanner}>
      <img
        style={imgStyle}
        src={media}
        alt={'Konkursy z nagrodami Pana Tableta'}
      />
      <div className={s.contestIncentive}>
        <h4>
          Konkurs trwa do: {formatDate(parseDateWithZone(data?.mainRoundTo))}
        </h4>
        <h2>{data?.name}</h2>
        <p className='body-2'>{data?.description}</p>
        <Button
          onClick={() =>
            navigate(
              `/contests/${
                data?.schoolType?.id === 1 ? 'kindergarten' : 'elementary'
              }/${data?.id}`
            )
          }
          className={`${button.button} ${button.ranking}`}
          icon={<CaretCircleRightIcon fill={globalColors.white2} />}
        >
          <p className='button-2'>Zobacz ranking</p>
        </Button>
      </div>
    </div>
  );
};

const NoContest = ({ media, imgStyle }: Props) => {
  return (
    <div className={s.noContest}>
      <img
        style={imgStyle}
        src={media}
        alt={'Konkursy z nagrodami Pana Tableta'}
      />
      <div className={s.contestIncentive}>
        <h2>Aktualnie brak konkursów</h2>
        <p className='body-2'>
          Niebawem kolejne konkursy. Zapraszamy do śledzenia naszej strony na
          facebooku.
        </p>
        <Button
          onClick={() =>
            window.open('https://pl-pl.facebook.com/PanTablet/', '_blank')
          }
          className={`${button.button} ${button.ranking}`}
          icon={<CaretCircleRightIcon fill={globalColors.white2} />}
        >
          <p className='button-2'>Śledź nas na Facebooku</p>
        </Button>
      </div>
    </div>
  );
};

const ContestBanner = ({ loading, media, imgStyle, data }: BannerProps) => {
  return !loading ? (
    <>
      {data ? (
        data.contestType === ContestStatus.Upcoming ? (
          <UpcomingContest media={media} imgStyle={imgStyle} data={data} />
        ) : (
          <ActiveContest media={media} imgStyle={imgStyle} data={data} />
        )
      ) : (
        <NoContest media={media} imgStyle={imgStyle} />
      )}
    </>
  ) : (
    <></>
  );
};

export default ContestBanner;
