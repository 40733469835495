import { IBasePopupProps, withPopup } from '../withPopup';
import { CaretCircleRightIcon } from '../../Icons/icons';
import s from './RegisterErrorPopup.module.scss';
import Button from '../../Button/Button';
import button from '../../Button/Button.module.scss';

const RegisterErrorPopup = ({ close }: IBasePopupProps) => {
  return (
    <div className={s.container}>
      <h3 className='headline-3b'>Rejestracja nie powiodła się</h3>
      <p className='body-3'>
        Nie udało się zarejestrować z powodów technicznych. Spróbuj ponownie
        później.
      </p>

      <div className={s.buttons}>
        <Button
          onClick={() => {
            if (close) {
              close();
            }
          }}
          className={`${button.button} ${button.login}`}
          icon={<CaretCircleRightIcon />}
        >
          <p className='button-5'>OK</p>
        </Button>
      </div>
    </div>
  );
};

export default withPopup(RegisterErrorPopup);
