import { ComponentType, useEffect, useRef, useState } from 'react';
import s from './Popup.module.scss';
import { CloseIcon } from '../../../../components/Icons/icons';
import globalColors from '../../../../styles/_colors.scss';
import { useOnClickOutside } from 'usehooks-ts';

export interface BasePopupProps {
  popupClassName: any;
}

interface Props {
  x: number;
  y: number;
  close: () => void;
}

export const withPopup =
  <T extends BasePopupProps>(Component: ComponentType<T>) =>
  (props: Omit<T & Props, 'popupClassName'>) => {
    const newProps: T = { ...props, popupClassName: s.popup } as T;
    const ref = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    useOnClickOutside(ref, () => props.close());

    useEffect(() => {
      setWidth(ref.current?.offsetWidth || 0);
      setHeight(ref.current?.offsetHeight || 0);
    }, []);

    const rect = document.body.getBoundingClientRect();

    return (
      <div
        ref={ref}
        style={{
          top: `${Math.min(props.y, rect.height + rect.y - height)}px`,
          left: `${Math.min(props.x, rect.width + rect.x - width)}px`,
        }}
        className={s.container}
      >
        <Component {...newProps} />
        <div className={s.close} onClick={() => props.close()}>
          <CloseIcon width={24} height={24} stroke={globalColors.gray15} />
        </div>
      </div>
    );
  };
