import s from './CurrentRanking.module.scss';
import button from '../../../../components/Button/Button.module.scss';
import { CaretCircleRightIcon } from '../../../../components/Icons/icons';
import globalColors from '../../../../styles/_colors.scss';
import Button from '../../../../components/Button/Button';
import RankingPopup, {
  PopupTheme,
} from '../../../../components/Popups/RankingPopup/RankingPopup';
import { Fragment, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IContest, useGetContest } from '../../../../api/contest';
import { IPrize } from '../../../../api/prize';
import { motion } from 'framer-motion';
import {
  h1VariantsH,
  h2Variants,
  revealAndScaleVariants,
  revealButtonVariants,
} from '../../../../components/Animations/TextTransitions';
import { useGetRanking } from '../../../../api/ranking';
import {
  ContestStatus,
  getContestStatus,
  getSchoolsRanking,
} from '../../../../utils/api/contests';

interface IProps {
  image: string;
  theme: PopupTheme;
}

const CurrentRanking = ({ image, theme }: IProps) => {
  const { contestId } = useParams();
  const { data: contest } = useGetContest(contestId ?? '');
  const { data: rankingApi } = useGetRanking({
    contestId: contestId ? +contestId : -1,
  });
  const [popupVisible, setPopupVisible] = useState(false);
  const ranking: any[] = useMemo(
    () => (contest && rankingApi ? getSchoolsRanking(rankingApi) : []),
    [contest, rankingApi]
  );

  return contest && getContestStatus(contest) === ContestStatus.Active ? (
    <>
      <RankingPopup
        contest={contest}
        ranking={ranking}
        popupTheme={theme}
        visible={popupVisible}
        setVisible={setPopupVisible}
      />

      <div className={s.container}>
        <motion.img
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, amount: 0.3 }}
          variants={revealAndScaleVariants}
          src={image}
          alt='ranking'
        />
        <div>
          <motion.h2
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true, amount: 0.3 }}
            variants={h2Variants}
            className='headline-2 add-bold'
          >
            Aktualny ranking
          </motion.h2>
          <div className={s.standings}>
            {[1, 2, 3].map((place, key) => {
              const prize = contest[
                `place${place}Prize` as keyof IContest
              ] as IPrize;

              return (
                <Fragment key={place}>
                  {ranking[place - 1] && (
                    <motion.div
                      initial='hidden'
                      whileInView='visible'
                      viewport={{ once: false, amount: 0.3 }}
                      variants={h1VariantsH}
                      custom={key + 1}
                    >
                      <h3 className='headline-3b'>{place}</h3>
                      <h4>
                        {ranking[place - 1].school.name}
                        {prize &&
                        ranking[place - 1].schoolVotes >=
                          prize.minVotesToWin ? (
                          <>
                            {' '}
                            - <b>{prize.name}</b>
                          </>
                        ) : (
                          ''
                        )}
                      </h4>
                    </motion.div>
                  )}
                </Fragment>
              );
            })}
          </div>
          <motion.div
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true, amount: 0.3 }}
            custom={1}
            variants={revealButtonVariants}
          >
            <Button
              className={`${button.button} ${button.contests}`}
              icon={<CaretCircleRightIcon fill={globalColors.blue4} />}
              onClick={() => setPopupVisible(true)}
            >
              <p className='button-6'>Zobacz pełny ranking</p>
            </Button>
          </motion.div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default CurrentRanking;
