import { IBasePopupProps, withPopup } from '../withPopup';
import { CaretCircleRightIcon } from '../../Icons/icons';
import { useNavigate } from 'react-router-dom';
import s from './ForgotPasswordPopup.module.scss';
import Button from '../../Button/Button';
import button from '../../Button/Button.module.scss';

interface IProps {
  accept: () => void;
  disabled: boolean;
}

const ForgotPasswordPopup = ({
  close,
  accept,
  disabled,
}: IBasePopupProps & IProps) => {
  const navigate = useNavigate();

  return (
    <div className={s.container}>
      <h3 className='headline-3b'>Na pewno chcesz przypomnieć swoje hasło?</h3>
      <p className='body-3'>
        Na podany podczas rejestracji adres e-mail otrzymasz link aktywacyjny,
        po kliknięciu którego zostaniesz przeniesiony na ekran umożliwiający
        wpisanie nowego hasła.
      </p>

      <div className={s.buttons}>
        <Button
          disabled={disabled}
          onClick={() => accept()}
          className={`${button.button} ${button.login}`}
          icon={<CaretCircleRightIcon />}
        >
          <p className='button-5'>Wyślij link aktywacyjny</p>
        </Button>

        <p
          onClick={() => {
            if (close) {
              close();
            }

            navigate('/login');
          }}
          className='field-1 add-underline'
        >
          Znam hasło, zaloguję się
        </p>
      </div>
    </div>
  );
};

export default withPopup(ForgotPasswordPopup);
