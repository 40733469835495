import Button from '../../../../../../components/Button/Button';
import button from '../../../../../../components/Button/Button.module.scss';
import { scrollToId } from '../../../../../../utils/scroll';
import s from './Buttons.module.scss';

interface Props {
  callback: any;
  style: any;
  showWorks?: boolean;
  showTasks?: boolean;
  selectedIndex?: number;
}

const Buttons = ({
  callback,
  style,
  showWorks = true,
  showTasks = true,
  selectedIndex,
}: Props) => {
  return (
    <div style={style} className={s.buttons}>
      <Button
        onClick={() => {
          callback(0);
          scrollToId('single-work-view');
        }}
        className={`${button.button} ${
          selectedIndex === 0
            ? button.galleryButtonActive
            : button.galleryButton
        } button-2`}
      >
        Galeria prac
      </Button>
      {showWorks && (
        <Button
          onClick={() => {
            callback(1);
            scrollToId('single-work-view');
          }}
          className={`${button.button} ${
            selectedIndex === 1
              ? button.galleryButtonActive
              : button.galleryButton
          } button-2`}
        >
          Materiały dydaktyczne
        </Button>
      )}
      {showTasks && (
        <Button
          onClick={() => {
            callback(2);
            scrollToId('single-work-view');
          }}
          className={`${button.button} ${
            selectedIndex === 2
              ? button.galleryButtonActive
              : button.galleryButton
          } button-2`}
        >
          Zadania dodatkowe
        </Button>
      )}
    </div>
  );
};

export default Buttons;
