import { useEffect, useRef, useState } from 'react';
import s from './SchoolTypes.module.scss';
import card from '../../../../components/Card/FlexCard.module.scss';
import {
  revealVariants,
  RevealCard,
} from '../../../../components/Animations/CardTransitions';
import { motion } from 'framer-motion';
import {
  KindergartenContent,
  KindergartenContentReverse,
  SchoolContent,
  SchoolContentReverse,
} from './Content';

const SchoolTypes = () => {
  const [isFlippedSchool, setFlipSchool] = useState(false);
  const [isFlippedKindergarten, setFlipKindergarten] = useState(false);
  const [currentHeight, setCurrentHeight] = useState('max-content');
  const ref = useRef<any>();

  const flipCardSchool = () => {
    if (!isFlippedSchool) {
      setCurrentHeight(ref.current.clientHeight + 'px');
    }
    setFlipSchool(!isFlippedSchool);
  };

  const flipCardKindergarten = () => {
    if (!isFlippedKindergarten) {
      setCurrentHeight(ref.current.clientHeight + 'px');
    }
    setFlipKindergarten(!isFlippedKindergarten);
  };

  useEffect(() => {
    function handleResize() {
      setCurrentHeight('max-content');
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <div className={s.wrapper}>
      <div
        ref={ref}
        style={{ height: currentHeight }}
        className={`${card.allCards}`}
      >
        <RevealCard>
          <motion.div
            style={{ width: '100%', height: '100%', maxHeight: '690px' }}
            variants={revealVariants}
            className={`${card.singleCard}  ${
              isFlippedSchool ? s.schoolFlipped : ''
            }`}
          >
            {isFlippedSchool ? (
              <SchoolContentReverse callback={flipCardSchool} />
            ) : (
              <SchoolContent callback={flipCardSchool} />
            )}
          </motion.div>
        </RevealCard>
        <RevealCard>
          <motion.div
            style={{ width: '100%', height: '100%', maxHeight: '690px' }}
            variants={revealVariants}
            className={`${card.singleCard}  ${
              isFlippedKindergarten ? s.kinderGartenFlipped : ''
            }`}
          >
            {isFlippedKindergarten ? (
              <KindergartenContentReverse callback={flipCardKindergarten} />
            ) : (
              <KindergartenContent callback={flipCardKindergarten} />
            )}
          </motion.div>
        </RevealCard>
      </div>
    </div>
  );
};

export default SchoolTypes;
