import s from './ContestsCreated.module.scss';
import { useGetContests } from '../../../../api/contest';
import { motion } from 'framer-motion';
import {
  h1Variants,
  h2Variants,
} from '../../../../components/Animations/TextTransitions';

export enum Theme {
  PrimarySchool = s.primarySchool,
  Kindergarten = s.kindergarten,
}

interface IProps {
  image: string;
  theme: Theme;
}

const ContestsCreated = ({ image, theme }: IProps) => {
  const { data } = useGetContests();

  return (
    <div className={`${s.container} ${theme.valueOf()}`}>
      <div className={s.text}>
        <motion.h1
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, amount: 0.3 }}
          variants={h1Variants}
          custom={1}
          className='headline-1b'
        >
          {Object.keys(data).length}
        </motion.h1>
        <motion.h2
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, amount: 0.3 }}
          variants={h2Variants}
          className='headline-2 add-bold'
        >
          liczba konkursów stworzonych przez Pana Tableta
        </motion.h2>
      </div>
      <motion.img
        initial={{ x: 100 }}
        whileInView={{ x: 0 }}
        viewport={{ once: true, amount: 0.3 }}
        transition={{
          duration: 2,
        }}
        src={image}
        alt='contests-created'
      />
    </div>
  );
};

export default ContestsCreated;
