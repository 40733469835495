import SearchBar from '../../components/SearchBar/SearchBar';
import SocialMedia from '../../components/SocialMedia/SocialMedia';
import SubHeader from '../../components/SubHeader/SubHeader';
import Intro from './components/Intro/Intro';
import SchoolTypes from './components/SchoolTypes/SchoolTypes';
import Gallery from './components/Gallery/Gallery';
import globalColors from '../../styles/_colors.scss';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'usehooks-ts';
import globals from '../../App.scss';

const IndexPage = () => {
  const mobile = useMediaQuery('(max-width: 768px)');
  return (
    <motion.div
      initial='hidden'
      animate='visible'
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <SubHeader color={globalColors.green1} />
      <Intro />
      <SearchBar
        style={{
          width: mobile ? globals.mobileWidth : '30%',
          minWidth: '270px',
        }}
      />
      <SchoolTypes />
      <Gallery />
      <SocialMedia />
    </motion.div>
  );
};

export default IndexPage;
