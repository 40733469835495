import logo from './logo.webp';
import monitor from './monitor.webp';
import tablet1 from './tablet1.webp';
import tablet2 from './tablet2.webp';
import s from './index.module.scss';
import { motion } from 'framer-motion';

const MonitorElements = () => {
  return (
    <div className={s.wrapper}>
      <motion.img src={monitor} alt='monitor' />
      <motion.img
        transition={{
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 1,
          delay: 2,
        }}
        animate={{
          y: 10,
          rotateX: 20,
        }}
        src={logo}
        alt='logo'
      />
      <motion.img
        transition={{
          duration: 1.5,
        }}
        animate={{
          x: 210,
        }}
        src={tablet1}
        alt='tablet1'
      />
      <motion.img
        transition={{
          duration: 1.5,
        }}
        animate={{
          x: -210,
        }}
        src={tablet2}
        alt='tablet2'
      />
    </div>
  );
};

export default MonitorElements;
