import { FacebookProvider, CustomChat } from 'react-facebook';

const MessengerChat = () => {
  return (
    <FacebookProvider appId='1212268253000063' chatSupport>
      <CustomChat pageId='331583716873007' />
    </FacebookProvider>
  );
};

export default MessengerChat;
