import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAxiosAuthConfig } from '../utils/axios';

interface IGetRanking {
  schoolId?: number;
  gradeId?: number;
  contestId?: number;
}

interface IAddVote extends IGetRanking {
  workId: number;
}

export const useAddVote = () => {
  const config = useAxiosAuthConfig();

  return useMutation({
    mutationFn: (voteData: IAddVote) =>
      axios.post(
        '/ranking/add-vote',
        {
          contestId: voteData.contestId,
          workId: voteData.workId,
          gradeId: voteData.gradeId,
          schoolId: voteData.schoolId,
        },
        config
      ),
  });
};

export const useGetRanking = ({
  schoolId,
  gradeId,
  contestId,
}: IGetRanking) => {
  const qs = require('qs');
  const query = qs.stringify(
    {
      filters: {
        school: schoolId ?? {},
        grade: gradeId ?? {},
        contest: contestId ?? {},
      },
      populate: {
        school: true,
        grade: true,
        contest: true,
        votes: true,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return useQuery({
    queryKey: ['ranking', schoolId, gradeId, contestId],
    queryFn: () => {
      return axios.get(`/rankings?${query}`).then((res) => {
        return res.data?.data;
      });
    },
    placeholderData: [],
  });
};
