import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface IUserData {
  email: string;
  firstName: string;
  lastName: string;
  favoriteSchools: IFavorites;
  id: number;
}

interface IFavorites {
  favoriteSchools: number[];
}

interface IUser {
  jwt: string;
  loggedIn: boolean;
  userData?: IUserData;
  rememberMe: boolean;
}

const initialState: IUser = {
  jwt: '',
  loggedIn: false,
  userData: undefined,
  rememberMe: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setJWT: (state, action: PayloadAction<string>) => {
      state.jwt = action.payload;
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    setUserData: (state, action: PayloadAction<IUserData>) => {
      state.userData = action.payload;
    },
    setFavoriteSchools: (state, action: PayloadAction<any>) => {
      if (state.userData) {
        state.userData.favoriteSchools = action.payload;
      }
    },
    setRememberMe: (state, action: PayloadAction<boolean>) => {
      state.rememberMe = action.payload;
    },
    logOut: (state) => {
      state.jwt = '';
      state.loggedIn = false;
      state.userData = undefined;
      state.rememberMe = false;
    },
  },
});

export const {
  setJWT,
  setLoggedIn,
  setUserData,
  setFavoriteSchools,
  setRememberMe,
  logOut,
} = userSlice.actions;

export default userSlice.reducer;
