import { motion } from 'framer-motion';
import { pVariants } from '../../../../components/Animations/TextTransitions';
import ContestCards from '../ContestCards/ContestCards';
import s from './Contests.module.scss';

const Contests = () => {
  return (
    <>
      <div className={s.contestSection}>
        <motion.p
          className='body-2'
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true, amount: 0.3 }}
          variants={pVariants}
          custom={1}
        >
          Nie martw się, zanim pojawi się konkurs zostaniesz automatycznie
          powiadomiony o dacie jego rozpoczęcia oraz szczegółach.
        </motion.p>
        <ContestCards />
      </div>
    </>
  );
};

export default Contests;
