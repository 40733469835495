import { useGetAnimators } from '../../../../api/calendar';
import { useEffect, useMemo, useState } from 'react';
import { CalendarView } from '../../const';
import WeekView from './WeekView/WeekView';
import MonthView from './MonthView/MonthView';
import { useAppSelector } from '../../../../redux/hooks';
import button from '../../../../components/Button/Button.module.scss';
import Button from '../../../../components/Button/Button';
import s from './Views.module.scss';

const Views = () => {
  const selectedView = useAppSelector((state) => state.calendar.selectedView);
  const { data: animators } = useGetAnimators();
  const [animatorGroup, setAnimatorGroup] = useState(0);

  const selectedAnimators = useMemo(() => {
    if (animators) {
      return animators[animatorGroup];
    }

    return {};
  }, [animators, animatorGroup]);

  const groups = useMemo(() => {
    if (animators) {
      return animators.length;
    }

    return 0;
  }, [animators]);

  useEffect(() => {
    if (selectedAnimators && Object.keys(selectedAnimators)) {
      document.documentElement.style.setProperty(
        '--calendar-animators',
        Object.keys(selectedAnimators).length.toString(),
      );
    }
  }, [selectedAnimators]);

  return (
    <>
      <div className={s.pagination}>
        <h4>Strona animatorów</h4>
        <div>
          {[...Array(groups)].map((_, idx) => (
            <Button
              key={idx}
              className={`${button.button} ${
                animatorGroup === idx ? button.calendarActive : button.calendar
              }`}
              onClick={() => setAnimatorGroup(idx)}
            >
              <strong className='button-4'>{idx + 1}</strong>
            </Button>
          ))}
        </div>
      </div>
      {selectedView === CalendarView.Week && (
        <WeekView animators={selectedAnimators} />
      )}
      {selectedView === CalendarView.Month && (
        <MonthView animators={selectedAnimators} />
      )}
    </>
  );
};

export default Views;
