import s from './ChildrenWorks.module.scss';
import grid from '../../../../components/Card/FlexCardAlt2.module.scss';
import { WorkLikeIcon } from '../../../../components/Icons/icons';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FacebookShareButton } from 'react-share';
import globalColors from '../../../../styles/_colors.scss';
import WithoutAccountPopup from '../../../../components/Popups/WithoutAccountPopup/WithoutAccountPopup';
import { useGetInfiniteWorks } from '../../../../api/work';
import { Params, useNavigate } from 'react-router-dom';
import { useGetContests } from '../../../../api/contest';
import {
  canVoteOnContestDate,
  getContestForWork,
} from '../../../../utils/api/contests';
import { formatDate } from '../../../../utils/datetime';
import { useVoting } from '../../../../utils/api/voting';
import { scrollToId } from '../../../../utils/scroll';
import button from '../../../../components/Button/Button.module.scss';
import { useLocation } from '../../../../utils/location';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { useAddShare } from '../../../../api/social-sharing';
import { useAppSelector } from '../../../../redux/hooks';
import { useInView } from 'framer-motion';

interface Props {
  imgStyle: any;
  loggedIn: boolean;
  params: Params;
  onClick?: () => void;
}

interface CategoryProps {
  text: string;
  onClick: () => void;
  className: string;
}

interface CardProps {
  card: any;
  imgStyle: any;
  loggedIn: boolean;
  popupNotLoggedIn: Dispatch<SetStateAction<boolean>>;
  onClick: () => void;
}

const Category = ({ text, onClick, className }: CategoryProps) => {
  return (
    <div onClick={onClick} className={`button-2 ${s.category} ${className}`}>
      {text}
    </div>
  );
};

const SubCategory = ({ text, onClick, className }: CategoryProps) => {
  return (
    <div onClick={onClick} className={`button-3 ${s.subCategory} ${className}`}>
      {text}
    </div>
  );
};

const WorkLikeIconWithLikes = ({ votes, onClick }: any) => {
  return (
    <div onClick={onClick} className={s.workLikeIconLikes}>
      <span
        style={{ color: globalColors.white2, height: 'max-content' }}
        className='button-3'
      >
        {votes}
      </span>
      <WorkLikeIcon fill={globalColors.white2} />
    </div>
  );
};

const WorkLikeIconWithoutLikes = ({ onClick }: any) => {
  return (
    <div onClick={onClick} className={s.workLikeIconWithoutLikes}>
      <WorkLikeIcon fill={globalColors.orange1} />
    </div>
  );
};

export const Card = ({
  card,
  imgStyle,
  onClick,
  loggedIn,
  popupNotLoggedIn,
}: CardProps) => {
  const [popups, onClickIncrement] = useVoting(
    card,
    loggedIn,
    popupNotLoggedIn
  );
  const user = useAppSelector((state) => state.user);
  const { data: contests } = useGetContests();

  const contest = useMemo(
    () => (card ? getContestForWork(card, Object.values(contests)) : null),
    [card, contests]
  );
  const canVoteDate = useMemo(
    () => (contest ? canVoteOnContestDate(contest) : null),
    [contest]
  );
  const addShare = useAddShare();
  return card ? (
    <>
      {popups}
      <div onClick={onClick} className={grid.singleCard}>
        <img
          className={s.image}
          style={imgStyle}
          src={process.env.REACT_APP_HOST_URL + card.media[0].url}
          alt='szkoła biorąca udział w lekcjach Pana Tablet'
        ></img>
        <div className={s.content}>
          <div className={s.subjectAndName}>
            <p className='button-3'>{card.topic.name}</p>
            <p style={{ whiteSpace: 'nowrap' }} className='button-3'>
              {formatDate(card.createdAt)}
            </p>
          </div>
          <p className='body-3 add-bold'>{card.author}</p>
          <div>
            <span className='button-2'>Animator: </span>
            <span className='button-5'>{card.animator?.name} </span>
          </div>
          <div>
            <span className='button-2'>Kategoria edukacyjna: </span>
            <p className='button-5'>{card.topic.category.name} </p>
          </div>
          <div className={s.bottomButtons}>
            <FacebookShareButton
              className={`${button.button} ${button.shareAlt}`}
              onClick={(e) => {
                e.stopPropagation();

                addShare.mutate({
                  type: 'facebook',
                  work: card.id,
                  school: card.school.id,
                  city: card.school.city.id,
                  voivodeship: card.school.voivodeship.id,
                  user:
                    loggedIn && user.userData ? user.userData.id : undefined,
                });
              }}
              url={
                process.env.REACT_APP_WEBSITE_URL +
                window.location.pathname +
                '/' +
                card.id
              }
              hashtag={'#nowoczesnaedukacja'}
            >
              <FontAwesomeIcon
                icon={faFacebook}
                style={{ width: '28px', height: '28px', color: '#0071e3' }}
              />
              <p className='button-3'>Udostępnij</p>
            </FacebookShareButton>
            {canVoteDate && (
              <>
                {card.votes.length ? (
                  <WorkLikeIconWithLikes
                    onClick={onClickIncrement}
                    votes={card.votes.length}
                  />
                ) : (
                  <WorkLikeIconWithoutLikes onClick={onClickIncrement} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

const ChildrenWorks = ({ imgStyle, loggedIn, params }: Props) => {
  const refLoadMore = useRef(null);
  const shouldLoadMore = useInView(refLoadMore);
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeSubCategory, setActiveSubCategory] = useState(null);
  const [categories, setCategories] = useState<any>([]);
  const [subCategories, setSubCategories] = useState<any>([]);
  const [popupVisibleNoAccount, setPopupVisibleNoAccount] = useState(false);
  const { data: worksInfinite, fetchNextPage } = useGetInfiniteWorks({
    school: params.school,
    grade: params.grade,
  });
  const works = useMemo(
    () => ({
      data: worksInfinite?.pages
        ?.map((page) => page.data)
        .reduce((acc, curr) => [...acc, ...curr], []),
    }),
    [worksInfinite]
  );
  const [cardData, setCardData] = useState<any>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (shouldLoadMore) {
      fetchNextPage().then(() => {});
    }
  }, [shouldLoadMore, fetchNextPage]);

  useEffect(() => {
    const { data } = works;
    if (data?.length >= 0) {
      setCardData(data);
    }
  }, [works]);

  useEffect(() => {
    const categories = [
      ...new Set(
        cardData?.map((elem: any) => {
          return elem.topic.category.name;
        })
      ),
    ];
    setCategories(categories);
  }, [cardData]);

  useEffect(() => {
    const subCategories = [
      ...new Map(
        cardData?.map((item: any) => [item.topic.name, item.topic])
      ).values(),
    ];
    setSubCategories(subCategories);
  }, [cardData]);

  useEffect(() => {
    if (activeCategory === null) {
      setSubCategories([]);
    } else {
      const subCategories = [
        ...new Map(
          cardData?.map((item: any) => [item.topic.name, item.topic])
        ).values(),
      ];
      const filteredSubCategories = subCategories.filter(
        (elem: any) => elem.category.name === activeCategory
      );
      setSubCategories(filteredSubCategories);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory, cardData]);

  const setCategory = (elem: any) => {
    if (elem === activeCategory) {
      setActiveCategory(null);
      setActiveSubCategory(null);
    } else {
      setActiveCategory(elem);
      setActiveSubCategory(null);
    }
  };

  const filteredCardData = (cardData: any) => {
    return cardData?.filter(
      (card: any) =>
        (card.topic.category.name === activeCategory ||
          activeCategory === null) &&
        (card.topic.name === activeSubCategory || activeSubCategory === null)
    );
  };

  const location = useLocation();

  return (
    <>
      <WithoutAccountPopup
        otherIncentive='Dodawanie polubień jest zarezerowane dla zarejestrowanych użytkowników. Dzięki tej funkcji wspierasz swoje dziecko i inne w wygraniu konkursu. Nie zwlekaj z założeniem konta.'
        visible={popupVisibleNoAccount}
        setVisible={setPopupVisibleNoAccount}
      ></WithoutAccountPopup>
      {filteredCardData(cardData)?.length > 0 ? (
        <>
          <h4 className={`add-bold ${s.categoryTitle}`}>
            Wybierz kategorię prac
          </h4>
          <div className={s.categories}>
            {categories.map((elem: any, index: number) => {
              return (
                <Category
                  key={index}
                  className={activeCategory === elem ? s.categoryActive : ''}
                  onClick={() => setCategory(elem)}
                  text={elem}
                />
              );
            })}
          </div>
          <div className={s.categories}>
            {subCategories.map((elem: any, index: number) => {
              return (
                <SubCategory
                  key={index}
                  className={
                    activeSubCategory === elem.name ? s.subCategoryActive : ''
                  }
                  onClick={
                    elem.name === activeSubCategory
                      ? () => setActiveSubCategory(null)
                      : () => setActiveSubCategory(elem.name)
                  }
                  text={elem.name}
                />
              );
            })}
          </div>
          <div className={s.cardsSummary}>
            {activeCategory === null && activeSubCategory === null ? (
              <h4 className='add-bold'>
                Wszystkie prace {cardData?.[0]?.grade?.name}
              </h4>
            ) : (
              <p className='body-2 add-bold'>
                {activeCategory}
                {activeSubCategory ? ' / ' + activeSubCategory : ''}
                {' - '}
                {filteredCardData(cardData).length} prace
              </p>
            )}
          </div>
          <div className={`${grid.allCards}`}>
            {filteredCardData(cardData)?.map((card: any, index: number) => {
              return (
                <Card
                  card={card}
                  key={card.id}
                  imgStyle={imgStyle}
                  loggedIn={loggedIn}
                  popupNotLoggedIn={setPopupVisibleNoAccount}
                  onClick={() => {
                    if (window.location.pathname.split('/').length > 4) {
                      location.push(card.id);
                      scrollToId('single-work-view');
                    } else {
                      navigate(window.location.pathname + '/' + card.id);
                    }
                  }}
                />
              );
            })}
            )
            <div ref={refLoadMore} />
          </div>
        </>
      ) : (
        <h4 className={`add-bold ${s.categoryTitle}`}>
          W wybranej klasie nie ma jeszcze żadnych prac
        </h4>
      )}
    </>
  );
};

export default ChildrenWorks;
