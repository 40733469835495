import SubHeader from '../../../../../../components/SubHeader/SubHeader';
import globalColors from '../../../../../../styles/_colors.scss';
import s from '../../../../SchoolPages.module.scss';
import BreadCrumbs from '../../../../../../components/Breadcrumbs/Breadcrumbs';
import IntroMessage from '../../../../components/IntroMessageAlt/IntroMessageAlt';
import BrandHero from './components/brand-hero-elementary-work.webp';
import Media from './components/contest-elementary.webp';
import { useGetWorks } from '../../../../../../api/work';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../../../../redux/hooks';
import ContestBanner from '../../../../components/ContestBanner/ContestBanner';
import { Divider } from '../../../../../../components/Divider/Divider';
import ChildrenWorks from '../../../../components/ChildrenWorks/ChildrenWorks';
import SocialMedia from '../../../../../../components/SocialMedia/SocialMedia';
import SingleWorkView from '../../../../components/SingleWorkView/SingleWorkView';
import ViewComment from '../../../../components/ViewComment/ViewComment';
import AdditionalWorksImg from '../../../../../SchoolPages/components/AdditionalMaterials/additional-works-elementary.webp';
import AdditionalTasksImg from '../../../../../SchoolPages/components/AdditionalMaterials/additional-tasks-elementary.webp';
import { SchoolType, useGetContests } from '../../../../../../api/contest';
import { getValidContests } from '../../../../../../utils/api/contests';
import { useSchoolParams } from '../../../../../../utils/api/school';
import { useMediaQuery } from 'usehooks-ts';

const GradeWorkPage = () => {
  const params = useSchoolParams();
  const works = useGetWorks({
    school: params.schoolId,
    grade: params.gradeId,
  });
  const [resWorks, setResWorks] = useState<any>(null);

  useEffect(() => {
    const { data } = works;
    if (data?.length >= 0) {
      setResWorks(data);
    }
  }, [works]);

  const user = useAppSelector((state) => state.user);
  const loggedIn = !!user.userData;

  const contests = useGetContests(SchoolType.PrimarySchool);

  const validContest = useMemo(() => {
    if (contests.data) {
      return getValidContests(Object.values(contests.data))[
        SchoolType.PrimarySchool
      ];
    }
  }, [contests]);

  const author = resWorks?.find(
    (child: any) => child.id.toString() === params.workId?.toString()
  )?.author;

  const mobile = useMediaQuery('(max-width: 768px)');

  return (
    <>
      <SubHeader color={globalColors.green1} />
      <BreadCrumbs
        grades={resWorks?.[0]?.school?.name}
        grade={resWorks?.[0]?.grade?.name}
        workId={author}
      />
      <IntroMessage
        h1='Kreatywność.
      Rozwój i nauka.
      Zabawa.'
        image={BrandHero}
        imgStyle={{
          width: mobile ? '100%' : '55%',
          height: mobile ? '200px' : '537px',
          right: '-5%',
        }}
      />
      <ContestBanner
        loading={contests.isLoading}
        imgStyle={{
          width: mobile ? '100%' : '30%',
          height: mobile ? '200px' : '430px',
          objectFit: 'contain',
          padding: '2rem',
        }}
        media={Media}
        data={validContest}
      />
      {resWorks?.[0] && (
        <SingleWorkView
          isElementary={true}
          showAdditionalMaterials={true}
          additionalWorksImg={AdditionalWorksImg}
          additionalTasksImg={AdditionalTasksImg}
          additionalWorksTitle={'Materiały dydaktyczne do nauki'}
          additionalTasksTitle={'Zadania dodatkowe dla Twojego dziecka'}
        />
      )}
      <ViewComment />
      <div className={s.divider}>
        <Divider height={'2px'} backgroundColor={'#89939E'} />
      </div>
      <ChildrenWorks
        params={{
          school: params.schoolId,
          grade: params.gradeId,
        }}
        imgStyle={{ width: '100%', height: '172px' }}
        loggedIn={loggedIn}
      />
      <SocialMedia />
    </>
  );
};

export default GradeWorkPage;
