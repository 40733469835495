import Crown from './img/crown.webp';
import Gift from './img/gift.webp';
import Trophy from './img/trophy.webp';
import s from './ContestInfo.module.scss';
import r from '../../../../components/Popups/RankingPopup/RankingPopup.module.scss';
import {
  ContestStatus,
  getFinishedContests,
  getMinimumVotesToQualify,
  getSchoolsRanking,
  getValidContests,
  isFinalRoundTime,
  isQualificationTime,
  isWorkForContest,
} from '../../../../utils/api/contests';
import { formatDate } from '../../../../utils/datetime';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useMediaQuery } from 'usehooks-ts';
import RankingPopup, {
  PopupTheme,
} from '../../../../components/Popups/RankingPopup/RankingPopup';
import button from '../../../../components/Button/Button.module.scss';
import Button from '../../../../components/Button/Button';
import { CaretCircleRightIcon } from '../../../../components/Icons/icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useState } from 'react';
import {
  SchoolType,
  useGetContest,
  useGetContests,
} from '../../../../api/contest';
import { useGetRanking } from '../../../../api/ranking';
import { useSchoolParams } from '../../../../utils/api/school';
import { FacebookShareButton } from 'react-share';
import { useAddShare } from '../../../../api/social-sharing';
import { useAppSelector } from '../../../../redux/hooks';
import { useVoting } from '../../../../utils/api/voting';
import WithoutAccountPopup from '../../../../components/Popups/WithoutAccountPopup/WithoutAccountPopup';
import { useNavigate } from 'react-router-dom';
import { parseDateWithZone } from '../../../../utils/api/timezone';

const ContestInfo = (props: any) => {
  const schoolType =
    window.location.href.indexOf('elementary') > -1
      ? SchoolType.PrimarySchool
      : SchoolType.Kindergarten;

  const contests = useGetContests();
  const validContest = useMemo(
    () => getValidContests(Object.values(contests.data))[schoolType],
    [contests, schoolType]
  );
  const finishedContests = useMemo(
    () => getFinishedContests(Object.values(contests.data), schoolType),
    [contests.data, schoolType]
  );

  const finishedContest = finishedContests.find((contest) =>
    isWorkForContest(contest, props?.cardData?.[0])
  );

  const isWorkInFinishedContest = finishedContest !== undefined;

  const params = useSchoolParams();
  const [schoolCurrentPlace, setSchoolCurrentPlace] = useState(0);

  const { data: contest } = useGetContest(
    isWorkInFinishedContest
      ? finishedContest?.id?.toString()
      : validContest?.id?.toString() ?? ''
  );

  const isWorkInActiveContest =
    validContest &&
    props?.cardData?.[0] &&
    parseDateWithZone(props?.cardData?.[0]?.createdAt) >=
      parseDateWithZone(validContest.worksFrom) &&
    parseDateWithZone(props?.cardData?.[0]?.createdAt) <=
      parseDateWithZone(validContest.worksTo) &&
    validContest.contestType === ContestStatus.Active;

  const { data: rankingApi } = useGetRanking({
    contestId: isWorkInFinishedContest
      ? +finishedContest.id
      : validContest?.id
      ? +validContest?.id
      : -1,
  });
  const ranking: any[] = useMemo(
    () => (contest && rankingApi ? getSchoolsRanking(rankingApi) : []),
    [contest, rankingApi]
  );

  const schoolTypeName =
    window.location.href.indexOf('elementary') > -1
      ? 'elementary'
      : 'kindergarten';

  useEffect(() => {
    const result = ranking?.findIndex(
      (elem: any) =>
        elem?.school?.id?.toString() === params?.schoolId?.toString()
    );
    setSchoolCurrentPlace(result + 1);
  }, [ranking, params.schoolId]);

  let initialTime: number | undefined;

  if (contest?.todaysDate) {
    initialTime = parseDateWithZone(contest.todaysDate);
  } else {
    initialTime = parseDateWithZone(new Date().toISOString());
  }

  const [now, setNow] = useState<number | undefined>(initialTime);
  const [isTimerPlaying, setIsTimerPlaying] = useState<boolean>(true);

  useEffect(() => {
    if (contest?.todaysDate) {
      setNow(parseDateWithZone(contest.todaysDate));
    } else {
      setNow(parseDateWithZone(new Date().toISOString()));
    }
  }, [contest?.todaysDate]);

  const [isQualificationRound, setIsQualificationRound] =
    useState<boolean>(false);

  const [isFinalRound, setIsFinalRound] = useState<boolean | null>(null);

  useEffect(() => {
    setIsQualificationRound(isQualificationTime(contest));
    setIsFinalRound(isFinalRoundTime(contest));
  }, [contest]);

  const startDate = useMemo(() => {
    if (isQualificationRound !== null && isFinalRound !== null) {
      if (isFinalRound) {
        return parseDateWithZone(contest?.mainRoundFrom);
      }
      return parseDateWithZone(contest?.qualificationFrom);
    }
    return undefined;
  }, [contest, isQualificationRound, isFinalRound]);

  const endDate = useMemo(() => {
    if (isQualificationRound !== null && isFinalRound !== null) {
      if (isFinalRound) {
        return parseDateWithZone(contest?.mainRoundTo);
      }
      return parseDateWithZone(contest?.qualificationTo);
    }
    return undefined;
  }, [contest, isQualificationRound, isFinalRound]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (now !== undefined && endDate !== undefined) {
        if (now >= endDate) {
          setIsTimerPlaying(false);
        } else {
          setNow((prevNow) =>
            prevNow !== undefined ? prevNow + 1000 : undefined
          );
        }
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [now, endDate]);

  const mobile = useMediaQuery('(max-width: 768px)');

  const getCountdownCircleTheme = (popupTheme: PopupTheme) => {
    switch (popupTheme) {
      case PopupTheme.Blue:
        return 'url(#primary-school)';

      case PopupTheme.Green:
        return 'url(#kindergarten)';

      default:
        return 'url(#)';
    }
  };
  const getPopupThemeClassName = (popupTheme: PopupTheme) => {
    switch (popupTheme) {
      case PopupTheme.Blue:
        return r.themeBlue;

      case PopupTheme.Green:
        return r.themeGreen;

      default:
        return '';
    }
  };

  const addShare = useAddShare();

  const user = useAppSelector((state) => state.user);

  const loggedIn = useAppSelector((state) => state.user.loggedIn);

  const [popupVisibleNoAccount, setPopupVisibleNoAccount] = useState(false);

  const [popups, onClickIncrement] = useVoting(
    props?.cardData?.[0],
    loggedIn,
    setPopupVisibleNoAccount
  );

  const [popupVisibleRanking, setPopupVisibleRanking] = useState(false);

  const navigate = useNavigate();

  const checkVotesVariationWord = (numberOfVotes: number) => {
    if (numberOfVotes === 1) {
      return 'głos';
    } else if (
      numberOfVotes === 2 ||
      numberOfVotes === 3 ||
      numberOfVotes === 4
    ) {
      return 'głosy';
    } else {
      return 'głosów';
    }
  };

  const renderContestMessage = () => {
    const minimumVotes = getMinimumVotesToQualify(
      props?.cardData?.[0],
      contest
    );
    if (isQualificationTime(contest)) {
      return (
        <div className={s.contestTitle}>
          <h3>
            Etap 1
            <br />
            {`Uzyskaj ${minimumVotes} ${checkVotesVariationWord(
              minimumVotes
            )} i startuj w konkursie!`}
          </h3>
          <p>Do końca kwalifikacji</p>
        </div>
      );
    } else {
      return (
        <div className={s.contestTitle}>
          <h3>
            Etap 2
            <br />
            Konkurs główny
          </h3>
          <p>Do końca głosowania</p>
        </div>
      );
    }
  };

  return isWorkInActiveContest || isWorkInFinishedContest ? (
    <>
      {popups}
      <RankingPopup
        contest={contest}
        ranking={ranking}
        popupTheme={PopupTheme.Blue}
        visible={popupVisibleRanking}
        setVisible={setPopupVisibleRanking}
      />
      <WithoutAccountPopup
        otherIncentive='Dodawanie polubień jest zarezerowane dla zarejestrowanych użytkowników. Dzięki tej funkcji wspierasz swoje dziecko i inne w wygraniu konkursu. Nie zwlekaj z założeniem konta.'
        visible={popupVisibleNoAccount}
        setVisible={setPopupVisibleNoAccount}
      />
      <svg>
        <defs>
          <linearGradient
            id='primary-school'
            gradientUnits='userSpaceOnUse'
            x1='-9.05%'
            y1='34.51%'
            x2='109.05%'
            y2='65.49%'
          >
            <stop stopColor='#FFC826' />
            <stop offset='.513' stopColor='#FF903D' />
            <stop offset='1.071' stopColor='#ED6F9B' />
          </linearGradient>

          <linearGradient
            id='kindergarten'
            gradientUnits='userSpaceOnUse'
            x1='-9.23%'
            y1='33.85%'
            x2='109.23%'
            y2='66.15%'
          >
            <stop stopColor='#1570EC' />
            <stop offset='1.002' stopColor='#02B0F5' />
          </linearGradient>
        </defs>
      </svg>
      <div>
        <div className={s.votingHeader}>
          <div>
            {isWorkInActiveContest ? (
              <>
                {renderContestMessage()}
                <div className={s.timerPlace}>
                  {startDate &&
                    endDate &&
                    now &&
                    isQualificationRound !== null &&
                    isFinalRound !== null && (
                      <CountdownCircleTimer
                        key={now}
                        size={mobile ? 180 : 260}
                        isPlaying={isTimerPlaying}
                        initialRemainingTime={(endDate - now) / 1000}
                        duration={(endDate - startDate) / 1000}
                        colors={getCountdownCircleTheme(PopupTheme.Blue)}
                      >
                        {({ remainingTime }) => {
                          let timeToShow;
                          if (remainingTime < 3600) {
                            // less than 1 hour
                            // show in minutes and seconds
                            const minutes = Math.floor(remainingTime / 60);
                            const seconds = Math.floor(remainingTime % 60);
                            timeToShow = `${minutes} m ${seconds} s`;
                          } else if (remainingTime < 86400) {
                            // less than 1 day
                            // show in hours, minutes and seconds
                            const hours = Math.floor(remainingTime / 3600);
                            const minutes = Math.floor(
                              (remainingTime % 3600) / 60
                            );
                            const seconds = Math.floor(
                              (remainingTime % 3600) % 60
                            );
                            timeToShow = `${hours} h ${minutes} m ${seconds} s`;
                          } else {
                            // show in days, hours, minutes and seconds
                            const days = Math.floor(remainingTime / 86400);
                            const hours = Math.floor(
                              (remainingTime % 86400) / 3600
                            );
                            const minutes = Math.floor(
                              (remainingTime % 3600) / 60
                            );
                            const seconds = Math.floor(
                              (remainingTime % 3600) % 60
                            );
                            timeToShow = (
                              <>
                                {`${days} d`} <br />{' '}
                                {`${hours} h ${minutes} m ${seconds} s`}
                              </>
                            );
                          }
                          return (
                            <div
                              className={`${
                                r.circleText
                              } ${getPopupThemeClassName(PopupTheme.Blue)}`}
                            >
                              <h3 className='headline-3b'>{timeToShow}</h3>
                              <br />
                              <h3 className='button-3'>
                                {formatDate(endDate)}
                              </h3>
                            </div>
                          );
                        }}
                      </CountdownCircleTimer>
                    )}
                  {schoolCurrentPlace !== 0 && (
                    <p
                      onClick={() => setPopupVisibleRanking(true)}
                      className={`body-1 ${s.schoolPlace}`}
                      style={{ cursor: 'pointer' }}
                    >
                      Miejsce szkoły: {`${schoolCurrentPlace}`}/
                      {`${ranking?.length}`}
                    </p>
                  )}
                </div>
              </>
            ) : (
              <div className={s.results}>
                <p>
                  <span className='headline-1a'>
                    {props?.cardData?.[0]?.votes?.length}
                  </span>
                  <span className='headline-4 add-bold'>Liczba głosów</span>
                </p>

                {schoolCurrentPlace !== 0 && (
                  <p>
                    <span className='headline-1a'>{schoolCurrentPlace}</span>
                    <span className='body-1 add-bold'>miejsce szkoły</span>
                  </p>
                )}
              </div>
            )}
          </div>
          {isWorkInActiveContest ? (
            <div
              onClick={() => setPopupVisibleRanking(true)}
              className={s.prizeCheck}
            >
              <img src={Gift} alt='nagrody' />
              <p className='button-1'>{`Sprawdź\nnagrody!`}</p>
            </div>
          ) : (
            <img src={Trophy} alt='puchar' />
          )}
        </div>
        <div className={s.buttons}>
          <div>
            <Button
              className={`${s.button} ${button.button} ${button.votingGradient}`}
              icon={<CaretCircleRightIcon />}
              onClick={
                isWorkInActiveContest
                  ? onClickIncrement
                  : () => navigate('/contests/' + schoolTypeName + '/finished')
              }
            >
              <p className='button-1'>
                {isWorkInActiveContest ? 'Głosuję' : 'Lista zwycięzców'}
              </p>
            </Button>
            {isWorkInActiveContest && (
              <div className={s.votes}>
                <p className='headline-1a'>
                  {props?.cardData?.[0]?.votes?.length}
                </p>
                <img src={Crown} alt='korona' />
              </div>
            )}
          </div>
          {isWorkInActiveContest && (
            <FacebookShareButton
              onClick={(e) => {
                e.stopPropagation();
                addShare.mutate({
                  type: 'facebook',
                  work: props?.cardData?.[0].id,
                  school: props?.cardData?.[0].school.id,
                  city: props?.cardData?.[0].school.city.id,
                  voivodeship: props?.cardData?.[0].school.voivodeship.id,
                  user:
                    loggedIn && user.userData ? user.userData.id : undefined,
                });
              }}
              className={`${s.button} ${s.buttonShare} ${button.button} ${button.shareOutline}`}
              url={process.env.REACT_APP_WEBSITE_URL + window.location.pathname}
              hashtag={'#nowoczesnaedukacja'}
            >
              <FontAwesomeIcon
                icon={faFacebook}
                style={{
                  width: '20px',
                  height: '20px',
                  order: 1,
                  color: '#0071e3',
                }}
              />
              <p className='button-2'>Wesprzyj szkołę</p>
            </FacebookShareButton>
          )}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default ContestInfo;
