import CookieConsent from 'react-cookie-consent';

const MrTabletCookieConsent = () => {
  return (
    <CookieConsent
      buttonText={'Akceptuje'}
      cookieName='cookies-consent'
      style={{ background: '#f5f7fa', border: '1px solid #212121' }}
      buttonStyle={{
        color: '#fefefe',
        background: '#0671e0',
        fontSize: '16px',
      }}
      expires={150}
    >
      <span style={{ fontSize: '14px', color: '#212121' }}>
        "Ta strona zbiera pliki cookie, aby zapewnić lepszą obsługę użytkownika"
      </span>
    </CookieConsent>
  );
};

export default MrTabletCookieConsent;
