import Image from './without-account.webp';
import { IBasePopupProps, withPopup } from '../withPopup';
import s from './WithoutAccount.module.scss';
import button from '../../Button/Button.module.scss';
import { CaretCircleRightIcon } from '../../Icons/icons';
import Button from '../../Button/Button';
import { useNavigate } from 'react-router-dom';

interface IProps {
  otherIncentive?: string;
}

const WithoutAccountPopup = ({
  close,
  otherIncentive,
}: IBasePopupProps & IProps) => {
  const navigate = useNavigate();
  let standardIncentive =
    'Posiadając konto masz możliwość brania udziału w konkursach Pana Tableta i wygrywać nagrody dla swojej szkoły.';
  return (
    <div className={s.container}>
      <img width='294px' src={Image} alt='without-account' />
      <h3 className='headline-3b'>Czy na pewno chcesz korzystać bez konta?</h3>
      <p className='body-3'>
        {otherIncentive ? otherIncentive : standardIncentive}
      </p>

      <div className={s.buttons}>
        <Button
          onClick={() => {
            if (close) {
              close();
            }
            navigate('/pre-register');
          }}
          className={`${button.button} ${button.login}`}
          icon={<CaretCircleRightIcon />}
        >
          <p className='button-5'>Chce pełny dostęp</p>
        </Button>

        <p
          onClick={() => {
            if (close) {
              close();
            }
          }}
          className='field-1 add-underline'
        >
          Kontynuuj bez zalogowania
        </p>
      </div>
    </div>
  );
};

export default withPopup(WithoutAccountPopup);
